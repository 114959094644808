import { Group, Input, Loader, LoadingOverlay } from "@mantine/core";
import { IconArrowLeft, IconSearch, IconUserPlus } from "@tabler/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { CollectionQuery } from "../../../../models/collection.model";
import { Passenger } from "../../../../models/passenger.model";
import Card from "../../../../shared/component/Card/card-component";
import Confirmation from "../../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../../shared/component/pagination/pagination";
import {
  useAddEmployeeMutation,
  useLazyGetCorporateEmployeesAdminQuery,
} from "../../store/corporate.query";

export default function CorporateEmployeeSelectComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [searchParam, setSearchParam] = useState<string>("");
  const params = useParams();

  const [currentId, setCurrentId] = useState<string>("");
  const [getPassengers, passengers] = useLazyGetCorporateEmployeesAdminQuery();
  const [addEmployee, addedEmployee] = useAddEmployeeMutation();

  useEffect(() => {
    getPassengers({
      ...collection,
      filter: [
        [{ field: "enabled", operator: "=", value: true }],
        [{ field: "corporateId", operator: "isNull", value: "" }],
      ],
    });
  }, [collection]);

  return (
    <Card title={"Add Employees"}>
      <Group position={"apart"}>
        <Group position={"apart"}>
          <Input
            onKeyUp={debounce(
              (event: any) =>
                setCollection({
                  ...collection,
                  search: event.target.value,
                  searchFrom: ["name", "email", "phoneNumber"],
                }),
              1000
            )}
            size="xs"
            icon={<IconSearch size={16} />}
            placeholder="Search"
          />
        </Group>
        <NavLink
          to={`/registration/corporate/detail/${params.type}/${params.id}`}
          className="relative flex items-center justify-center px-2 py-1 text-white rounded cursor-pointer bg-primary"
        >
          <IconArrowLeft size={20} strokeWidth={1.5} />
          <div className={"pl-2"}>back</div>
        </NavLink>
      </Group>
      <div className="relative mt-5 overflow-x-auto">
        <LoadingOverlay visible={passengers.isFetching} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Gender
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {passengers.isSuccess &&
              passengers.data.data.length > 0 &&
              passengers.data?.data.map((passenger: Passenger, idx: number) => (
                <tr
                  key={idx}
                  className="text-xs bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {passenger.name}
                  </th>
                  <td className="px-6 py-4">{passenger.email}</td>
                  <td className="px-6 py-4 text-xs">{passenger.phoneNumber}</td>
                  <td className="px-6 py-4">
                    {passenger.gender === "male" ? "Male" : "Female"}
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex-col space-y-1">
                      {passenger.corporateId === params.id ? (
                        <div className="relative flex items-center justify-center w-20 px-2 py-1 text-white bg-green-300 rounded cursor-pointer">
                          <div>Already an Employee</div>
                        </div>
                      ) : addedEmployee.isLoading &&
                        currentId === passenger.id ? (
                        <div className="relative flex items-center justify-center px-2 py-1 text-white rounded cursor-pointer bg-primary">
                          <Loader size={"sm"} color={"white"} />
                        </div>
                      ) : currentId ? (
                        <div className="relative flex items-center justify-center px-2 py-1 text-white rounded cursor-pointer bg-primary">
                          <IconUserPlus size={20} strokeWidth={1.5} />
                          <div className={"pl-2"}>Add</div>
                        </div>
                      ) : (
                        <Confirmation
                          header={"Employee add confirmation"}
                          type={"notify"}
                          yesText={"Add"}
                          message={"Do you want to add this as an employee?"}
                          onYes={() => {
                            setCurrentId(passenger.id);
                            addEmployee({
                              corporateId: params.id ?? "",
                              id: passenger.id,
                            }).then(() => setCurrentId(""));
                          }}
                        >
                          <div className="relative flex items-center justify-center px-2 py-1 text-white rounded cursor-pointer bg-primary">
                            <IconUserPlus size={20} strokeWidth={1.5} />
                            <div className={"pl-2"}>Add</div>
                          </div>
                        </Confirmation>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {passengers.isLoading || !passengers?.data?.data.length ? (
          <div className="relative flex items-center justify-center w-full">
            <LoadingOverlay visible={passengers.isLoading} overlayBlur={2} />
            <EmptyIcon />
          </div>
        ) : (
          <></>
        )}
        {passengers?.isSuccess && passengers?.data?.data?.length > 0 && (
          <div className="flex items-center justify-end w-full mt-2 mb-2">
            <Pagination
              total={passengers?.data ? passengers?.data?.count : 0}
              defaultPageSize={10}
              pageSize={[10, 15, 20]}
              onPaginationChange={(skip: number, top: number) => {
                setCollection({ ...collection, skip: skip, top: top });
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
