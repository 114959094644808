import { io, Socket } from "socket.io-client";

const SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`;
// Define your custom types for events
interface ServerToClientEvents {
  message: (data: string) => void;
}

interface ClientToServerEvents {
  sendMessage: (data: string) => void;
}

export const createSocket = (token: string): Socket<any, any> => {
  return io(SERVER_URL, {
    // auth: {
    //   token, // Pass the token here
    // },
    query: {
      token: token,
    },
    transports: ["websocket"], // Optional: Ensure WebSocket is used
  });
};
