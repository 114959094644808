import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Marker } from "react-leaflet";
interface CustomMarkerProps {
  position: L.LatLngExpression;
  children: React.ReactNode;
  handleClick: any;
}
const CustomMarkerComponent: React.FC<CustomMarkerProps> = ({
  position,
  children,
  handleClick,
}) => {
  // console.log("first", position);
  const customIcon = L.icon({
    iconUrl: "standard.jpg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });
  return (
    <Marker
      position={position}
      icon={customIcon}
      eventHandlers={{ click: handleClick }}
    >
      {children}
    </Marker>
  );
};
export default CustomMarkerComponent;
