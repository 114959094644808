import { Group } from "@mantine/core";
import { IconUser, IconUserOff, IconUsers } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ReportCard } from "../component/ReportCard";
import {
  useLazyGetAllProvidersDashboardQuery,
  useLazyGetOwnersDashboardQuery,
  useLazyGetPassengersDashboardQuery,
  useLazyGetUsersDashboardQuery,
} from "../store/dashboard.query";

export default function UserDashboardPage() {
  const [usersCircleProgress, setUsersCircleProgress] = useState<any[]>([]);

  const [getPassengersAll, passengers] = useLazyGetPassengersDashboardQuery();
  const [getOwnersAll, owners] = useLazyGetOwnersDashboardQuery();
  const [getUsers, users] = useLazyGetUsersDashboardQuery();
  const [getProvidersAll, providers] = useLazyGetAllProvidersDashboardQuery();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    async function getTotal() {
      let total = 0;
      await getPassengersAll({ count: true }).then((response) => {
        if (response?.data?.count) {
          total += response?.data?.count;
        }
      });
      await getOwnersAll({ count: true }).then((response) => {
        if (response?.data?.count) {
          total += response?.data?.count;
        }
      });
      await getProvidersAll({ count: true }).then((response) => {
        if (response?.data?.count) {
          total += response?.data?.count;
        }
      });

      await getUsers({ count: true }).then((response) => {
        if (response?.data?.count) {
          total += response?.data?.count;
        }
      });

      setTotal(total);
    }
    getTotal();
  }, []);

  return (
    <div>
      <div className="flex w-full h-16 px-2 justify-between items-center mt-2 border border-sky-300 mb-2">
        <span className="capitalize font-semibold text-primary">
          User dashboard report
        </span>
        <span className="capitalize font-semibold text-primary">
          {"Total: "}
          {total}
        </span>
      </div>
      <Group position={"center"} spacing={25} key={1 + "_tab"}>
        <ReportCard
          {...{
            title: "Passengers",
            description: "",
            count: passengers?.data?.count,
            loading: passengers.isFetching,
            icon: <IconUsers size={100} />,
          }}
        />
        <ReportCard
          {...{
            title: "Providers",
            description: "",
            count: providers?.data?.count,
            loading: providers.isFetching,
            icon: <IconUserOff size={100} />,
          }}
        />
        <ReportCard
          {...{
            title: "Owners",
            description: "",
            count: owners?.data?.count,
            loading: owners.isFetching,
            icon: <IconUser size={100} />,
          }}
        />
        <ReportCard
          {...{
            title: "Employees",
            description: "",
            count: users?.data?.count,
            loading: users.isFetching,
            icon: <IconUser size={100} />,
          }}
        />
      </Group>
    </div>
  );
}
