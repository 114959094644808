import { Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Vehicle } from "../../../models/vehicle.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import { useLazyGetVehiclesQuery } from "../store/vehicle.query";

import { IconAlertTriangle } from "@tabler/icons";
import dayjs from "dayjs";

export default function VehicleListPage() {
  const params = useParams();
  const location = useLocation();
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["owner", "category"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [getVehicles, vehicles] = useLazyGetVehiclesQuery();

  useEffect(() => {
    getVehicles(collection);
  }, [collection]);

  useEffect(() => {
    getCategories({ skip: 0 }, true).then((categories) => {
      const data = categories.data?.data?.map((category) => {
        return {
          field: "categoryId",
          value: category.id,
          name: category.name,
          operator: "=",
        };
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, []);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/owner/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  // const showBoloExpiresIcon = (vehicle: Vehicle) => {
  //   let expiredDoc = vehicle?.boloDueDate
  //     ? dayjs(vehicle.boloDueDate).isBefore(dayjs())
  //     : false;

  //   if (!expiredDoc) return null;

  //   return (
  //     <Tooltip label="Has bolo about to expire." withArrow>
  //       <span>
  //         <IconAlertTriangle
  //           color="red"
  //           style={{ cursor: "pointer" }}
  //           tabIndex={0}
  //         />
  //       </span>
  //     </Tooltip>
  //   );
  // };
  const showDocumentsExpiresIcon = (vehicle: Vehicle) => {
    let expiredInsurance = vehicle?.insuranceCertificateDueDate
      ? dayjs(vehicle.insuranceCertificateDueDate).isBefore(dayjs())
      : false;
    let expiredBolo = vehicle?.boloDueDate
      ? dayjs(vehicle.boloDueDate).isBefore(dayjs())
      : false;

    if (!expiredBolo && !expiredInsurance) return null;

    return (
      <Tooltip label="Has expired or soon to be expired document." withArrow>
        <span>
          <IconAlertTriangle
            color="red"
            style={{ cursor: "pointer" }}
            tabIndex={0}
          />
        </span>
      </Tooltip>
    );
  };
  const data: Vehicle[] | undefined = vehicles.data?.data;
  const config: EntityConfig<Vehicle> = {
    primaryColumn: { key: "plateNumber", name: "Plate Number" },

    rootUrl: "/registration/vehicle",
    identity: "id",
    name: "plateNumber",
    visibleColumn: [
      {
        key: ["owner", "name"],
        name: "Owner",
        hideSort: true,
        render: (data) => {
          return (
            <div
              className={`flex space-x-2 text-sm ${
                data?.deletedAt ? "text-danger" : ""
              }`}
            >
              <div>
                {data?.ownerId && !data?.owner && `Owner is archived`}
                {data?.ownerId &&
                  data?.owner &&
                  `${data?.owner?.name ?? ""} - ${
                    data?.owner?.phoneNumber ?? ""
                  }`}
              </div>
              <div> {showDocumentsExpiresIcon(data)}</div>
            </div>
          );
        },
      },
      { key: ["category", "name"], name: "Category", hideSort: true },
      { key: "model", name: "Model", hideSort: true },
      { key: "code", name: "Code" },
      { key: "plateNumber", name: "Plate Number", hideSort: true },
      { key: "color", name: "Color", hideSort: true },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "enabled", name: "Status", hideSort: true },
    ],
    filter: [filterData],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={`${
          location.pathname === "/vehicle/archive" ? "detail" : viewMode
        }`}
        title={"Vehicles"}
        total={vehicles?.data?.count}
        itemsLoading={vehicles?.isLoading || vehicles?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: [
              "plateNumber",
              "code",
              "model",
              "color",
              "owner.phoneNumber",
              "owner.name",
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
