import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { PassengerPreference } from "../../../models/passenger-preference";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetPassengerPreferencesQuery } from "../store/passenger-preference.query";

export default function PassengerPreferencePage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    // includes: ["route"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getPassengerPreferences, groupedPassengerPreferences] =
    useLazyGetPassengerPreferencesQuery();

  useEffect(() => {
    getPassengerPreferences(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/route/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: PassengerPreference[] | undefined =
    groupedPassengerPreferences.data?.data;
  const config: EntityConfig<PassengerPreference> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/suggested-route",
    showDetail: false,
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "pickup", name: "Pickup", hideSort: true },
      { key: "destination", name: "destination", hideSort: true },
      {
        key: "tripType",
        name: "Shift",
        hideSort: true,
        render: (value: PassengerPreference) =>
          value?.tripType
            ? value?.tripType?.charAt(0).toUpperCase() + value.tripType.slice(1)
            : "",
      },
      {
        key: "morningPickupTime",
        name: "Morning Pickup Time",
        hideSort: true,
        render: (value) => value?.morningPickupTime || "---",
      },
      {
        key: "afternoonPickupTime",
        name: "Afternoon Pickup Time",
        hideSort: true,
        render: (value) => value?.afternoonPickupTime || "---",
      },

      { key: "createdAt", name: "Suggested At", isDate: true },
      // {
      //   key: "actions",
      //   name: "Actions",
      //   hideSort: true,
      //   render: (value) => (
      //     <Tooltip label={<span className="text-sm">View route</span>}>
      //       <Button
      //         size="small"
      //         className={
      //           " text-white text-2xs flex  items-center justify-center"
      //         }
      //         type="button"
      //         onClick={() => {
      //           navigate(
      //             `/settings-and-configurables/route/detail/active/${value?.routeId}`
      //           );
      //         }}
      //       >
      //         {<IconEye />}
      //       </Button>
      //     </Tooltip>
      //   ),
      // },

      // { key: "isActive", name: "Status" },
    ],
    filter: [
      [
        {
          field: "tripType",
          value: "One Way",
          operator: "=",
          name: "One Way",
        },
        {
          field: "tripType",
          value: "Round trip",
          operator: "=",
          name: "Round trip",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Passengers Preferences"}
        showNewButton={false}
        showExport={false}
        showSelector={false}
        showArchivedCheckBox={false}
        total={groupedPassengerPreferences?.data?.count}
        itemsLoading={groupedPassengerPreferences?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["pickup", "destination"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
