import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Avatar,
  Button,
  Divider,
  FileInput,
  Group,
  Input,
  LoadingOverlay,
  Select,
  Stack,
  Switch,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";

import {
  IconArchive,
  IconArrowBackUp,
  IconCurrentLocation,
  IconDeviceFloppy,
  IconMap,
  IconSearch,
  IconSlash,
  IconTrash,
  IconUpload,
} from "@tabler/icons";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { phoneRegExAll } from "../../../shared/utility/Tools/RegEx";
import countryCodes from "../../../shared/utility/countryCodes.json";
import {
  useActivateSchoolMutation,
  useArchiveSchoolMutation,
  useCreateSchoolMutation,
  useDeleteSchoolMutation,
  useLazyGetArchivedSchoolQuery,
  useLazyGetSchoolKidsQuery,
  useLazyGetSchoolQuery,
  useRestoreSchoolMutation,
  useUpdateLogoMutation,
  useUpdateSchoolMutation,
} from "../store/school.query";

import { dateFormat } from "../../../shared/utility/date-format/date-format";

import { useId } from "@mantine/hooks";
import { debounce } from "lodash";
import InputMask from "react-input-mask";
import { City } from "../../../models/city.model";
import { CollectionQuery } from "../../../models/collection.model";
import { Kid } from "../../../models/kids.model";
import { School, SchoolStatus } from "../../../models/school.model";
import AuthContext from "../../../shared/auth/context/authContext";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import { Location } from "../../../shared/component/Map/map-component";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { notification } from "../../../shared/component/notification/utility/notification";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { Constants } from "../../../shared/constants/Constants";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import { useLazyGetCitiesQuery } from "../../city/store/city.query";

const MapComponent = lazy(
  () => import("../../../shared/component/Map/map-component")
);

export interface Props {
  editMode: string;
}

export default function NewSchoolComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();

  const defaultValue: School = {
    id: "",
    enabled: false,
    website: "",
    isPrivate: true,
    //Main
    name: "",
    email: "",
    daysOff: 0,
    phoneNumber: "",
    contactPerson: {
      name: "",
      phoneNumber: "",
    },
    address: {
      country: "Ethiopia",
      city: "",
      subCity: "",
      woreda: "",
      houseNumber: "",
    },
    lng: 0,
    lat: 0,
    archiveReason: "",
    status: SchoolStatus.Active,
  };

  const nameRegEx =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'\-]*)$/gi;
  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 5 characters")
      .max(100, "Name should not be more than 100 characters")
      .matches(nameRegEx, "Remove invalid characters")
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExAll, "Phone number is not valid")
      .required("Phone number is required"),
    website: Yup.string().url("Invalid link").nullable(),
    contactPerson: Yup.object()
      .shape({
        name: Yup.string()
          .min(5, "Name should have at least 5 letters")
          .required("Name is required"),
        phoneNumber: Yup.string()
          .matches(phoneRegExAll, "Phone number is not valid")
          .required("Phone number is required"),
      })
      .required(),

    address: Yup.object()
      .shape({
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        subCity: Yup.string(),
        woreda: Yup.string().required("Woreda is required"),
        houseNumber: Yup.string().required("House Number is required"),
      })
      .required(),

    daysOff: Yup.number()
      .typeError("Days off should be a number.")
      .min(0, "Days off should be greater than 0")
      .max(31, "Days off should be less than 31")
      .required("Days off is required"),
  });

  const Auth = useContext(AuthContext);
  const [getSchool, school] = useLazyGetSchoolQuery();
  const [getArchivedSchool, archivedSchool] = useLazyGetArchivedSchoolQuery();
  const [createSchool, newSchool] = useCreateSchoolMutation();
  const [updateSchool, updateResponse] = useUpdateSchoolMutation();

  const [archiveSchool, archiveResponse] = useArchiveSchoolMutation();
  const [activateSchool, activateResponse] = useActivateSchoolMutation();
  const [restoreSchool, restoreCall] = useRestoreSchoolMutation();
  const [deleteSchool, deleteCall] = useDeleteSchoolMutation();
  const [updateLogo, updateLogoResponse] = useUpdateLogoMutation();

  const [getSchoolKids, kids] = useLazyGetSchoolKidsQuery();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<School>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const isPrivate = watch("isPrivate");

  let profileSchema = Yup.object().shape({
    profileData: Yup.mixed()
      .test(
        "fileSize",
        "Profile image file should be less than 2MB",
        (value) => {
          if (!value || value.length === 0) return true; // attachment is optional
          return value.size <= 2000000;
        }
      )
      .test(
        "type",
        "Only the following formats are accepted: .jpeg, .jpg, .png",
        (value) => {
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/jpg" ||
              value.mimetype === "image/jpeg" ||
              value.mimetype === "image/png" ||
              value.mimetype === "image/jpg")
          );
        }
      ),
  });

  const profileForm = useForm<{ profileData: any }>({
    resolver: yupResolver(profileSchema),
    mode: "all",
  });

  const [getCities, cities] = useLazyGetCitiesQuery();
  const [previewProfile, setPreviewProfile] = useState<any>(null);
  const [data] = useState<any>(countryCodes);
  const id = useId();
  const e_id = useId();
  const [code, setCode] = useState("+251");
  const [e_code, setE_Code] = useState("+251");
  const [country, setCountry] = useState("");
  const [, setSchoolId] = useState("");

  const locationDefault = { lat: 8.993441, lng: 38.7663447 };

  const [location, setLocation] = useState<Location>(locationDefault);

  const [locationLoading, setLocationLoading] = useState<boolean>(false);

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: [
      "school",
      "groupAssignment",
      "groupAssignment.group",
      "groupAssignment.driver",
      "category",
    ],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  function checkLocation() {
    setLocationLoading(true);
    setTimeout(async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          notification("success", "Location has been resolved successfully!");
          setLocationLoading(false);
        },
        (err) => notification("error", "Error resolving your location!")
      );
    }, 0);
  }

  function resetForm() {
    reset(defaultValue);
    setCountry("");
  }

  useEffect(() => {
    getCities({
      skip: 0,
      top: 100,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
    });
  }, []);

  useEffect(() => {
    if (editMode == "detail") {
      getSchoolKids({ id: params.id ?? "", q: collection });
    }
  }, [collection, params]);

  useEffect(() => {
    if (editMode === "detail") {
      reset(defaultValue);

      profileForm.reset({ profileData: undefined });
      setPreviewProfile("");

      if (params?.type === "archived") {
        getArchivedSchool(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setLocation({ lat: response.data.lat, lng: response.data.lng });
          if (response?.data?.logo) {
            let data = {
              originalname: response?.data?.logo.originalname,
              path: response?.data?.logo.path,
              filename: response?.data?.logo.filename,
              mimetype: response?.data?.logo.mimetype,
            };
            setPreviewProfile(downloadUrlParser(data));
            setCountry(response?.data?.address.country);
          }
          setSchoolId(response.data.id);
        });
      } else {
        getSchool(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setLocation({ lat: response?.data?.lat, lng: response?.data?.lng });
          if (response?.data?.logo) {
            let data = {
              originalname: response?.data?.logo?.originalname,
              path: response?.data?.logo?.path,
              filename: response?.data?.logo?.filename,
              mimetype: response?.data?.logo?.mimetype,
            };
            setPreviewProfile(downloadUrlParser(data));
            setCountry(response?.data?.address.country);
          }
          setSchoolId(response?.data?.id);
        });
      }
    } else {
      resetForm();
    }
  }, [params.id, editMode, getSchool, updateSchool]);

  function changeProfile() {
    try {
      const objectUrl = URL.createObjectURL(
        profileForm.getValues("profileData")
      );
      setPreviewProfile(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } catch (e) {
      return null;
    }
  }

  function onSubmit(data: any) {
    data.phoneNumber = data.phoneNumber.replace(/\s/g, "");
    if (editMode === "new") {
      createSchool(data)
        .unwrap()
        .then((result) => {
          reset(defaultValue);
        })
        .catch((err) => {});
    } else {
      const school: School = {
        id: params.id,
        ...data,
      };

      updateSchool(school)
        .unwrap()
        .then((response) => {})
        .catch((err) => {});
    }
  }

  function SubmitProfile() {
    if (profileForm.getValues("profileData")) {
      const formData = new FormData();
      formData.append("logo", profileForm.getValues("profileData"));
      formData.append("id", params.id ?? "");
      updateLogo({ id: params.id ?? "", data: formData }).then(
        (response: any) => {
          let data = {
            originalname: response?.data?.logo.originalname,
            path: response?.data?.logo.path,
            filename: response?.data?.logo.filename,
            mimetype: response?.data?.logo.mimetype,
          };
          setPreviewProfile(downloadUrlParser(data));
        }
      );
    }
  }

  function setName(name: string) {}

  const onError = (errors: any, e: any) => {};

  function setSearchLocation(position: google.maps.LatLngLiteral) {
    setLocation({ lat: position.lat, lng: position.lng });
  }

  const [activeTab, setActiveTab] = useState<string | null>("main");

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New School" : school?.data?.name ?? ""}
        item={school}
        path={`/registration/school/detail/${params?.type}/${
          params.id ?? "e622d73c-b708-4f53-ad38-0e50fb097b61"
        }`}
        id={params.id ?? "e622d73c-b708-4f53-ad38-0e50fb097b61"}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (school.isFetching || updateResponse.isLoading)
          }
        />

        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
            <Tabs.Tab value="location">Location</Tabs.Tab>
            {editMode === "detail" && <Tabs.Tab value="kids">Kids</Tabs.Tab>}
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex  justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <Accordion
                  defaultValue={["info", "address", "other"]}
                  multiple
                  variant="separated"
                  classNames={{ control: "bg-secondary text-gray-700" }}
                >
                  <Accordion.Item value="info">
                    <Accordion.Control>School Information</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.name && `${errors?.name?.message}`}
                            withAsterisk
                            label="Name"
                            placeholder="Name"
                            {...register("name")}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.email && `${errors?.email?.message}`}
                            withAsterisk
                            label="Email"
                            placeholder="Email"
                            {...register("email")}
                          />
                        </div>

                        <div className="flex w-full space-x-4 justify-between">
                          <div className="flex w-full">
                            <Select
                              radius={"xs"}
                              searchable
                              nothingFound={
                                <div className="fle w-full justify-center items-center">
                                  <EmptyIcon />
                                </div>
                              }
                              onChange={(code) => {
                                code && setCode(code);
                              }}
                              classNames={{
                                input:
                                  "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                              }}
                              value={code}
                              label="Code"
                              data={data?.map((item: any) => ({
                                label: `${item.name} (${item.dial_code})`,
                                value: item.dial_code,
                                key: item.name,
                              }))}
                              maxDropdownHeight={400}
                            />
                            <Input.Wrapper
                              id={id}
                              className="w-full"
                              label="Phone number"
                              required
                              error={
                                errors.phoneNumber &&
                                `${errors?.phoneNumber?.message}`
                              }
                            >
                              <Input
                                classNames={{ input: "rounded-l-none" }}
                                component={InputMask}
                                placeholder="Phone Number"
                                mask={`${code} 999 999 999`}
                                id={id}
                                {...register("phoneNumber")}
                              />
                            </Input.Wrapper>
                          </div>
                          <div className="px-4 w-full flex items-center justify-start">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.website && `${errors?.website?.message}`
                              }
                              label="Website"
                              placeholder="Website"
                              {...register("website")}
                            />
                          </div>
                        </div>
                        <div className="flex w-full space-x-4  justify-between">
                          <Switch
                            className="w-full mt-8"
                            {...register("isPrivate")}
                            checked={isPrivate}
                            label="Is the school Private?"
                          />
                          <div className="px-4 w-full flex items-center justify-start">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.daysOff && `${errors?.daysOff?.message}`
                              }
                              label="Days Off"
                              placeholder="Days Off"
                              {...register("daysOff")}
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="other">
                    <Accordion.Control>
                      Additional Information
                    </Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4 my-5">
                        <div className="flex w-full space-x-4 justify-between">
                          <TextInput
                            radius={"xs"}
                            classNames={{
                              label: "dark:text-white",
                              input: " rounded rounded-l-none",
                            }}
                            className="w-full rounded rounded-l-0"
                            error={
                              errors.contactPerson?.name &&
                              `${errors?.contactPerson?.name?.message}`
                            }
                            withAsterisk
                            label="Contact Person Name"
                            placeholder="Contact person name"
                            {...register("contactPerson.name")}
                          />
                          <div className="flex w-full">
                            <Select
                              radius={"xs"}
                              searchable
                              nothingFound={
                                <div className="fle w-full justify-center items-center">
                                  <EmptyIcon />
                                </div>
                              }
                              onChange={(code) => {
                                code && setE_Code(code);
                              }}
                              classNames={{
                                input:
                                  "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                              }}
                              value={e_code}
                              label="Code"
                              data={data?.map((item: any) => ({
                                label: `${item.name} (${item.dial_code})`,
                                value: item.dial_code,
                                key: item.name,
                              }))}
                              maxDropdownHeight={400}
                            />
                            <Input.Wrapper
                              id={e_id}
                              className="w-full"
                              label="Contact Person Phone Number"
                              required
                              error={
                                errors.contactPerson?.phoneNumber &&
                                `${errors?.contactPerson?.phoneNumber?.message}`
                              }
                            >
                              <Input
                                classNames={{ input: "rounded-l-none" }}
                                component={InputMask}
                                placeholder="Phone Number"
                                mask={`${code} 999 999 999`}
                                id={e_id}
                                {...register("contactPerson.phoneNumber")}
                              />
                            </Input.Wrapper>
                          </div>
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>

                  {editMode === "detail" && (
                    <Accordion.Item value="profile">
                      <Accordion.Control>Logo</Accordion.Control>
                      <Accordion.Panel>
                        <div className="flex  justify-center h-full">
                          <form
                            onSubmit={profileForm.handleSubmit(
                              onSubmit,
                              onError
                            )}
                            className="w-full gap-y-4 dark:text-white"
                          >
                            <div className="flex-col space-y-4">
                              <div className="flex w-full justify-center mt-2">
                                <Avatar
                                  size="xl"
                                  color="blue"
                                  radius="xl"
                                  src={previewProfile}
                                  alt={school?.data?.name}
                                >
                                  {school.data?.name.charAt(0) ?? "L"}
                                </Avatar>
                              </div>

                              <div className="flex w-full justify-between mt-2">
                                <div className="px-4 w-full flex items-center justify-start">
                                  <Controller
                                    control={profileForm.control}
                                    name="profileData"
                                    render={({ field }) => (
                                      <FileInput
                                        clearable
                                        className="w-full"
                                        error={
                                          profileForm.formState.errors
                                            .profileData &&
                                          `${profileForm.formState.errors.profileData?.message}`
                                        }
                                        label="Upload Logo"
                                        accept="image/png,image/jpeg,image/jpg"
                                        icon={<IconUpload size={14} />}
                                        {...field}
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="w-full flex justify-end mt-2 items-center">
                                <Group
                                  className={"px-4"}
                                  position="right"
                                  mt="xl"
                                >
                                  <Button
                                    classNames={{ label: "flex space-x-1 " }}
                                    variant="default"
                                    className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                                    onClick={() => {
                                      setPreviewProfile(
                                        school?.data?.logo?.path
                                      );
                                      profileForm.reset();
                                      // Reset file input text - form reset couldn't reset it!
                                      const file: HTMLInputElement | null =
                                        document.querySelector(".file");
                                      if (file) file.value = "";
                                    }}
                                    size="xs"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="fill-current h-6"
                                        viewBox="0 0 32 32"
                                      >
                                        <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                                      </svg>
                                    </span>
                                    <span>Reset</span>
                                  </Button>

                                  <Button
                                    variant="filled"
                                    className="bg-primary text-white"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                    loading={updateLogoResponse.isLoading}
                                    onClick={() => {
                                      if (
                                        profileForm.getValues("profileData")
                                      ) {
                                        SubmitProfile();
                                      } else {
                                        profileForm.setError("profileData", {
                                          message: "Please select an image",
                                        });
                                      }
                                    }}
                                  >
                                    <span>
                                      <IconDeviceFloppy
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Submit</span>
                                  </Button>
                                </Group>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  )}

                  <Accordion.Item value="address">
                    <Accordion.Control>Address</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <Controller
                            control={control}
                            name="address.country"
                            render={({ field }) => (
                              <Select
                                className="w-full"
                                withAsterisk
                                searchable
                                nothingFound={
                                  <div className="fle w-full justify-center items-center">
                                    <EmptyIcon />
                                  </div>
                                }
                                error={
                                  errors.address?.country &&
                                  `${errors?.address?.country?.message}`
                                }
                                radius={"xs"}
                                classNames={{
                                  label: "dark:text-white",
                                }}
                                label="Country"
                                placeholder="Country"
                                data={data?.map((item: any) => ({
                                  label: `${item.name}`,
                                  value: item.name,
                                  key: item.name,
                                }))}
                                maxDropdownHeight={400}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            control={control}
                            name="address.city"
                            render={({ field }) => (
                              <Select
                                withAsterisk
                                className="w-full"
                                searchable
                                nothingFound={
                                  <div className="fle w-full justify-center items-center">
                                    <EmptyIcon />
                                  </div>
                                }
                                error={
                                  errors.address?.city &&
                                  `${errors?.address?.city?.message}`
                                }
                                radius={"xs"}
                                classNames={{
                                  label: "dark:text-white",
                                }}
                                defaultValue="Ethiopia"
                                label="City"
                                data={
                                  cities.data
                                    ? cities.data.data.map((item: City) => ({
                                        label: `${item.name}`,
                                        value: item.name ?? "",
                                      }))
                                    : []
                                }
                                maxDropdownHeight={400}
                                {...field}
                              />
                            )}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.subCity &&
                              `${errors?.address?.subCity?.message}`
                            }
                            label="Sub-City"
                            placeholder="Sub-city"
                            {...register("address.subCity")}
                          />
                        </div>
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.woreda &&
                              `${errors?.address?.woreda?.message}`
                            }
                            withAsterisk
                            label="Woreda/Kebele"
                            placeholder="Woreda/Kebele"
                            {...register("address.woreda")}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.houseNumber &&
                              `${errors?.address?.houseNumber?.message}`
                            }
                            withAsterisk
                            label="House Number"
                            placeholder="House Number"
                            {...register("address.houseNumber")}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between mt-5">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.lng && `${errors?.lng?.message}`}
                            withAsterisk
                            label="Longitude"
                            value={location.lng}
                            onChange={(e) => {
                              try {
                                const val = parseFloat(e.currentTarget.value);
                                setValue("lng", isNaN(val) ? 0 : val);
                                setLocation({
                                  lng: isNaN(val) ? 0 : val,
                                  lat: location.lat ?? 0,
                                });
                              } catch (e) {}
                            }}
                          />

                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.lat && `${errors?.lat?.message}`}
                            withAsterisk
                            label="Latitude"
                            value={location.lat}
                            onChange={(e) => {
                              try {
                                const val = parseFloat(e.currentTarget.value);
                                setValue("lat", isNaN(val) ? 0 : val);
                                setLocation({
                                  lat: isNaN(val) ? 0 : val,
                                  lng: location.lng,
                                });
                              } catch (e) {}
                            }}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between">
                          <Button
                            variant={"default"}
                            onClick={() => {
                              setActiveTab("location");
                            }}
                            className="w-1/5 bg-primary text-white py-2 hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                            classNames={{ label: "dark:text-white" }}
                          >
                            <IconCurrentLocation />
                            <IconSlash />
                            <IconMap />
                          </Button>
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={newSchool.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {(params?.type === "archived" &&
                          archivedSchool?.data?.deletedAt) ||
                        (params?.type === "active" &&
                          school?.data?.deletedAt) ? (
                          <>
                            <Confirmation
                              type={"danger"}
                              message={
                                "Are you sure you want to delete it permanently?"
                              }
                              onYes={() =>
                                deleteSchool(`${params?.id}`).then(
                                  (response: any) => {
                                    if (response?.data) {
                                      navigate(-1);
                                    }
                                  }
                                )
                              }
                              header={`Permanent Delete confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={deleteCall.isLoading}
                                className={`bg-danger text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconTrash size={20} strokeWidth={1.5} />
                                <span>Delete</span>{" "}
                              </Button>
                            </Confirmation>
                            <Confirmation
                              type={"notify"}
                              message={`Are you sure you want to restore it`}
                              onYes={() => restoreSchool(`${params?.id}`)}
                              header={`Restore confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={restoreCall.isLoading}
                                className={`bg-green-500 text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconArrowBackUp size={20} strokeWidth={1.5} />
                                <span>Restore</span>
                              </Button>
                            </Confirmation>
                          </>
                        ) : (
                          <>
                            {Auth?.user?.id !== school?.data?.id && (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this school?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this school?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveSchool({
                                      id: `${
                                        params.id ??
                                        "e622d73c-b708-4f53-ad38-0e50fb097b61"
                                      }`,
                                      reason: data,
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>

                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to " +
                                    (school.data?.enabled
                                      ? "deactivate"
                                      : "activate") +
                                    " it?"
                                  }
                                  onYes={() =>
                                    activateSchool(
                                      `${
                                        params.id ??
                                        "e622d73c-b708-4f53-ad38-0e50fb097b61"
                                      }`
                                    )
                                  }
                                  header={
                                    (school.data?.enabled
                                      ? "Deactivate"
                                      : "Activate") + " Confirmation"
                                  }
                                >
                                  <Button
                                    variant="filled"
                                    loading={activateResponse.isLoading}
                                    className={`${
                                      school.data?.enabled
                                        ? " bg-danger"
                                        : "bg-green-500"
                                    } text-white`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="fill-current h-6"
                                        viewBox="0 0 48 48"
                                      >
                                        <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                      </svg>
                                    </span>
                                    {school.data?.enabled ? (
                                      <span>Deactivate</span>
                                    ) : (
                                      <span>Activate</span>
                                    )}
                                  </Button>
                                </Confirmation>
                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="location" pt="xs">
            <div className="flex p-5 justify-between h-full">
              <div>
                <Text>Latitude: {location.lat}</Text>
                <Text>Longitude: {location.lng}</Text>
              </div>
              <div className="flex-col space-y-4">
                <Button
                  classNames={{ label: "flex space-x-1 " }}
                  variant="default"
                  className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                  leftIcon={<IconCurrentLocation />}
                  loading={locationLoading}
                  onClick={checkLocation}
                >
                  <span>Load Current Location</span>
                </Button>
              </div>
            </div>
            <Divider className={"my-2"} />

            {/*StaticMap Component*/}
            <Suspense>
              <MapComponent
                setLocation={setLocation}
                location={location}
                placesProps={{
                  setLocation: setSearchLocation,
                  setName: setName,
                }}
              />
            </Suspense>

            <Divider className={"my-2"} />
            <div className="w-full flex justify-end items-center">
              <Button
                variant="filled"
                className="bg-primary text-white"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={locationLoading}
                onClick={() => {
                  setValue("lat", location.lat);
                  setValue("lng", location.lng);
                  setActiveTab("main");
                }}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </div>
          </Tabs.Panel>

          {editMode === "detail" && (
            <Tabs.Panel value="kids" pt="xs">
              <div className="flex-col space-y-4 overflow-x-auto">
                <div className={"min-w-max"}>
                  <Stack align={"flex-start"} className={"mt-3 mb-5"}>
                    <Group position={"apart"} className={"w-full"}>
                      <div className={"ml-3"}>Kids List</div>
                      <div className={"ml-3"}>
                        <Input
                          radius={"sm"}
                          placeholder="Search here"
                          className={"w-72"}
                          size="xs"
                          onKeyUp={debounce(
                            (event: any) =>
                              setCollection({
                                ...collection,
                                search: event.target.value,
                                searchFrom: ["name"],
                              }),
                            1000
                          )}
                          rightSection={
                            <div className="h-full flex items-center">
                              <IconSearch className="opacity-50" />
                            </div>
                          }
                        />
                      </div>
                    </Group>
                  </Stack>

                  <div className=" relative overflow-x-auto ">
                    <LoadingOverlay visible={kids.isFetching} />
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            Group
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Name
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Age
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Grade
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Driver
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Transportation Time
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Travel With
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Category
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Start Time
                          </th>
                          <th scope="col" className="py-3 px-6">
                            School
                          </th>

                          <th scope="col" className="py-3 px-6">
                            Distance from school
                          </th>
                          <th scope="col" className="py-3 px-6">
                            price
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {kids.isSuccess &&
                          kids.data.data.length > 0 &&
                          kids.data?.data.map((kid: Kid, idx: number) => (
                            <tr
                              key={idx}
                              className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                            >
                              <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {kid?.groupAssignment?.groupId
                                  ? kid?.groupAssignment?.group?.name
                                  : "Group not assigned"}
                              </th>
                              <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {kid.name}
                              </th>
                              <td className="py-4 px-6 text-xs">{kid.age}</td>
                              <td className="py-4 px-6 text-xs">{kid.grade}</td>
                              <td className="py-4 px-6 text-xs">
                                {kid?.groupAssignment?.driver
                                  ? `${kid?.groupAssignment?.driver?.name} - ${kid?.groupAssignment?.driver?.phoneNumber}`
                                  : "Driver Not Assigned"}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {kid.transportationTime}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {kid.kidTravelStatus}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {kid?.categoryName}
                              </td>
                              <td className="py-4 px-6 text-center">
                                {dateFormat(kid?.startDate)}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {kid?.schoolName}
                              </td>
                              <td className="py-4 px-6 text-center">
                                {kid?.distanceFromSchool &&
                                kid.distanceFromSchool > 1
                                  ? kid.distanceFromSchool
                                  : 0}{" "}
                                km
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {kid?.kidFee &&
                                  Intl.NumberFormat("en", {
                                    maximumFractionDigits: 2,
                                  }).format(kid?.kidFee) + " ETB"}
                              </td>

                              <td className="py-4 px-6 text-center">
                                {kid?.groupAssignment?.status ?? "Unassigned"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {kids.isLoading || !kids?.data?.data.length ? (
                      <div className="w-full relative flex justify-center items-center h-56">
                        <LoadingOverlay
                          visible={kids.isLoading}
                          overlayBlur={2}
                        />
                        <EmptyIcon />
                      </div>
                    ) : (
                      <></>
                    )}
                    {kids?.isSuccess && kids?.data?.data?.length > 0 && (
                      <div className="w-full flex justify-end items-center mt-2 mb-2">
                        <Pagination
                          total={kids?.data ? kids?.data?.count : 0}
                          defaultPageSize={10}
                          pageSize={[5, 10, 15, 20]}
                          onPaginationChange={(skip: number, top: number) => {
                            setCollection({
                              ...collection,
                              skip: skip,
                              top: top,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}
