/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { FaqCategory } from "../../../models/faqCategory.model";

import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";

import { FAQCATEGORY_ENDPOINT } from "../faq-category.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const faqCategoryQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFaqCategories: builder.query<Collection<FaqCategory>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${FAQCATEGORY_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          if (error.status === 403) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        }
      },
    }),
    createFaqCategory: builder.mutation<FaqCategory, FaqCategory>({
      query: (data: FaqCategory) => ({
        url: `${FAQCATEGORY_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategories",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteFaqCategory: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${FAQCATEGORY_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission: "manage-faqs",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              faqCategoryQuery.util.updateQueryData(
                "getFaqCategories",
                currentCollection,
                (draft) => {
                  draft.data = draft.data.filter(
                    (faqCategory) => faqCategory.id !== id
                  );
                  draft.count -= 1;
                }
              )
            );
            dispatch(
              faqCategoryQuery.util.updateQueryData(
                "getArchivedFaqCategories",
                archivedCollection,
                (draft) => {
                  draft.data = draft.data.filter(
                    (faqCategory) => faqCategory.id !== id
                  );
                  draft.count -= 1;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getFaqCategory: builder.query<FaqCategory, string>({
      query: (id: string) => ({
        url: `${FAQCATEGORY_ENDPOINT.faq_category}/${id}`,
        method: "GET",
      }),
    }),
    getArchivedFaqCategory: builder.query<FaqCategory, string>({
      query: (id: string) => ({
        url: `${FAQCATEGORY_ENDPOINT.archived_category}/${id}`,
        method: "GET",
      }),
    }),
    updateFaqCategory: builder.mutation<FaqCategory, FaqCategory>({
      query: (data: FaqCategory) => ({
        url: `${FAQCATEGORY_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategories",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faqCategory) => {
                  if (faqCategory.id === data.id) {
                    return data;
                  } else {
                    return faqCategory;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveFaqCategory: builder.mutation<FaqCategory, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${FAQCATEGORY_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategories",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faqCategory) => {
                  if (faqCategory.id === arg.id) {
                    return data;
                  } else {
                    return faqCategory;
                  }
                });
              }
            )
          );
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategory",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getArchivedFaqCategory",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedFaqCategories: builder.query<
      Collection<FaqCategory>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${FAQCATEGORY_ENDPOINT.archived_categories}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    restoreFaqCategory: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${FAQCATEGORY_ENDPOINT.restore_category}/${id}`,
        method: "POST",
        permission: "manage-faqs",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategories",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faqCategory) => {
                  if (faqCategory.id === id) {
                    return data;
                  } else {
                    return faqCategory;
                  }
                });
              }
            )
          );
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getFaqCategory",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          dispatch(
            faqCategoryQuery.util.updateQueryData(
              "getArchivedFaqCategory",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetFaqCategoryQuery,
  useLazyGetFaqCategoriesQuery,
  useCreateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
  useArchiveFaqCategoryMutation,
  useLazyGetArchivedFaqCategoriesQuery,
  useRestoreFaqCategoryMutation,

  useLazyGetArchivedFaqCategoryQuery,
} = faqCategoryQuery;
