/* eslint-disable @typescript-eslint/no-explicit-any */
import { BusStop } from "../../../models/bus-stop.model";
import { City } from "../../../models/city.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CITY_ENDPOINT } from "../city.endpoint";

let cityCollection: CollectionQuery;
let archivedCityCollection: CollectionQuery;
let cityBusStopsCollection: { query: CollectionQuery; CityId: string };

const cityQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCity: builder.query<City, string>({
      query: (id: string) => ({
        url: `${CITY_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCity: builder.query<City, string>({
      query: (id: string) => ({
        url: `${CITY_ENDPOINT.archivedCity}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCities: builder.query<Collection<City>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CITY_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedCityCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getCities: builder.query<Collection<City>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CITY_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            cityCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getCityBusStops: builder.query<
      Collection<BusStop>,
      { query: CollectionQuery; CityId: string }
    >({
      query: (data) => ({
        url: `${CITY_ENDPOINT.city_bus_stop}/${data.CityId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            cityBusStopsCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createCity: builder.mutation<City, City>({
      query: (newData: any) => ({
        url: `${CITY_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission: "manage-cities",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              cityQuery.util.updateQueryData(
                "getCities",
                cityCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
            notification("success", "Successfully created");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateCity: builder.mutation<City, any>({
      query: (newData: any) => ({
        url: `${CITY_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-cities",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              cityQuery.util.updateQueryData(
                "getCities",
                cityCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((city) => {
                      if (city.id === data.id) return data;
                      else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateCity: builder.mutation<City, City>({
      query: (newData: any) => ({
        url: `${CITY_ENDPOINT.update}`,
        method: "put",
        data: { ...newData, isActive: !newData.isActive },
        permission: "manage-cities",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              cityQuery.util.updateQueryData(
                "getCities",
                cityCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((city) => {
                      if (city.id === data.id) return data;
                      else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              cityQuery.util.updateQueryData(
                "getCity",
                `${param.id}`,
                (draft) => {
                  draft.isActive = data.isActive;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveCity: builder.mutation<City, { id: string; reason: string }>({
      query: (data) => ({
        url: `${CITY_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-cities",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              cityQuery.util.updateQueryData(
                "getCities",
                cityCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((city) => {
                      if (city.id == query?.id) {
                        return data;
                      } else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              cityQuery.util.updateQueryData("getCity", query?.id, (draft) => {
                if (data) {
                  draft.deletedAt = data.deletedAt;
                }
              })
            );
            dispatch(
              cityQuery.util.updateQueryData(
                "getArchivedCity",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data.deletedAt;
                  }
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteCity: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CITY_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-cities",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              cityQuery.util.updateQueryData(
                "getCities",
                cityCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((city) => city.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreCity: builder.mutation<City, string>({
      query: (id: string) => ({
        url: `${CITY_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-cities",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            cityQuery.util.updateQueryData(
              "getCities",
              cityCollection,
              (draft) => {
                if (data) {
                  draft.data = draft.data.map((city) => {
                    if (city.id === id) {
                      return data;
                    } else {
                      return city;
                    }
                  });
                }
              }
            )
          );
          dispatch(
            cityQuery.util.updateQueryData("getCity", id, (draft) => {
              if (data) {
                draft.deletedAt = data.deletedAt;
              }
            })
          );
          dispatch(
            cityQuery.util.updateQueryData("getArchivedCity", id, (draft) => {
              if (data) {
                draft.deletedAt = data.deletedAt;
              }
            })
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetCityQuery,
  useLazyGetCityBusStopsQuery,
  useLazyGetArchivedCityQuery,
  useLazyGetCitiesQuery,
  useLazyGetArchivedCitiesQuery,
  useCreateCityMutation,
  useUpdateCityMutation,
  useArchiveCityMutation,
  useActivateCityMutation,
  useRestoreCityMutation,
  useDeleteCityMutation,
} = cityQuery;
