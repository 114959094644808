import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { FaqCategory } from "../../../models/faqCategory.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetFaqCategoriesQuery } from "../store/faq-category.query";

export default function FaqCategoryPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getFaqCategories, faqCategorys] = useLazyGetFaqCategoriesQuery();

  useEffect(() => {
    getFaqCategories(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/faq-category/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  const data: FaqCategory[] | undefined = faqCategorys.data?.data;
  const config: EntityConfig<FaqCategory> = {
    primaryColumn: {
      key: "name",
      name: "Name",
      hideSort: true,
      render: (faqCategory) => {
        return (
          <Text
            size="sm"
            className={faqCategory?.deletedAt ? "text-danger" : ""}
          >
            {faqCategory.name}
          </Text>
        );
      },
    },
    detailUrl: "detail",
    rootUrl: "/faq-category",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        hideSort: true,
        render: (faqCategory) => {
          return (
            <Text
              size="sm"
              className={faqCategory?.deletedAt ? "text-danger" : ""}
            >
              {faqCategory.name}
            </Text>
          );
        },
      },

      { key: "createdAt", name: "Registered at", isDate: true },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        showExport={false}
        viewMode={viewMode}
        showSelector={false}
        title={"FAQ Categories"}
        total={faqCategorys?.data?.count}
        itemsLoading={faqCategorys.isLoading || faqCategorys?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
