export const PASSENGER_PREFERENCE_ENDPOINT = {
  detail: `${process.env.REACT_APP_API}/passenger-preferences/get-passenger-preference`,
  delete: `${process.env.REACT_APP_API}/passenger-preferences/delete-passenger-preference`,
  toggleStatus: `${process.env.REACT_APP_API}/passenger-preferences/activate-or-block-passenger-preference`,
  archive: `${process.env.REACT_APP_API}/passenger-preferences/archive-passenger-preference`,
  restore: `${process.env.REACT_APP_API}/passenger-preferences/restore-passenger-preference`,
  archivedList: `${process.env.REACT_APP_API}/passenger-preferences/get-archived-passenger-preferences`,
  archivedPassengerPreference: `${process.env.REACT_APP_API}/passenger-preferences/get-archived-passenger-preference`,
  list: `${process.env.REACT_APP_API}/passenger-preferences/get-passenger-preferences`,
  create: `${process.env.REACT_APP_API}/passenger-preferences/create-passenger-preference`,
  update: `${process.env.REACT_APP_API}/passenger-preferences/update-passenger-preference`,
  groupedList: `${process.env.REACT_APP_API}/passenger-preferences/group-passenger-preferences`,
};
