/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { PassengerPreference } from "../../../models/passenger-preference";

import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { PASSENGER_PREFERENCE_ENDPOINT } from "../passenger-preference.endpoint";

let passengerPreferenceCollection: CollectionQuery;

let archivedPassengerPreferenceCollection: CollectionQuery;

const passengerPreferenceQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getPassengerPreference: builder.query<PassengerPreference, string>({
      query: (id: string) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedPassengerPreference: builder.query<PassengerPreference, string>({
      query: (id: string) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.archivedPassengerPreference}/${id}`,
        method: "get",
      }),
    }),
    getGroupedPassengerPreferences: builder.query<
      Collection<PassengerPreference>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.groupedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            // passengerPreferenceCollection = param;
          }
        } catch {}
      },
    }),

    getArchivedPassengerPreferences: builder.query<
      Collection<PassengerPreference>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedPassengerPreferenceCollection = param;
          }
        } catch {}
      },
    }),

    getPassengerPreferences: builder.query<
      Collection<PassengerPreference>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            passengerPreferenceCollection = param;
          }
        } catch {}
      },
    }),

    createPassengerPreference: builder.mutation<
      PassengerPreference,
      PassengerPreference
    >({
      query: (newData: any) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getPassengerPreferences",
                passengerPreferenceCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updatePassengerPreference: builder.mutation<PassengerPreference, any>({
      query: (newData: any) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.update}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getPassengerPreferences",
                passengerPreferenceCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deletePassengerPreference: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.delete}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getArchivedPassengerPreferences",
                archivedPassengerPreferenceCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((route) => route.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restorePassengerPreference: builder.mutation<PassengerPreference, string>({
      query: (id: string) => ({
        url: `${PASSENGER_PREFERENCE_ENDPOINT.restore}/${id}`,
        method: "post",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getPassengerPreferences",
                passengerPreferenceCollection,
                (draft) => {
                  draft.data = draft?.data?.map((route) => {
                    if (route.id === id) {
                      return data;
                    } else {
                      return route;
                    }
                  });
                }
              )
            );
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getPassengerPreference",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              passengerPreferenceQuery.util.updateQueryData(
                "getArchivedPassengerPreference",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetPassengerPreferenceQuery,
  useLazyGetGroupedPassengerPreferencesQuery,
  useLazyGetArchivedPassengerPreferenceQuery,
  useLazyGetPassengerPreferencesQuery,
  useLazyGetArchivedPassengerPreferencesQuery,
  useCreatePassengerPreferenceMutation,
  useUpdatePassengerPreferenceMutation,
  useRestorePassengerPreferenceMutation,
  useDeletePassengerPreferenceMutation,
} = passengerPreferenceQuery;
