import { List, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCarSuv, IconRoute, IconUserCircle } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { CollectionQuery } from "../../../models/collection.model";
import { useLazyGetRouteAssignmentsQuery } from "../../route-assignment/store/route-assignment.query";
import LiveTrackingComponent from "../component/live-tracking.component";
import RouteTableComponent from "../component/route-table.component";
function MyMapComponent() {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedDriver, setSelectedDriver] = useState<any>();
  // const [getTrips, tripsResponse] = useLazyGetTripListsQuery();
  const [getRouteAssignments, routeAssignments] =
    useLazyGetRouteAssignmentsQuery();
  const [collection, setCollection] = useState<CollectionQuery>({
    top: 10,
    skip: 0,
    includes: [
      "route",
      "driver",
      "driver.vehicle",
      "bookings",
      "bookings.trips",
    ],
    filter: [[{ field: "trips.isCompleted", operator: "=", value: false }]],
  });
  const [location, setLocation] = useState<L.LatLngExpression>([
    9.011586459072317, 38.75900792457031,
  ]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation([position.coords.latitude, position.coords.longitude]);
        },
        (err) => {}
      );
    } else {
      setLocation([9.010908288422344, 38.7620549137963]);
    }
  }, []);
  useEffect(() => {
    // const interval = setInterval(() => {
    getRouteAssignments(collection);
    // }, 30000);

    // return () => clearInterval(interval);
  }, [collection]);

  function refresh() {
    getRouteAssignments(collection);
  }

  // console.log("selectedDriver", selectedDriver);
  return (
    <div className="m-10 ">
      <div className="flex w-full gap-5">
        <RouteTableComponent
          setSelectedDriver={setSelectedDriver}
          setCollection={setCollection}
          collection={collection}
          openDriverModal={open}
          setLocation={setLocation}
          refresh={refresh}
          assignments={routeAssignments?.data?.data}
          isLoading={
            routeAssignments?.isLoading || routeAssignments?.isFetching
          }
          assignmentsCount={routeAssignments?.data?.count}
        />
        <LiveTrackingComponent
          trackedDriversData={routeAssignments?.data?.data}
          setSelectedDriver={setSelectedDriver}
          location={location}
          setLocation={setLocation}
          open={open}
        />
      </div>

      <Modal opened={opened} onClose={close} title={"Driver Detail"}>
        <List>
          <List.Item icon={<IconUserCircle />}>
            Driver:{" "}
            {`${selectedDriver?.driver?.name} (${selectedDriver?.driver?.phoneNumber})`}
          </List.Item>
          <List.Item icon={<IconCarSuv />}>
            Vehicle: {selectedDriver?.driver?.vehicle?.model}
            {", "}
            {selectedDriver?.driver?.vehicle?.plateNumber}
          </List.Item>
          {/* <List.Item icon={<IconCarSuv />}>
            Plate Number: {selectedDriver?.vehicle?.plateNumber}
          </List.Item> */}
          <List.Item icon={<IconRoute />}>
            Route Name: {selectedDriver?.route?.name}
          </List.Item>
        </List>
      </Modal>
    </div>
  );
}
export default React.memo(MyMapComponent);
