import { Card, Group, Stack, Text } from "@mantine/core";

export interface ReportCardData {
  title: string;
  description: string;
  count?: number | string;
  icon: any;
  loading?: boolean;
  key?: number;
  obj?: any;
  onClick?: () => void;
}

export function formatCurrency(val: number) {
  return val
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const ReportCard: React.FC<ReportCardData> = (props: ReportCardData) => {
  return (
    <div
      key={props.key}
      className="rounded-lg cursor-pointer hover:shadow-primary border shadow-lg w-56 h-24 px-2 flex items-center space-x-2"
      onClick={() => props.onClick?.()}
    >
      <div className="rounded-full border h-12 w-12 flex items-center p-3 border-primary">
        {props.icon}
      </div>
      <div className=" flex-col ">
        <p className="text-gray-500">{props.title}</p>
        <p className="text-2xl font-medium">{props.count ?? "--"}</p>
      </div>
    </div>
  );
};

function Code(props: ReportCardData) {
  return (
    <Card
      shadow="sm"
      p="md"
      radius="md"
      withBorder
      className={"max-w-96 bg-slate-600 text-white"}
      key={props.key}
    >
      <Group position={"apart"} className={props.loading ? "w-full" : ""}>
        <Stack spacing={2} className={props.loading ? "w-2/5" : ""}>
          <Stack spacing={1}>
            {props.loading ? (
              <div className={"skeleton-box w-11/12 h-6 rounded"} />
            ) : (
              <Text fz={20} fw={500}>
                {props.title}
              </Text>
            )}
            {props.loading ? (
              <div className={"skeleton-box w-full h-5 rounded mt-2"} />
            ) : (
              <Text fz={15} fw={400}>
                {props.description}
              </Text>
            )}
          </Stack>
          <Group>
            {props.loading ? (
              <div className={"skeleton-box w-10/12 h-10 rounded mt-5"} />
            ) : (
              <Text fz={40} fw={500}>
                {props.count ?? "--"}
              </Text>
            )}
          </Group>
        </Stack>
        <Group
          position={"center"}
          grow={props.loading}
          className={props.loading ? "w-2/5" : ""}
        >
          {props.loading ? (
            <div className={"skeleton-box w-full h-24 rounded my-auto"} />
          ) : (
            props.icon
          )}
        </Group>
      </Group>
    </Card>
  );
}
