import { Text } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Request } from "../../../models/request.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { RequestStatus } from "../../../shared/utility/enum/request.enum";
import { useLazyGetRequestsQuery } from "../store/request.query";

export default function RequestPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["parent", "school", "driver"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getRequests, requests] = useLazyGetRequestsQuery();

  useEffect(() => {
    getRequests(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/request/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Request[] | undefined = requests?.data?.data;
  const config: EntityConfig<Request> = {
    primaryColumn: {
      key: ["parent", "name"],
      name: "Parent Name",
    },
    detailUrl: "detail",
    rootUrl: "/request",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "driverName",
        name: "Parent Name",
        hideSort: true,
        render: (request) => {
          return (
            <Text size="sm" className={request?.deletedAt ? "text-danger" : ""}>
              {request?.parent?.name} - {request?.parent?.phoneNumber}
            </Text>
          );
        },
      },
      {
        key: "driverName",
        name: "Driver Name",
        hideSort: true,
        render: (request) => {
          return (
            <Text size="sm">
              {request?.driver
                ? request?.driver?.name + " " + request?.driver?.phoneNumber
                : "Driver not assigned"}
            </Text>
          );
        },
      },
      {
        key: "driverName",
        name: "School Name",
        hideSort: true,
        render: (request) => {
          return (
            <Text size="sm">
              {request?.school
                ? request?.school?.name + " " + request?.school?.phoneNumber
                : "School not assigned"}
            </Text>
          );
        },
      },
      {
        key: "requestDate",
        name: "Request For",
        hideSort: true,
        isDate: true,
        render: (request) => {
          const day = dayjs(request.requestDate).format("ddd, MMM D, YYYY");
          // return dateFormat(`${day}, ${request.requestTime?.replace("+00", "+03")}`);
          return (
            day +
            " " +
            dayjs(
              request.requestDate +
                " " +
                request?.requestTime?.replace("+00", "+03")
            ).format("LT")
          );
        },
      },
      {
        key: "pickupFrom",
        name: "Pick Up From",
        hideSort: true,
        render: (request) => {
          return request?.pickupFrom?.toUpperCase();
        },
      },
      {
        key: "paymentAmount",
        name: "Payment Amount",
        hideSort: true,
        render: (request) => {
          return (
            new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(request.paymentAmount ?? 0) + "ETB"
          );
        },
      },

      {
        key: "isPaid",
        name: "Is Paid",
        hideSort: true,
        isBoolean: true,
      },
      { key: "createdAt", name: "Created At", isDate: true },
      {
        key: "status",
        name: "Status",
        hideSort: true,
        render(value) {
          return value?.status
            ? value.status.charAt(0).toUpperCase() +
                value.status.slice(1).toLowerCase()
            : "";
        },
      },
    ],
    filter: [
      [
        {
          field: "status",
          operator: "=",
          value: RequestStatus.Pending,
          name: "Pending",
        },
        {
          field: "status",
          operator: "=",
          value: RequestStatus.Started,
          name: "Started",
        },
        {
          field: "status",
          operator: "=",
          value: RequestStatus.Completed,
          name: "Completed",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Requests"}
        total={requests?.data?.count}
        itemsLoading={requests.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["parent.name"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
