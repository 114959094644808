import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Button,
  Checkbox,
  FileInput,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Tabs,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconArchive,
  IconCalculator,
  IconDeviceFloppy,
  IconEdit,
  IconPlus,
  IconTrash,
  IconUpload,
} from "@tabler/icons";
import { IconUserDollar } from "@tabler/icons-react";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Category, CategoryStatus } from "../../../../models/category.model";
import { CollectionQuery } from "../../../../models/collection.model";
import {
  Gender,
  Kid,
  KidStatus,
  KidTravelStatus,
  TransportationTime,
} from "../../../../models/kids.model";
import { School } from "../../../../models/school.model";
import Confirmation from "../../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../../shared/component/empty-icon/empty-icon";
import { notification } from "../../../../shared/component/notification/utility/notification";
import { Pagination } from "../../../../shared/component/pagination/pagination";
import { Constants } from "../../../../shared/constants/Constants";
import { downloadUrlParser } from "../../../../shared/utility/Tools/tools";
import { useLazyGetCategoriesQuery } from "../../../category/store/category.query";
import { useLazyGetSchoolsQuery } from "../../../school/store/school.query";
import {
  useArchiveKidMutation,
  useCreateKidMutation,
  useDeleteKidMutation,
  useEstimateKidFeeMutation,
  useLazyGetParentKidsQuery,
  useLazyGetParentQuery,
  usePayManuallyMutation,
  useUpdateKidMutation,
  useUpdateKidProfileMutation,
} from "../../store/parent.query";

import { IconClipboardCopy } from "@tabler/icons-react";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
export default function KidComponent() {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: [
      "school",
      "groupAssignment",
      "groupAssignment.group",
      "groupAssignment.driver",
      "category",
    ],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [editMode, setEditMode] = useState<"new" | "detail">("new");

  const [categoryCollection, setCategoryCollection] = useState<CollectionQuery>(
    {
      top: 1000,
      skip: 0,
      filter: [
        [
          {
            value: CategoryStatus.Both,
            operator: "=",
            field: "isForKabbaKids",
          },
          {
            value: CategoryStatus.ForKabbaKids,
            operator: "=",
            field: "isForKabbaKids",
          },
        ],
      ],
    }
  );

  const [opened, setOpened] = useState(false);
  const [gender, setGender] = useState<Gender.Male | Gender.Female>(
    Gender.Male
  );
  const [viewMode, setViewMode] = useState<"new" | "edit">("new");
  const [getParentKids, kids] = useLazyGetParentKidsQuery();
  const [getParent, parent] = useLazyGetParentQuery();
  const [createKid, createKidControl] = useCreateKidMutation();
  const [updateKid, updateKidControl] = useUpdateKidMutation();
  const [getSchools, schools] = useLazyGetSchoolsQuery();
  const [updateKidProfile, updateKidProfileResponse] =
    useUpdateKidProfileMutation();

  const [deleteKid, deleteKidResponse] = useDeleteKidMutation();

  const [archiveKid, archiveKidControl] = useArchiveKidMutation();

  const [getCategories, categories] = useLazyGetCategoriesQuery();

  const [payManually, payManuallyResponse] = usePayManuallyMutation();
  const [calculateFee, calculateFeeResponse] = useEstimateKidFeeMutation();
  const [selection, setSelection] = useState("");

  const defaultValue: Kid = {
    id: "",
    name: "",
    grade: "",
    age: 0,
    gender: Gender.Male,
    status: KidStatus.Empty,
    remark: "",
    transportationTime: TransportationTime.Both,
    kidTravelStatus: KidTravelStatus.WithOthers,
    // discountType: "percentage",
    // discountAmount: 0
  };

  const nameRegEx =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'\-]*)$/gi;
  const date = new Date();

  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 5 characters")
      .max(100, "Name should not be more than 100 characters")
      .matches(nameRegEx, "Remove invalid characters")
      .required("Name is required"),
    grade: Yup.string()
      .min(1, "Grade should at least have 1 character")
      .max(4, "Grade should not be more than 4 characters")
      .required("Grade is required"),
    age: Yup.number()
      .typeError("Age should be a number")
      .min(2, "Age can not be less than 2")
      .max(18, "Age can not be greater than 18")
      .required("Age is required"),
    gender: Yup.string()
      .oneOf([Gender.Male, Gender.Female])
      .required("Gender is required"),
    discountType: Yup.string()
      .nullable()
      .typeError("Discount type should be either Percentage or flat"),
    discountAmount: Yup.number()
      .nullable()
      .typeError("Discount Amount should be a number"),
    schoolId: Yup.string().required("School is required"),
    categoryId: Yup.string().required("Category is required"),
    startDate: Yup.string()
      .typeError("Start date should be a string")
      .required("Start Date is required"),
    transportationTime: Yup.string()
      .oneOf([
        TransportationTime.Afternoon,
        TransportationTime.Both,
        TransportationTime.Morning,
      ])
      .required("Start Date is required"),
    kidTravelStatus: Yup.string()
      .oneOf([
        KidTravelStatus.Alone,
        KidTravelStatus.WithOthers,
        KidTravelStatus.WithSiblings,
      ])
      .required("Start Date is required"),
  });

  let profileSchema = Yup.object().shape({
    profileData: Yup.mixed().test(
      "fileSize",
      "Profile image file should be less than 2MB",
      (value) => {
        if (!value || value.length === 0) return true; // attachment is optional
        changeProfile();
        return value.size <= 2000000;
      }
    ),
  });

  const {
    trigger,
    getFieldState,
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const profileForm = useForm<{ profileData: any }>({
    resolver: yupResolver(profileSchema),
    mode: "all",
  });

  useEffect(() => {
    if (params.id) {
      getParent(params.id)
        .unwrap()
        .then((parent) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }, [params.id]);

  const onSubmit = async (data: any) => {
    data.parentId = params.id;
    const school: School | undefined = schools?.data?.data?.filter(
      (school: School) => school.id === data.schoolId
    )[0];
    const category: Category | undefined = categories?.data?.data?.filter(
      (category: Category) => category.id === data.categoryId
    )[0];
    data.schoolName = school?.name;
    data.categoryName = category?.name;

    // data.startDate = moment(data.startDate).format(
    //   "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    // );

    data.startDate = dayjs(data.startDate);

    if (viewMode == "new") {
      createKid(data)
        .unwrap()
        .then((response) => {
          reset(defaultValue);
          setOpened(false);
        })
        .catch((err) => {});
    } else {
      updateKid(data)
        .unwrap()
        .then((response) => {
          let data = {
            originalname: response?.profileImage?.originalname,
            path: response?.profileImage?.path,
            filename: response?.profileImage?.filename,
            mimetype: response?.profileImage?.mimetype,
          };
          setPreviewProfile(downloadUrlParser(data));

          reset();
          setOpened(false);
        })
        .catch((err) => {});
    }
  };

  const [previewProfile, setPreviewProfile] = useState<any>(null);
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectedKid, setSelectedKid] = useState<Kid | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [searchSchool, setSearchSchool] = useState<string>("");
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const [estimation, setEstimation] = useState<number | undefined | null>(null);
  const [schoolCollection, setSchoolCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 100,
  });

  useEffect(() => {
    setCollection({ ...collection, withArchived: showArchived });
  }, [showArchived]);
  async function handleEstimate() {
    await trigger();

    if (
      !getFieldState("name").invalid &&
      !getFieldState("grade").invalid &&
      !getFieldState("age").invalid &&
      !getFieldState("gender").invalid &&
      !getFieldState("schoolId").invalid &&
      !getFieldState("categoryId").invalid &&
      !getFieldState("startDate").invalid &&
      !getFieldState("transportationTime").invalid &&
      !getFieldState("kidTravelStatus").invalid
    ) {
      calculateFee({ ...getValues(), parentId: params.id })
        .unwrap()
        .then((res) => {
          setEstimation(res);
        });
    } else {
      notification("error", "Please enter all required fields!");
    }
  }

  function parseSchools() {
    const data: { value: string; label: string }[] = [];
    if (schools.data) {
      schools?.data?.data.map((school: School) => {
        let label = school.name;

        data.push({
          value: school.id ?? "",
          label: label,
        });
      });
    }

    if (selectedKid && !selectedKid?.school?.enabled) {
      data.push({
        value: selectedKid?.schoolId ?? "",
        label: selectedKid?.schoolName ?? "",
      });
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  useEffect(() => {
    if (params.id && typeof params.id === "string") {
      getParentKids({ id: params.id, collection: collection });
    }
  }, [collection, params]);

  useEffect(() => {
    getSchools({
      ...schoolCollection,
      filter: [[{ field: "enabled", value: true, operator: "=" }]],
    });
  }, [schoolCollection]);

  useEffect(() => {
    getSchools({
      ...schoolCollection,
      searchFrom: ["name"],
      search: searchSchool,
    });
  }, [searchSchool]);

  useEffect(() => {
    getCategories(categoryCollection);
  }, [getCategories]);

  const onError = (data: any) => {};

  const openUpdateModal = (kid: Kid) => {
    setSelectedKid(kid);
    setEditMode("detail");
    setValue("name", kid?.name);
    setValue("gender", kid?.gender);
    setValue("age", kid?.age);
    setValue("grade", kid?.grade);
    setValue("schoolId", kid?.schoolId);
    setValue("remark", kid?.remark);
    setValue("status", kid?.status);
    setValue("id", kid?.id);
    setValue("distanceFromSchool", kid?.distanceFromSchool);

    setValue("categoryId", kid?.categoryId);
    setValue("kidTravelStatus", kid?.kidTravelStatus);
    setValue("transportationTime", kid?.transportationTime);
    setValue("startDate", dayjs(kid?.startDate));
    setValue("discountAmount", kid?.discountAmount ?? 0);
    setValue("discountType", kid?.discountType);

    setViewMode("edit");
    setOpened(true);

    if (kid?.profileImage) {
      let data = {
        originalname: kid?.profileImage.originalname,
        path: kid?.profileImage.path,
        filename: kid?.profileImage.filename,
        mimetype: kid?.profileImage.mimetype,
      };
      setPreviewProfile(downloadUrlParser(data));
    }
  };

  function parseCategories() {
    const data: { value: string; label: string }[] = [];
    if (categories.data) {
      categories?.data?.data.map((category: Category) => {
        let label = category.name;

        data.push({
          value: category.id ?? "",
          label: label,
        });
      });
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  function handlePayment() {
    payManually({
      amount: parent?.data?.totalPayment ?? 0,
      parentId: params.id ?? "",
    })
      .unwrap()
      .then((response) => {});
  }

  function changeProfile() {
    try {
      const objectUrl = URL.createObjectURL(
        profileForm.getValues("profileData")
      );
      setPreviewProfile(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } catch (e) {
      return null;
    }
  }

  function SubmitProfile() {
    if (profileForm.getValues("profileData") && selectedKid?.id) {
      const formData = new FormData();
      formData.append("profileImage", profileForm.getValues("profileData"));
      formData.append("id", selectedKid?.id);
      updateKidProfile({ id: selectedKid?.id, data: formData })
        .unwrap()
        .then((response) => {
          profileForm.reset();
          setSelectedKid(null);
          setPreviewProfile(null);
          setOpened(false);
        })
        .catch((error) => {
          notification(
            "error",
            error?.message
              ? error?.message
              : "Unable to save profile image. Please try again"
          );
        });
    } else {
      profileForm.setError("profileData", {
        message: "Unable to save profile image. Please try again",
      });
    }
  }

  return (
    <div className={"min-w-max"}>
      <Stack align={"flex-start"} className={"mt-3 mb-5"}>
        <Group position={"apart"} className={"w-full"}>
          <div className={"ml-3"}>Kids List</div>
          <Group>
            <Checkbox
              checked={showArchived}
              onChange={(e) => setShowArchived(e.currentTarget.checked)}
              label="Show Archived"
            />
            {/* {parent?.data?.status !== ParentStatus.New} */}
            <Confirmation
              type={"notify"}
              message={"Are you sure you want to perform manual payment?"}
              onYes={() => handlePayment()}
              header={`Manual Payment`}
            >
              <Button
                variant="filled"
                className="bg-primary text-white"
                classNames={{ label: "flex space-x-1" }}
                type="button"
                size={"xs"}
                loading={payManuallyResponse.isLoading}
              >
                <IconUserDollar size={20} strokeWidth={1.5} /> Pay
              </Button>
            </Confirmation>

            <div
              onClick={() => {
                setOpened(true);
                setViewMode("new");
                setEditMode("new");
                reset();
              }}
              className="px-2 py-1 bg-primary rounded text-white cursor-pointer relative items-center flex"
            >
              <IconPlus size={20} />
              <div className={"pl-2"}>Add</div>
            </div>
          </Group>
        </Group>
      </Stack>

      <Modal
        size={"lg"}
        opened={opened}
        onClose={() => {
          setPreviewProfile(null);
          setOpened(false);
          setSelectedKid(null);
          profileForm.reset();
          setEstimation(null);
          reset();
        }}
        title={viewMode === "edit" ? "Edit kid" : "Add kid"}
      >
        <Tabs color={"teal"} defaultValue={"main"}>
          <Tabs.List>
            <Tabs.Tab value={"main"}>Main</Tabs.Tab>
            {viewMode == "edit" && (
              <Tabs.Tab value={"profile"}>Profile Picture</Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="main">
            <form onSubmit={handleSubmit(onSubmit, onError)} className={"mt-2"}>
              <div className="w-full">
                <div className="flex w-full space-x-4  justify-center">
                  <TextInput
                    classNames={{ label: "dark:text-white" }}
                    className="w-full "
                    error={errors.name && `${errors?.name?.message}`}
                    withAsterisk
                    label="Name"
                    placeholder="Enter name"
                    {...register("name")}
                  />

                  <div className="flex w-full space-x-4  justify-center">
                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.age && `${errors?.age?.message}`}
                      withAsterisk
                      type={"number"}
                      label="Age"
                      placeholder="Enter age"
                      {...register("age")}
                    />
                    <Controller
                      render={({ field }) => (
                        <Select
                          radius={"xs"}
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          error={errors?.gender && `${errors?.gender?.message}`}
                          label="Gender"
                          placeholder="Select a gender"
                          data={[
                            { label: "Male", value: Gender.Male },
                            {
                              label: "Female",
                              value: Gender.Female,
                            },
                          ]}
                          withAsterisk
                          {...field}
                        />
                      )}
                      name="gender"
                      control={control}
                    />
                  </div>
                </div>

                <div className="flex w-full space-x-4  justify-center">
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        label="School"
                        searchable
                        withAsterisk
                        placeholder="Select a school"
                        onKeyUp={debounce(
                          (event: any) => setSearchSchool(event.target.value),
                          1000
                        )}
                        error={
                          errors?.schoolId && `${errors?.schoolId?.message}`
                        }
                        {...field}
                        className="w-full"
                        data={parseSchools()}
                      />
                    )}
                    name="schoolId"
                    control={control}
                  />

                  <TextInput
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={errors.grade && `${errors?.grade?.message}`}
                    withAsterisk
                    label="Grade"
                    placeholder="Enter grade"
                    {...register("grade")}
                  />
                </div>

                <div className="flex w-full space-x-4  justify-center">
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        label="Category"
                        searchable
                        withAsterisk
                        placeholder="Select a category"
                        error={
                          errors?.categoryId && `${errors?.categoryId?.message}`
                        }
                        {...field}
                        className="w-full"
                        data={parseCategories()}
                      />
                    )}
                    name="categoryId"
                    control={control}
                  />
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DatePickerInput
                        className="w-full"
                        error={
                          errors.startDate && `${errors.startDate?.message}`
                        }
                        //@ts-ignore
                        placeholder="Pick start date"
                        label="Start Date"
                        excludeDate={(sDate) => {
                          if (sDate.getFullYear() < date.getFullYear()) {
                            return true;
                          } else if (
                            sDate.getFullYear() === date.getFullYear() &&
                            sDate.getMonth() < date.getMonth()
                          ) {
                            return true;
                          } else {
                            if (
                              sDate.getFullYear() <= date.getFullYear() &&
                              sDate.getMonth() === date.getMonth() &&
                              sDate.getDate() <= date.getDate() - 1
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        }}
                        withAsterisk
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="flex w-full space-x-4  justify-center">
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        label="Transportation Time"
                        searchable
                        withAsterisk
                        placeholder="Select Transportation Time"
                        error={
                          errors?.transportationTime &&
                          `${errors?.transportationTime?.message}`
                        }
                        {...field}
                        className="w-full"
                        data={[
                          {
                            value: TransportationTime.Afternoon,
                            label: "Afternoon",
                          },
                          {
                            value: TransportationTime.Morning,
                            label: "Morning",
                          },
                          { value: TransportationTime.Both, label: "Both" },
                        ]}
                      />
                    )}
                    name="transportationTime"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        label="Travel Status"
                        searchable
                        withAsterisk
                        placeholder="Select Travel Status"
                        error={
                          errors?.kidTravelStatus &&
                          `${errors?.kidTravelStatus?.message}`
                        }
                        {...field}
                        className="w-full"
                        data={[
                          { value: KidTravelStatus.Alone, label: "Alone" },
                          {
                            value: KidTravelStatus.WithOthers,
                            label: "With Others",
                          },
                          {
                            value: KidTravelStatus.WithSiblings,
                            label: "With Siblings",
                          },
                        ]}
                      />
                    )}
                    name="kidTravelStatus"
                    control={control}
                  />
                </div>
                <div className="flex w-full space-x-4  justify-center">
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        label="Discount Type"
                        searchable
                        placeholder="Select Discount Type"
                        error={
                          errors?.discountType &&
                          `${errors?.discountType?.message}`
                        }
                        {...field}
                        className="w-full"
                        data={[
                          { value: "percentage", label: "Percentage" },
                          {
                            value: "flat",
                            label: "Flat",
                          },
                        ]}
                      />
                    )}
                    name="discountType"
                    control={control}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={
                      errors.discountAmount &&
                      `${errors?.discountAmount?.message}`
                    }
                    type="number"
                    min={0}
                    defaultValue={0}
                    label="Discount Amount"
                    placeholder="Discount Amount"
                    {...register("discountAmount")}
                  />
                </div>

                {/* <div className="flex w-full space-x-4  justify-center">
                                    <Controller
                                        render={({field}) => (
                                            <Select
                                                radius={"xs"}
                                                label="Status"
                                                searchable
                                                withAsterisk
                                                placeholder="Select a status"
                                                error={errors?.status && `${errors?.status?.message}`}
                                                {...field}
                                                className="w-full"
                                                data={[{label: "At Home", value: KidStatus.AtHome},
                                                    {label: "In School", value: KidStatus.InSchool},
                                                    {label: "On the way", value: KidStatus.OnTheWay}
                                                ]}
                                            />
                                        )}
                                        name="status"
                                        control={control}
                                    />
                                </div> */}

                <div className="flex w-full space-x-4  justify-center">
                  <Textarea
                    placeholder="Remarks..."
                    label="Remark"
                    className="w-full"
                    error={errors?.remark && `${errors?.remark?.message}`}
                    {...register("remark")}
                  />
                </div>
              </div>
              <Group position="apart" mt="xl">
                <Group position="apart">
                  <Button
                    classNames={{ label: "flex space-x-1 " }}
                    variant="default"
                    className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                    type="button"
                    loading={calculateFeeResponse.isLoading}
                    onClick={handleEstimate}
                    size="xs"
                  >
                    <IconCalculator />
                    <span>Estimate</span>
                  </Button>

                  {estimation ? (
                    <p>
                      {" "}
                      {Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                      }).format(estimation) + " ETB"}
                    </p>
                  ) : (
                    <p>
                      {" "}
                      {Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                      }).format(0) + " ETB"}
                    </p>
                  )}
                </Group>
                <div className="flex space-x-2">
                  <Button
                    classNames={{ label: "flex space-x-1" }}
                    variant="default"
                    className="ml-2 dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                    type="reset"
                    size="xs"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="fill-current h-6"
                        viewBox="0 0 32 32"
                      >
                        <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                      </svg>
                    </span>
                    <span>Reset</span>
                  </Button>
                  <Button
                    variant="filled"
                    className="bg-primary text-white"
                    type="submit"
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                    loading={
                      createKidControl.isLoading ||
                      updateKidProfileResponse?.isLoading ||
                      updateKidControl?.isLoading
                    }
                  >
                    <span>
                      <IconDeviceFloppy size={22} strokeWidth={1.5} />
                    </span>
                    <span>Submit</span>
                  </Button>
                </div>
              </Group>
            </form>
          </Tabs.Panel>

          {viewMode == "edit" && (
            <Tabs.Panel value="profile" pt="xs">
              <div className="flex  justify-center h-full">
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="w-full gap-y-4 dark:text-white"
                >
                  <div className="flex-col space-y-4">
                    <div className="flex w-full justify-center mt-2">
                      <Avatar
                        size="xl"
                        color="blue"
                        radius="xl"
                        src={previewProfile}
                        alt={selectedKid?.name}
                      >
                        {selectedKid?.name.charAt(0) ?? "US"}
                      </Avatar>
                    </div>

                    <div className="flex w-full justify-between mt-2">
                      <div className="px-4 w-full flex items-center justify-start">
                        <Controller
                          control={profileForm.control}
                          name="profileData"
                          render={({ field }) => (
                            <FileInput
                              clearable
                              className="w-full"
                              error={
                                profileForm.formState.errors.profileData &&
                                `${profileForm.formState.errors.profileData?.message}`
                              }
                              label="Upload Profile"
                              accept="image/png,image/jpeg,image/jpg"
                              icon={<IconUpload size={14} />}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="w-full flex justify-end mt-2 items-center">
                      <Group className={"px-4"} position="right" mt="xl">
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          onClick={() => {
                            setPreviewProfile(selectedKid?.profileImage?.path);
                            profileForm.reset();
                            // Reset file input text - form reset couldn't reset it!
                            const file: HTMLInputElement | null =
                              document.querySelector(".file");
                            if (file) file.value = "";
                          }}
                          size="xs"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>

                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={updateKidProfileResponse.isLoading}
                          onClick={() => {
                            if (profileForm.getValues("profileData")) {
                              SubmitProfile();
                            } else {
                              profileForm.setError("profileData", {
                                message: "Please select an image",
                              });
                            }
                          }}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </Group>
                    </div>
                  </div>
                </form>
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      </Modal>

      <div className="relative overflow-x-auto ">
        <LoadingOverlay visible={kids.isFetching} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Group
              </th>
              <th scope="col" className="py-3 px-6">
                Name
              </th>
              <th scope="col" className="py-3 px-6">
                Age
              </th>
              <th scope="col" className="py-3 px-6">
                Grade
              </th>
              <th scope="col" className="py-3 px-6">
                Driver
              </th>
              <th scope="col" className="py-3 px-6">
                Transportation Time
              </th>
              <th scope="col" className="py-3 px-6">
                Travel With
              </th>
              <th scope="col" className="py-3 px-6">
                Category
              </th>
              <th scope="col" className="py-3 px-6">
                Registered At
              </th>
              <th scope="col" className="py-3 px-6">
                Start Date
              </th>
              <th scope="col" className="py-3 px-6">
                School
              </th>

              <th scope="col" className="py-3 px-6">
                Distance from school
              </th>

              <th scope="col" className="py-3 px-6">
                Discount Amount
              </th>

              <th scope="col" className="py-3 px-6">
                First Month Price
              </th>
              <th scope="col" className="py-3 px-6">
                Ongoing Monthly Fee
              </th>
              <th scope="col" className="py-3 px-6">
                Status
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {kids.isSuccess &&
              kids.data.data.length > 0 &&
              kids.data?.data.map((kid: Kid, idx: number) => (
                <tr
                  key={idx}
                  className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {kid?.groupAssignment?.groupId
                      ? kid?.groupAssignment?.group?.name
                      : "Group not assigned"}
                  </th>
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <span className={kid?.deletedAt ? "text-danger" : ""}>
                      {kid.name}
                    </span>
                  </th>
                  <td className="py-4 px-6 text-xs">{kid.age}</td>
                  <td className="py-4 px-6 text-xs">{kid.grade}</td>
                  <td className="py-4 px-6 text-xs">
                    {kid?.groupAssignment?.driver
                      ? `${kid?.groupAssignment?.driver?.name} - ${kid?.groupAssignment?.driver?.phoneNumber}`
                      : "Driver Not Assigned"}
                  </td>
                  <td className="py-4 px-6 text-xs">
                    {kid.transportationTime}
                  </td>
                  <td className="py-4 px-6 text-xs">{kid.kidTravelStatus}</td>
                  <td className="py-4 px-6 text-xs">{kid?.categoryName}</td>
                  <td className="py-4 px-6 text-center">
                    {dateFormat(kid?.createdAt, "mmmm dS, yyyy hh:mm TT")}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {dateFormat(kid?.startDate, "mmmm dS, yyyy")}
                  </td>
                  <td className="py-4 px-6 text-xs">{kid?.schoolName}</td>
                  <td className="py-4 px-6 text-center">
                    {kid?.distanceFromSchool && kid.distanceFromSchool > 1
                      ? kid.distanceFromSchool
                      : 0}{" "}
                    km
                  </td>

                  <td className="py-4 px-6 text-xs">
                    {kid?.discountAmount ?? 0}
                    {kid?.discountType === "flat"
                      ? " ETB"
                      : kid?.discountType === "percentage"
                      ? " %"
                      : ""}
                  </td>

                  <td className="py-4 px-6 text-xs">
                    {kid?.kidFee &&
                      Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                      }).format(kid?.kidFee) + " ETB"}
                  </td>
                  <td className="py-4 px-6 text-xs">
                    {kid?.estimatedMonthlyFee &&
                      Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                      }).format(kid?.estimatedMonthlyFee) + " ETB"}
                  </td>

                  <td className="py-4 px-6 text-center">
                    {kid?.groupAssignment?.status ?? "Unassigned"}
                  </td>
                  <td className="py-4 px-2">
                    <div className="flex space-x-2">
                      <CopyToClipboard
                        text={`https://www.google.com/maps/dir/?api=1&origin=${parent?.data?.lat},${parent?.data?.lng}&destination=${kid?.school?.lat},${kid?.school?.lng}`}
                        onCopy={() =>
                          notification(
                            "success",
                            "Map link copied to clipboard"
                          )
                        }
                      >
                        <Tooltip label={"Copy map location"}>
                          <Button
                            variant="filled"
                            loading={archiveKidControl.isLoading}
                            className="bg-primary text-white mb-2"
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <IconClipboardCopy />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>

                      <Button
                        variant="filled"
                        loading={archiveKidControl.isLoading}
                        className="bg-primary text-white mb-2"
                        type="button"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        onClick={() => openUpdateModal(kid)}
                      >
                        <IconEdit />
                      </Button>

                      {kid?.deletedAt ? (
                        <Confirmation
                          type={"notify"}
                          message={"Are you sure you want to delete this kid?"}
                          header={"Delete Confirmation"}
                          yesText={"Delete"}
                          onYes={(data: string) => {
                            deleteKid({
                              id: `${kid.id}`,
                              parentId: `${params.id}`,
                            }).then(() => {});
                          }}
                        >
                          <Button
                            variant="filled"
                            loading={archiveKidControl.isLoading}
                            className="bg-danger text-white"
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <span>
                              <IconTrash size={22} strokeWidth={1.5} />
                            </span>
                            {/*<span>Archive</span>*/}
                            {/*<Tooltip label={"Archive this kid"}>*/}
                            {/*    Archive this kid*/}
                            {/*</Tooltip>*/}
                          </Button>
                        </Confirmation>
                      ) : (
                        <Confirmation
                          type={"notify"}
                          message={"Are you sure you want to archive this kid?"}
                          header={"Archive Confirmation"}
                          result={"single"}
                          resultRequired={true}
                          selectorLabel={"Please select a reason"}
                          labelDescription={"Why are you archiving this kid?"}
                          options={Constants.ArchiveReason.map((reason) => {
                            return { label: reason, value: reason };
                          })}
                          customInput={true}
                          yesText={"Archive"}
                          onYes={(data: string) => {
                            archiveKid({
                              id: `${kid.id}`,
                              parentId: `${params.id}`,
                              reason: data,
                            }).then(() => {});
                          }}
                        >
                          <Button
                            variant="filled"
                            loading={archiveKidControl.isLoading}
                            className="bg-danger text-white"
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <span>
                              <IconArchive size={22} strokeWidth={1.5} />
                            </span>
                            {/*<span>Archive</span>*/}
                            {/*<Tooltip label={"Archive this kid"}>*/}
                            {/*    Archive this kid*/}
                            {/*</Tooltip>*/}
                          </Button>
                        </Confirmation>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {kids.isLoading || !kids?.data?.data.length ? (
          <div className="w-full relative flex justify-center items-center h-56">
            <LoadingOverlay visible={kids.isLoading} overlayBlur={2} />
            <EmptyIcon />
          </div>
        ) : (
          <></>
        )}
        {kids?.isSuccess && kids?.data?.data?.length > 0 && (
          <div className="w-full flex justify-end items-center mt-2 mb-2">
            <Pagination
              showTitle={false}
              total={kids?.data ? kids?.data?.count : 0}
              defaultPageSize={10}
              pageSize={[5, 10, 15, 20]}
              onPaginationChange={(skip: number, top: number) => {
                setCollection({ ...collection, skip: skip, top: top });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
