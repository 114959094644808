import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";

import ArchivedRouteComponent from "../component/archived-route-component";
import NewRouteComponent from "../component/new-route-component";
import RoutePage from "../page/route-page";
export const RoutesRoute: RouteObject = {
  path: "/settings-and-configurables/route",
  element: (
    <AuthGuard role={["super_admin", "admin", "finance", "operator"]}>
      <RoutePage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewRouteComponent editMode="detail" />,
    },
    { path: ":id", element: <NewRouteComponent editMode="new" /> },
    { path: "archived", element: <ArchivedRouteComponent /> },
  ],
};

