/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Corporate } from "../../../models/corporate.model";
import { Passenger } from "../../../models/passenger.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { PASSENGER_ENDPOINT } from "../passenger.endpoint";

let passengerCollection: CollectionQuery;
let archivedPassengerCollection: CollectionQuery;
const passengerQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getPassenger: builder.query<Passenger, string>({
      query: (id: string) => ({
        url: `${PASSENGER_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedPassenger: builder.query<Passenger, string>({
      query: (id: string) => ({
        url: `${PASSENGER_ENDPOINT.archivedPassenger}/${id}`,
        method: "get",
      }),
    }),

    getArchivedPassengers: builder.query<
      Collection<Passenger>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${PASSENGER_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedPassengerCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    getPassengers: builder.query<Collection<Passenger>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PASSENGER_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            passengerCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    createPassenger: builder.mutation<Passenger, Passenger>({
      query: (newData: any) => ({
        url: `${PASSENGER_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassengers",
                passengerCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updatePassenger: builder.mutation<Passenger, Passenger>({
      query: (newData: any) => ({
        url: `${PASSENGER_ENDPOINT.update}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassengers",
                passengerCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((passenger) => {
                      if (passenger.id === data.id) return data;
                      else {
                        return passenger;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activatePassenger: builder.mutation<Passenger, string>({
      query: (id: string) => ({
        url: `${PASSENGER_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission: "activate-or-block-passenger",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassengers",
                passengerCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) {
                        const c: Corporate = {
                          id: "",
                          name: data.corporateName ?? "",
                          email: "",
                          website: "",
                          phoneNumber: "",
                          enabled: true,
                          logo: {
                            filename: "",
                            path: "",
                            originalname: "",
                            mimetype: "",
                            size: 0,
                          },
                          tinNumber: "",
                          contactPerson: {
                            name: "",
                            email: "",
                            phoneNumber: "",
                            position: "",
                          },
                          address: {
                            country: "",
                            city: "",
                            subCity: "",
                            woreda: "",
                            houseNumber: "",
                          },
                          landmark: "",
                          lat: 0,
                          lng: 0,
                        };

                        return { ...data, corporate: c };
                      } else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassenger",
                param,
                (draft) => {
                  draft.enabled = data.enabled;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archivePassenger: builder.mutation<
      Passenger,
      { id: string; reason: string }
    >({
      query: (data) => ({
        url: `${PASSENGER_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-passengers",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassengers",
                passengerCollection,
                (draft) => {
                  draft.data = draft?.data?.map((passenger) => {
                    if (passenger.id === arg.id) {
                      return data;
                    } else {
                      return passenger;
                    }
                  });
                }
              )
            );
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassenger",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              passengerQuery.util.updateQueryData(
                "getArchivedPassenger",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deletePassenger: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${PASSENGER_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-passengers",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              passengerQuery.util.updateQueryData(
                "getArchivedPassengers",
                archivedPassengerCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (passenger) => passenger.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restorePassenger: builder.mutation<Passenger, string>({
      query: (id: string) => ({
        url: `${PASSENGER_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-passengers",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassengers",
                passengerCollection,
                (draft) => {
                  draft.data = draft?.data?.map((passenger) => {
                    if (passenger.id === id) {
                      return data;
                    } else {
                      return passenger;
                    }
                  });
                }
              )
            );
            dispatch(
              passengerQuery.util.updateQueryData(
                "getPassenger",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              passengerQuery.util.updateQueryData(
                "getArchivedPassenger",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetPassengerQuery,
  useLazyGetArchivedPassengerQuery,
  useLazyGetPassengersQuery,
  useLazyGetArchivedPassengersQuery,
  useCreatePassengerMutation,
  useUpdatePassengerMutation,
  useArchivePassengerMutation,
  useActivatePassengerMutation,
  useRestorePassengerMutation,
  useDeletePassengerMutation,
} = passengerQuery;
