const ArchiveReason: string[] = [
  "No Longer Needed",
  "Completed or Finalized",
  "Duplicate Entry",
  "Historical Reference",
  "Legal or Compliance Requirement",
  "Data Quality Issue",
  "Business Rule Change",
  "User Requested",
  "Inactive or Dormant",
  "It is incorrect and can't update it",
];
export const Constants = {
  ArchiveReason: [...ArchiveReason],
  OwnerArchiveReason: [...ArchiveReason, "License has expired"],
  VehicleArchiveReason: [
    ...ArchiveReason,
    "Bolo has expired",
    "Insurance has expired",
  ],
  ProviderArchiveReason: [...ArchiveReason, "Provider license has expired"],
  UserArchiveReason: [
    ...ArchiveReason,
    "User has been terminated",
    "User has resigned",
  ],

  Levels: (key: string | undefined) => {
    switch (key) {
      case "easy":
        return "Easy";
      case "moderate":
        return "Moderate";
      case "hard":
        return "Hard";
      case "extreme":
        return "Extreme";
      default:
        return "-----";
    }
  },
};
export const monthsList = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
export const decodeMonth = (month: string) => {
  switch (month) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    default:
      return "December";
  }
};

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const events = [
  "Meskel",
  "Festival",
  "Irecha",
  "Easter",
  "Timket",
  "Sport",
  "Culture",
];
export const trafficConditions = ["Easy", "Moderate", "Difficult", "Extreme"];
export const morningTimes = [
  { value: "12:00 morning", label: "12:00 Morning" },
  { value: "12:15 morning", label: "12:15 Morning" },
  { value: "12:30 morning", label: "12:30 Morning" },
  { value: "12:45 morning", label: "12:45 Morning" },
  { value: "1:00 morning", label: "1:00 Morning" },
  { value: "1:30 morning", label: "1:30 Morning" },
  { value: "2:00 morning", label: "2:00 Morning" },
];

export const afternoonTimes = [
  { value: "10:00 afternoon", label: "10:00 Afternoon" },
  { value: "10:30 afternoon", label: "10:30 Afternoon" },
  { value: "11:00 afternoon", label: "11:00 Afternoon" },
  { value: "11:30 afternoon", label: "11:30 Afternoon" },
  { value: "12:00 afternoon", label: "12:00 Afternoon" },
  { value: "12:30 afternoon", label: "12:30 Afternoon" },
  { value: "1:00 evening", label: "1:00 Evening" },
];
