import { Text, Tooltip } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Owner } from "../../../models/owner.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetOwnersQuery } from "../store/owner.query";

export default function OwnerPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getOwners, owners] = useLazyGetOwnersQuery();

  useEffect(() => {
    getOwners(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/owner/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  const showExpiresIcon = (owner: Owner) => {
    let expiredDoc = owner?.licenseDueDate
      ? dayjs(owner.licenseDueDate).isBefore(dayjs())
      : false;

    if (!expiredDoc) return null;

    return (
      <Tooltip label="Has expired or soon to be expired license." withArrow>
        <span>
          <IconAlertTriangle
            color="red"
            style={{ cursor: "pointer" }}
            tabIndex={0}
          />
        </span>
      </Tooltip>
    );
  };

  const data: Owner[] | undefined = owners.data?.data;
  const config: EntityConfig<Owner> = {
    primaryColumn: {
      key: "name",
      name: "Name",
      render: (owner) => {
        return (
          <Text size="sm" className={owner?.deletedAt ? "text-danger" : ""}>
            {owner.name}
          </Text>
        );
      },
    },
    detailUrl:
      location.pathname === "/owner/vehicles" ||
      location.pathname === `/owner/vehicles/${params.id}`
        ? "vehicles"
        : "detail",
    rootUrl: "/registration/owner",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (owner) => {
          return (
            <div
              className={`flex space-x-2 text-sm ${
                owner?.deletedAt ? "text-danger" : ""
              }`}
            >
              <Text>{owner.name}</Text>
              <div> {showExpiresIcon(owner)}</div>
            </div>
          );
        },
      },
      { key: "email", name: "Email", hideSort: true },
      { key: "phoneNumber", name: "Phone", hideSort: true },
      { key: "isCompany", name: "Company", hideSort: true },
      { key: "tinNumber", name: "TIN Number", hideSort: true },
      { key: "createdAt", name: "Registered at", isDate: true },
      { key: "enabled", name: "Status", hideSort: true },
    ],
    filter: [
      [
        { field: "isCompany", value: "true", operator: "=", name: "Company" },
        {
          field: "isCompany",
          value: "false",
          operator: "=",
          name: "Individuals",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={
          location.pathname === "/owner/vehicles" ||
          location.pathname === `/owner/vehicles/${params.id}`
            ? "detail"
            : viewMode
        }
        title={"Owners"}
        total={owners?.data?.count}
        itemsLoading={owners.isLoading || owners?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: [
              "name",
              "email",
              "phoneNumber",
              "tinNumber",
              "website",
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
