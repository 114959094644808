import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NewDemandForcastComponent from "../component/new-demand-forcat-component";
import DemandForcastPage from "../page/demand-forcast-page";
export const DemandForcastRoute: RouteObject = {
  path: "/demand-forcast",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <DemandForcastPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewDemandForcastComponent editMode="detail" />,
    },
    { path: ":id", element: <NewDemandForcastComponent editMode="new" /> },
  ],
};
