import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Text, TextInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../store/account.query";
const schema = yup
  .object()
  .shape({
    email: yup.string().email().required("Email is required"),
  })
  .required();
export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const defaultValue = {
    email: "",
  };
  const [forgetPassword, forgetPasswordResponse] = useForgetPasswordMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });
  const onSubmit = (data: any) => {
    const forgetData = { type: "transport", email: data.email };
    forgetPassword(forgetData).then((response: any) => {
      if (!response?.error) {
        navigate("/accounts/update-password");
      }
    });
  };
  const onError = (error: any) => {};
  return (
    <>
      {/* <div className="bg-sky-100 h-screen w-full flex justify-center items-center">
        <Center className="w-3/4 z-20 px-4 h-3/4 border  bg-white flex space-x-4">
          <div className="w-3/4 hidden md:block border-0 border-r h-full md:flex p-2 justify-center items-center">
            <img src="/forgot_password.png" alt="img" className="" />
          </div>
          <div className="h-full p-2 w-full flex justify-center items-center">
            <div className="flex-col lex  space-y-4 w-full p-4 h-3/4 justify-center align-items-center ">
              <div className="w-full flex justify-center">
                <a href="/">
                <img src="/linc.png" alt="img" className=" w-12" />
                </a>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full flex justify-center h-full flex-1"
              >
                <div className="w-3/4 flex flex-col">
                  <TextInput
                    label="Email"
                    withAsterisk
                    placeholder="example@gmail.com"
                    error={errors?.email && `${errors?.email?.message}`}
                    {...register("email")}
                  />
                  <div className="w-full flex justify-center items-center flex-1 -mt-[150px]">
                    
                      <Button
                        variant="filled"
                        className="bg-primary w-full text-white"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={forgetPasswordResponse?.isLoading}
                      >
                        <span>Reset Password</span>
                      </Button>
                      
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Center>
      </div> */}
      <div className="flex justify-center items-center bg-sky-100 h-screen">
        <div className="flex h-[75vh] bg-white">
          <div className="w-3/4 hidden marker:border-0 border-r h-full md:flex p-2 justify-center items-center">
            <img src="/forgot_password.png" alt="img" className="" />
          </div>
          <div className="w-full">
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full flex justify-center h-full flex-1"
            >
              <div className="flex flex-col justify-center items-center gap-8 px-2 md:px-18">
                <a href="/">
                  <img src="/favicon-32x32.ico" alt="img" className=" w-12" />
                </a>

                <Text className="w-[40%] inline">
                  {" "}
                  Enter the email address associated with your account, and
                  we'll send you instructions to reset your password.
                </Text>
                <TextInput
                  label="Your Email"
                  className="w-[40%]"
                  withAsterisk
                  placeholder="example@gmail.com"
                  error={errors?.email && `${errors?.email?.message}`}
                  {...register("email")}
                />
                <Button
                  variant="filled"
                  className="bg-primary w-[40%] text-white"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={forgetPasswordResponse?.isLoading}
                >
                  <span>Reset Password</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
