import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import PassengerPreferencePage from "../page/passenger-preference-page";

export const PassengerPreferenceRoute: RouteObject = {
  path: "/passengers-preferences",
  element: (
    <AuthGuard role={["super_admin", "admin", "finance", "operator"]}>
      <PassengerPreferencePage />
    </AuthGuard>
  ),
  // children: [
  //   {
  //     path: "detail/:type/:id",
  //     element: <NewRouteComponent editMode="detail" />,
  //   },
  //   { path: ":id", element: <NewRouteComponent editMode="new" /> },
  //   { path: "archived", element: <ArchivedRouteComponent /> },
  // ],
};
