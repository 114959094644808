import { Button, Image, Modal, Tooltip } from "@mantine/core";
import { IconCircleCheck, IconLineDashed } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Booking } from "../../../models/booking.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  ChapaPaymentStatus,
  NewBookingStatus,
} from "../../../shared/utility/enum/other.enum";
import { pickupTimeType } from "../../../shared/utility/enum/pickupTypeTimes.enum";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useLazyGetBookingListsQuery,
  useUpdateInitialPaymentStatusMutation,
  useUpdatePaymentStatusMutation,
} from "../store/booking.query";

export default function BookingPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: [
      "assignment",
      "vehicle",
      "vehicle.category",
      "driver",
      "passenger",
      "route",
    ],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getBookings, bookings] = useLazyGetBookingListsQuery();
  const [updatPaymentStatus, paymentStatusResponse] =
    useUpdatePaymentStatusMutation();
  const [updatInitialPaymentStatus, initialPaymentStatusResponse] =
    useUpdateInitialPaymentStatusMutation();
  useEffect(() => {
    getBookings(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/booking/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  const [opened, setOpened] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [initialPaymentopened, setInitialPaymentOpened] = useState(false);
  const [selectedInitialPaymentImageUrl, setSelectedInitialPaymentImageUrl] =
    useState<string | null>(null);
  const handleViewImage = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
    setOpened(true);
  };

  const handleViewInitialPaymentImage = (imageUrl: string) => {
    setSelectedInitialPaymentImageUrl(imageUrl);
    setInitialPaymentOpened(true);
  };

  const data: Booking[] | undefined = bookings.data?.data;
  const config: EntityConfig<Booking> = {
    primaryColumn: {
      key: "passengerName",
      name: "Passenger Name",
      render: (book) => {
        return (
          <span
            className={book?.deletedAt ? "text-danger" : ""}
          >{`${book?.passenger?.name} - ${book?.passenger?.phoneNumber}`}</span>
        );
      },
    },
    rootUrl: "/bookings",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "passengerName",
        name: "Passenger",
        hideSort: true,
        render: (book) => {
          return (
            <span
              className={book?.deletedAt ? "text-danger" : ""}
            >{`${book?.passenger?.name} - ${book?.passenger?.phoneNumber}`}</span>
          );
        },
      },
      {
        key: "routeName",
        name: "Route",
        hideSort: true,
        render: (book) => {
          return (
            <span
              className={book?.deletedAt ? "text-danger" : ""}
            >{`${book?.route?.name}`}</span>
          );
        },
      },
      { key: "pickupName", name: "Pickup Name", hideSort: true },
      { key: "destinationName", name: "Destination Name", hideSort: true },
      {
        key: "vehiclePlateNumber",
        name: "Vehicle",
        hideSort: true,
        render: (value) =>
          `${value?.vehicle?.model} - (${value.vehiclePlateNumber})`,
      },
      {
        key: ["vehicle", "category", "name"],
        name: "category",
        hideSort: true,
      },
      {
        key: ["driver", "name"],
        name: "Driver",
        hideSort: true,
        render: (value) =>
          `${value?.driver?.name} - ${value?.driver?.phoneNumber}`,
      },
      {
        key: "shift",
        name: "Shift",
        hideSort: true,
        render: (value: Booking) =>
          value?.shift
            ? value?.shift?.charAt(0).toUpperCase() + value.shift.slice(1)
            : "",
      },
      {
        key: "morningPickupTime",
        name: "Morning Pickup Time ",
        hideSort: true,
        render: (value) => value?.morningPickupTime || <IconLineDashed />,
      },
      {
        key: "afternoonPickupTime",
        name: "Afternoon Pickup Time ",
        hideSort: true,
        render: (value) => value?.afternoonPickupTime || <IconLineDashed />,
      },
      {
        key: "weeklyEndDay",
        name: "Transportation Ends On (Weekly) ",
        hideSort: true,
      },
      { key: "createdAt", name: "Booked At", isDate: true },

      // { key: "walletType", name: "Wallet Type", hideSort: true },
      {
        key: "fee",
        name: "Fee",
        hideSort: true,
        render: (value) =>
          `${Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(
            value.fee
          )} ETB`,
      },
      {
        key: "remainingFee",
        name: "Remaining Fee",
        hideSort: true,
        render: (value) =>
          `${Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(
            value?.remainingFee ?? 0
          )} ETB`,
      },
      {
        key: "paymentImage",
        name: "Payment Receipt",
        hideSort: true,
        render: (response) => {
          const data = {
            originalname: response?.paymentImage?.originalname,
            path: response?.paymentImage?.path,
            filename: response?.paymentImage?.filename,
            mimetype: response?.paymentImage?.mimetype,
          };

          const imageLink = downloadUrlParser(data);

          return response?.paymentImage ? (
            <Button
              variant=""
              size="xs"
              onClick={() => handleViewImage(imageLink)}
            >
              View
            </Button>
          ) : (
            <IconLineDashed />
          );
        },
      },
      {
        key: "paymentStatus",
        name: "Payment Status",
        hideSort: true,
        render: (value) => {
          const paymentStatus = value?.paymentStatus || ""; // Provide a default empty string
          return paymentStatus
            ? paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1)
            : ""; // Default display text if paymentStatus is empty
        },
      },
      {
        key: "initialPaymentImage",
        name: "Initial Payment Receipt",
        hideSort: true,
        render: (response) => {
          const data = {
            originalname: response?.initialPaymentImage?.originalname,
            path: response?.initialPaymentImage?.path,
            filename: response?.initialPaymentImage?.filename,
            mimetype: response?.initialPaymentImage?.mimetype,
          };

          const initialPaymentImageLink = downloadUrlParser(data);

          return response?.initialPaymentImage ? (
            <Button
              variant=""
              size="xs"
              onClick={() => handleViewImage(initialPaymentImageLink)}
            >
              View
            </Button>
          ) : (
            <IconLineDashed />
          );
        },
      },
      {
        key: "initialPaymentStatus",
        name: "Initial Payment Status",
        hideSort: true,
      },

      { key: "isActive", name: "Is Active", hideSort: true },
      {
        key: "status",
        name: "Status",
        hideSort: true,
        render: (value) =>
          value?.status === NewBookingStatus.CREATED ? "Active" : value?.status,
      },

      {
        key: "action",
        name: "Actions",
        hideSort: true,
        render: (eachBooking) => {
          return (
            <>
              {" "}
              <div className="flex items-center space-x-1">
                <span>
                  {eachBooking?.paymentStatus !== ChapaPaymentStatus.Success &&
                    eachBooking?.paymentImage && (
                      <div className="flex space-x-1">
                        <Confirmation
                          type={"notify"}
                          header={`Change Payment status Confirmation `}
                          message={
                            "Are you sure you want to change this booking payment status?"
                          }
                          result={"single"}
                          resultRequired={true}
                          customError={"Please select a paymet status"}
                          selectorLabel={"Please select a paymet status"}
                          options={[
                            {
                              value: ChapaPaymentStatus.Success,
                              label: "Success",
                            },
                            {
                              value: ChapaPaymentStatus.Rejected,
                              label: "Rejected",
                            },
                          ]}
                          yesText={"Ok"}
                          onYes={(data: ChapaPaymentStatus) => {
                            updatPaymentStatus({
                              bookingId: `${eachBooking.id}`,
                              paymentStatus: data,
                            })
                              .unwrap()
                              .then((response) => {
                                //
                              });
                          }}
                        >
                          <Tooltip label="Change Payment Status">
                            <Button
                              size="small"
                              className={
                                "text-white text-xs flex  items-center justify-center"
                              }
                              type="button"
                              loading={
                                paymentStatusResponse.isLoading &&
                                paymentStatusResponse.originalArgs
                                  ?.bookingId === eachBooking.id
                              }
                            >
                              <IconCircleCheck size={19} strokeWidth={1.5} />
                            </Button>
                          </Tooltip>
                        </Confirmation>
                      </div>
                    )}
                </span>
                <span>
                  {eachBooking?.initialPaymentStatus !==
                    ChapaPaymentStatus.Success &&
                    eachBooking?.initialPaymentImage &&
                    eachBooking.status === NewBookingStatus.CREATED && (
                      <div className="flex space-x-1">
                        <Confirmation
                          type={"notify"}
                          header={`Change Initial Payment status Confirmation `}
                          message={
                            "Are you sure you want to change this booking initial payment status?"
                          }
                          result={"single"}
                          resultRequired={true}
                          customError={"Please select a paymet status"}
                          selectorLabel={"Please select a paymet status"}
                          options={[
                            {
                              value: ChapaPaymentStatus.Success,
                              label: "Success",
                            },
                            {
                              value: ChapaPaymentStatus.Rejected,
                              label: "Rejected",
                            },
                          ]}
                          yesText={"Ok"}
                          onYes={(data: ChapaPaymentStatus) => {
                            updatInitialPaymentStatus({
                              bookingId: `${eachBooking.id}`,
                              initialPaymentStatus: data,
                            })
                              .unwrap()
                              .then((response) => {
                                //
                              });
                          }}
                        >
                          <Tooltip label="Change Initial Payment Status">
                            <Button
                              size="small"
                              className={
                                "text-white text-xs flex  items-center justify-center"
                              }
                              type="button"
                              loading={
                                initialPaymentStatusResponse.isLoading &&
                                initialPaymentStatusResponse.originalArgs
                                  ?.bookingId === eachBooking.id
                              }
                            >
                              <IconCircleCheck size={19} strokeWidth={1.5} />
                            </Button>
                          </Tooltip>
                        </Confirmation>
                      </div>
                    )}
                </span>
              </div>
            </>
          );
        },
      },
    ],
    filter: [
      [
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Morning,
          name: "Morning",
        },
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Afternoon,
          name: "Afternoon",
        },
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Both,
          name: "Both",
        },
      ],
      [
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.CREATED,
          name: "Active",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.InitialFeePaid,
          name: "Initial Fee Paid",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.BOOKED,
          name: "Booked",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.CANCELLED,
          name: "Cancelled",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.EXPIRED,
          name: "Expired",
        },
      ],
      [
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Pending,
          name: "Pending",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Success,
          name: "Success",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Cancelled,
          name: "Cancelled",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Error,
          name: "Error",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Failed,
          name: "Failed",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Timeout,
          name: "Timeout",
        },
      ],
      [
        {
          field: "isActive",
          operator: "=",
          value: true,
          name: "Active",
        },
        {
          field: "isActive",
          operator: "=",
          value: false,
          name: "InActive",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        showNewButton={false}
        title={"Bookings"}
        total={bookings?.data?.count}
        itemsLoading={bookings.isLoading || bookings?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            filter: [
              [
                { field: "passenger.name", operator: "like", value: data },
                {
                  field: "passenger.phoneNumber",
                  operator: "like",
                  value: data,
                },
                { field: "route.name", operator: "like", value: data },
              ],
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Payment Receipt Preview"
      >
        {selectedImageUrl && (
          <Image
            maw={240}
            mx="auto"
            radius="md"
            src={selectedImageUrl}
            alt="Image is not available"
          />
        )}
      </Modal>
    </div>
  );
}
