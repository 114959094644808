/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Owner } from "../../../models/owner.model";
import { Vehicle } from "../../../models/vehicle.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { VEHICLE_ENDPOINT } from "../../vehicle/vehicle.endpoints";
import { OWNER_ENDPOINT } from "../owner.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let vehiclesCollection: CollectionQuery;
let archivedVehiclesCollection: CollectionQuery;

const ownerQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOwners: builder.query<Collection<Owner>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${OWNER_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          if (error.status === 403) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        }
      },
    }),
    createOwner: builder.mutation<Owner, Owner>({
      query: (data: Owner) => ({
        url: `${OWNER_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteOwner: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${OWNER_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission: "manage-owners",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((owner) => owner.id !== id);
                draft.count -= 1;
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData(
              "getArchivedOwners",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter((owner) => owner.id !== id);
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getOwner: builder.query<Owner, string>({
      query: (id: string) => ({
        url: `${OWNER_ENDPOINT.owner}/${id}`,
        method: "GET",
      }),
    }),
    getArchivedOwner: builder.query<Owner, string>({
      query: (id: string) => ({
        url: `${OWNER_ENDPOINT.archived_owner}/${id}`,
        method: "GET",
      }),
    }),
    updateOwner: builder.mutation<Owner, Owner>({
      query: (data: Owner) => ({
        url: `${OWNER_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === data.id) {
                    if (data.isCompany) {
                      const returnData = { ...data, gender: "" };
                      return returnData;
                    }
                    return data;
                  } else {
                    return owner;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveOwner: builder.mutation<Owner, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${OWNER_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === arg.id) {
                    return data;
                  } else {
                    return owner;
                  }
                });
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData("getOwner", arg?.id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            ownerQuery.util.updateQueryData(
              "getArchivedOwner",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedOwners: builder.query<Collection<Owner>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${OWNER_ENDPOINT.archived_owners}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    restoreOwner: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${OWNER_ENDPOINT.restore_owner}/${id}`,
        method: "POST",
        permission: "manage-owners",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === id) {
                    return data;
                  } else {
                    return owner;
                  }
                });
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData("getOwner", id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            ownerQuery.util.updateQueryData("getArchivedOwner", id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    activateOwner: builder.mutation<Owner, string>({
      query: (id: string) => ({
        url: `${OWNER_ENDPOINT.activate}/${id}`,
        method: "POST",
        permission: "activate-or-block-owner",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.enabled) {
            notification("success", "Successfully activated");
          } else {
            notification("success", "Successfully deactivated");
          }
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === id) {
                    owner.enabled = data.enabled;
                    return owner;
                  }
                  return owner;
                });
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData("getOwner", id, (draft) => {
              draft.enabled = data.enabled;
            })
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getOwnerVehicles: builder.query<Collection<Vehicle>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${OWNER_ENDPOINT.vehicles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          vehiclesCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    archiveOwnerVehicle: builder.mutation<any, any>({
      query: (data: { id: string; ownerId: string; reason: string }) => ({
        url: `${VEHICLE_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully archived");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwnerVehicles",
              vehiclesCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle: Vehicle) => vehicle.id !== arg.id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedOwnerVehicles: builder.query<
      Collection<Vehicle>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${VEHICLE_ENDPOINT.archived_owners_vehicles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedVehiclesCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    deleteOwnerVehicle: builder.mutation<boolean, any>({
      query: (data: { id: string; ownerId: string }) => ({
        url: `${VEHICLE_ENDPOINT.delete}`,
        method: "DELETE",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Deleted successfully");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwnerVehicles",
              vehiclesCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle: Vehicle) => vehicle.id !== arg.id
                );
                draft.count -= 1;
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData(
              "getArchivedOwnerVehicles",
              archivedVehiclesCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle: Vehicle) => vehicle.id !== arg.id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    restoreOwnerVehicle: builder.mutation<any, any>({
      query: (data: { id: string; ownerId: string }) => ({
        url: `${OWNER_ENDPOINT.restore_owner_vehicle}`,
        method: "POST",
        data: data,
        permission: "manage-owners",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully restored");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwnerVehicles",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData(
              "getArchivedOwnerVehicles",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle) => vehicle.id !== arg.id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateOwnerDocument: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${OWNER_ENDPOINT.update_document}`,
        method: "POST",
        data: data,
        permission: "update-owner-document",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully uploaded");
          dispatch(
            ownerQuery.util.updateQueryData(
              "getOwners",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === data.id) {
                    return data;
                  } else {
                    return owner;
                  }
                });
              }
            )
          );
          dispatch(
            ownerQuery.util.updateQueryData("getOwner", data?.id, (draft) => {
              Object.assign(draft, data);
            })
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetOwnerQuery,
  useLazyGetOwnersQuery,
  useCreateOwnerMutation,
  useDeleteOwnerMutation,
  useUpdateOwnerMutation,
  useArchiveOwnerMutation,
  useLazyGetArchivedOwnersQuery,
  useRestoreOwnerMutation,
  useActivateOwnerMutation,
  useLazyGetOwnerVehiclesQuery,
  useArchiveOwnerVehicleMutation,
  useLazyGetArchivedOwnerVehiclesQuery,
  useDeleteOwnerVehicleMutation,
  useRestoreOwnerVehicleMutation,
  useUpdateOwnerDocumentMutation,
  useLazyGetArchivedOwnerQuery,
} = ownerQuery;
