/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Permission } from "../../../models/permission.model";
import { Role } from "../../../models/role.model";
import { User } from "../../../models/user.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { USER_ENDPOINT } from "../user.endpoint";

let userCollection: CollectionQuery;
let archivedUserCollection: CollectionQuery;
let rolePermissionCollection: any;
const userQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedUser: builder.query<User, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.archivedUser}/${id}`,
        method: "get",
      }),
    }),

    getArchivedUsers: builder.query<Collection<User>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${USER_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedUserCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getUsers: builder.query<Collection<User>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${USER_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            userCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getRoles: builder.query<Collection<Role>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${USER_ENDPOINT.roles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getUserRoles: builder.query<Collection<Role>, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.user_role}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createUser: builder.mutation<User, User>({
      query: (newData: any) => ({
        url: `${USER_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permissions: "manage-users",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateUser: builder.mutation<User, User>({
      query: (newData: any) => ({
        url: `${USER_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-users",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateUserProfile: builder.mutation<User, any>({
      query: (newData: any) => ({
        url: `${USER_ENDPOINT.updateProfile}` + newData.id,
        method: "post",
        data: newData.data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateUser: builder.mutation<User, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission: "activate-or-block-users",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              userQuery.util.updateQueryData("getUser", param, (draft) => {
                draft.enabled = data.enabled;
              })
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    archiveUser: builder.mutation<User, { id: string; reason: string }>({
      query: (data) => ({
        url: `${USER_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-users",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  draft.data = draft?.data?.map((provider) => {
                    if (provider.id === arg.id) {
                      return data;
                    } else {
                      return provider;
                    }
                  });
                }
              )
            );
            dispatch(
              userQuery.util.updateQueryData("getUser", arg?.id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              userQuery.util.updateQueryData(
                "getArchivedUser",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    deleteUser: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-users",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              userQuery.util.updateQueryData(
                "getArchivedUsers",
                archivedUserCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((user) => user.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    restoreUser: builder.mutation<User, string>({
      query: (id: string) => ({
        url: `${USER_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-users",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              userQuery.util.updateQueryData(
                "getUsers",
                userCollection,
                (draft) => {
                  draft.data = draft?.data?.map((provider) => {
                    if (provider.id === id) {
                      return data;
                    } else {
                      return provider;
                    }
                  });
                }
              )
            );
            dispatch(
              userQuery.util.updateQueryData("getUser", id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              userQuery.util.updateQueryData("getArchivedUser", id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getRolePermissions: builder.query<Collection<Permission>, any>({
      query: (data: { roleId: string; collection: CollectionQuery }) => ({
        url: `${USER_ENDPOINT.role_permissions}/${data.roleId}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          rolePermissionCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getUserRolePermissions: builder.query<Permission[], any>({
      query: (data: { roleId: string; accountId: string }) => ({
        url: `${USER_ENDPOINT.user_role_permissions}/${data.accountId}/${data.roleId}`,
        method: "GET",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    addRoleToAccount: builder.mutation<Role[], any>({
      query: (data: any) => ({
        url: `${USER_ENDPOINT.add_account_role}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              userQuery.util.updateQueryData(
                "getUserRoles",
                param.accountId,
                (draft) => {
                  if (data) {
                    draft.data = data;
                    draft.count = data.length;
                  }
                }
              )
            );
            notification("success", "Successfully assigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    addAccountRolePermissions: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${USER_ENDPOINT.add_account_role_permission}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              userQuery.util.updateQueryData(
                "getUserRolePermissions",
                { roleId: param.roleId, accountId: param.accountId },
                (draft) => {
                  if (data) {
                    draft = draft.concat(data);
                  }
                }
              )
            );
            notification("success", "Permission Successfully assigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    removeAccountRole: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${USER_ENDPOINT.remove_account_role}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              userQuery.util.updateQueryData(
                "getUserRoles",
                param.accountId,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (role) => role.id !== param.roleId
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully removed");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetUserQuery,
  useLazyGetArchivedUserQuery,
  useLazyGetUsersQuery,
  useLazyGetArchivedUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserProfileMutation,
  useArchiveUserMutation,
  useActivateUserMutation,
  useRestoreUserMutation,
  useDeleteUserMutation,
  useLazyGetRolesQuery,
  useLazyGetUserRolesQuery,
  useLazyGetRolePermissionsQuery,
  useAddRoleToAccountMutation,
  useLazyGetUserRolePermissionsQuery,
  useAddAccountRolePermissionsMutation,
  useRemoveAccountRoleMutation,
} = userQuery;
