/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { DriverAssignment } from "../../../models/driver-assignment.model";
import { Group } from "../../../models/group.model";
import { Candidate, Job, JobStatus } from "../../../models/job.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { JOB_ENDPOINT } from "../job.endpoint";

let jobCollection: CollectionQuery;
let archivedJobCollection: CollectionQuery;
let candidateCollection: { id: string; collection: CollectionQuery };
let unassignedGroupCollection: CollectionQuery;
let driverAssignmentCollection: CollectionQuery;
const jobQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getJob: builder.query<Job, string>({
      query: (id: string) => ({
        url: `${JOB_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedJob: builder.query<Job, string>({
      query: (id: string) => ({
        url: `${JOB_ENDPOINT.archivedJob}/${id}`,
        method: "get",
      }),
    }),

    getArchivedJobs: builder.query<Collection<Job>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${JOB_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedJobCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getJobs: builder.query<Collection<Job>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${JOB_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            jobCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getUnassignedGroups: builder.query<Collection<Group>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${JOB_ENDPOINT.getUnassignedGroups}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            unassignedGroupCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getCandidates: builder.query<
      Collection<Candidate>,
      { id: string; collection: CollectionQuery }
    >({
      query: (data: { id: string; collection: CollectionQuery }) => ({
        url: `${JOB_ENDPOINT.getCandidates}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            candidateCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createJob: builder.mutation<Job, Job>({
      query: (newData: any) => ({
        url: `${JOB_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateJob: builder.mutation<Job, Job>({
      query: (newData: any) => ({
        url: `${JOB_ENDPOINT.update}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((job) => {
                      if (job.id === data.id) return data;
                      else {
                        return job;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    openOrCloseJob: builder.mutation<Job, { id: string; status: JobStatus }>({
      query: (data: { id: string; status: JobStatus }) => ({
        url: `${JOB_ENDPOINT.openOrClose}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((job) => {
                      if (job.id === data.id) return data;
                      else {
                        return job;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              jobQuery.util.updateQueryData("getJob", param?.id, (draft) => {
                if (data) {
                  draft.status = data.status;
                }
              })
            );
            dispatch(
              jobQuery.util.updateQueryData(
                "getArchivedJob",
                param?.id,
                (draft) => {
                  if (data) {
                    draft.status = data.status;
                  }
                }
              )
            );
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveJob: builder.mutation<Job, { id: string; reason: string }>({
      query: (data) => ({
        url: `${JOB_ENDPOINT.archive}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((city) => {
                      if (city.id === query.id) {
                        return data;
                      } else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              jobQuery.util.updateQueryData("getJob", query?.id, (draft) => {
                if (data) {
                  draft.deletedAt = data.deletedAt;
                }
              })
            );
            dispatch(
              jobQuery.util.updateQueryData(
                "getArchivedJob",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data.deletedAt;
                  }
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteJob: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${JOB_ENDPOINT.delete}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  draft.data = draft.data.filter((job) => job.id !== id);
                  draft.count -= 1;
                }
              )
            );
            dispatch(
              jobQuery.util.updateQueryData(
                "getArchivedJobs",
                archivedJobCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((job) => job.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    removeCandidate: builder.mutation<
      boolean,
      { driverId: string; jobId: string }
    >({
      query: (data: { driverId: string; jobId: string }) => ({
        url: `${JOB_ENDPOINT.removeCandidate}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              jobQuery.util.updateQueryData(
                "getCandidates",
                candidateCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (candidate) =>
                        candidate.driverId !== params.driverId &&
                        candidate.jobId !== params.jobId
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    unassignDriver: builder.mutation<boolean, { id: string; jobId: string }>({
      query: (data: { id: string; jobId: string }) => ({
        url: `${JOB_ENDPOINT.unassignDriver}/${data.id}`,
        method: "delete",
      }),

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Assignment removed successfully");
            dispatch(
              jobQuery.util.updateQueryData(
                "getDriverAssignmentsForJob",
                driverAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (assignment) => assignment.id !== params.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );

            dispatch(
              jobQuery.util.updateQueryData("getJob", params.jobId, (draft) => {
                draft.status = JobStatus.Close;
                draft.driverId = undefined;
              })
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreJob: builder.mutation<Job, string>({
      query: (id: string) => ({
        url: `${JOB_ENDPOINT.restore}/${id}`,
        method: "post",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((city) => {
                      if (city.id === id) {
                        return data;
                      } else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              jobQuery.util.updateQueryData("getJob", id, (draft) => {
                if (data) {
                  draft.deletedAt = data.deletedAt;
                }
              })
            );
            dispatch(
              jobQuery.util.updateQueryData("getArchivedJob", id, (draft) => {
                if (data) {
                  draft.deletedAt = data.deletedAt;
                }
              })
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getDriverAssignmentsForJob: builder.query<
      Collection<DriverAssignment>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${JOB_ENDPOINT.getDriverAssignmentsForJob}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            driverAssignmentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createDriverAssignment: builder.mutation<Job, DriverAssignment>({
      query: (newData: DriverAssignment) => ({
        url: `${JOB_ENDPOINT.createDriverAssignment}`,
        method: "post",
        data: newData,
      }),
      invalidatesTags: ["group_driver_assignment"],
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              jobQuery.util.updateQueryData(
                "getJobs",
                jobCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );

            dispatch(
              jobQuery.util.updateQueryData(
                "getJob",
                param?.jobId ?? "",
                (draft) => {
                  draft.driverId = param?.driverId;
                  draft.status = JobStatus.Assigned;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetJobQuery,
  useLazyGetJobsQuery,
  useLazyGetArchivedJobQuery,
  useLazyGetArchivedJobsQuery,

  useLazyGetUnassignedGroupsQuery,

  useUpdateJobMutation,
  useOpenOrCloseJobMutation,
  useCreateJobMutation,
  useDeleteJobMutation,
  useArchiveJobMutation,
  useRestoreJobMutation,

  useLazyGetCandidatesQuery,
  useCreateDriverAssignmentMutation,
  useRemoveCandidateMutation,
  useUnassignDriverMutation,
  useLazyGetDriverAssignmentsForJobQuery,
} = jobQuery;
