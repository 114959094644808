import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  Modal,
  Select,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandTelegram,
  IconDeviceFloppy,
  IconRefresh,
  IconTrash,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import locations from "../../../shared/utility/locations.json";

import * as Yup from "yup";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import {
  CredentialType,
  Notification,
  NotificationMethod,
} from "../../../models/notification.model";
import { ParentStatus } from "../../../models/parent.model";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationsQuery,
} from "../store/notification.query";

import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import SendByUploadFile from "../component/send-by-upload-file";
import "../styles/style.css";

export default function NotificationPage() {
  const params = useParams();
  const location = useLocation();
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);

  const [specificLocations] = useState<any>(locations);
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
    includes: ["accountReceiver"],
  });
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [showSpecificLocation, setShowSpecificLocation] =
    useState<boolean>(false);

  const defaultValue = {
    title: "",
    body: "",
    type: CredentialType.All,
    status: null,
    method: NotificationMethod.Both,
  };

  const [getNotifications, notifications] = useLazyGetNotificationsQuery();
  const [createNotification, notificationResponse] =
    useCreateNotificationMutation();
  const [deleteNotification, deleteNotificationResponse] =
    useDeleteNotificationMutation();

  let schema = Yup.object()
    .shape({
      type: Yup.string().typeError("Type should be string").required(),
      body: Yup.string().typeError("Body should be string").required(),
      title: Yup.string().typeError("Title should be string").required(),
      method: Yup.string().typeError("Method  should be string").required(),
      // status: Yup.string().nullable().notRequired().oneOf([
      //   ParentStatus.New,
      //   ParentStatus.Assigned,
      //   ParentStatus.PartiallyAssigned,
      //   ParentStatus.WaitingToBeAssigned,
      //   ParentStatus.Declined,
      //   ParentStatus.Agreed,
      //   ParentStatus.ContractSigned,
      // ])
    })
    .required();
  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    getNotifications(collection)
      .unwrap()
      .then((response) => {});
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/city/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const onSubmit = async (data: any) => {
    createNotification(data)
      .unwrap()
      .then((response) => {
        reset(defaultValue);
        setOpenNotificationModal(false);
        setShowStatus(false);
        setShowSpecificLocation(false);
        reset(defaultValue);
      });
  };

  const onError = (data: any) => {};

  const data: Notification[] | undefined = notifications.data?.data;
  const config: EntityConfig<Notification> = {
    primaryColumn: { key: "title", name: "Title", hideSort: true },
    rootUrl: "/notification",
    identity: "id",
    name: "title",
    showDetail: false,
    visibleColumn: [
      {
        key: "title",
        name: "Title",
        hideSort: true,
        render(notification) {
          return notification?.type
            ? notification.title
            : `${notification?.accountReceiver?.name} (${notification?.accountReceiver?.phoneNumber})`;
        },
      },
      { key: "body", name: "Body", hideSort: true },
      { key: "method", name: "Notification Method", hideSort: true },
      { key: "type", name: "Type", hideSort: true },
      { key: "createdAt", name: "Sent at", isDate: true },
      {
        key: "actions",
        name: "Actions",
        hideSort: true,
        render: (value: Notification) => {
          return (
            <>
              <div className="flex space-x-0">
                <Confirmation
                  type={"danger"}
                  message={"Are you sure you want to it?"}
                  header={`Delete`}
                  yesText={"Delete"}
                  onYes={(reason: string) => {
                    // archiveFeedback({id: value.id, reason: reason});
                    deleteNotification(value.id ?? "");
                  }}
                >
                  <Tooltip label="Delete Notification">
                    <Button
                      variant="filleds"
                      // loading={archiveResponse.isLoading}
                      className="bg-white text-danger mx-1"
                      type="button"
                      classNames={{ label: "flex space-x-1" }}
                      size={"xs"}
                    >
                      <span>
                        <IconTrash size={20} strokeWidth={1.5} />
                      </span>
                    </Button>
                  </Tooltip>
                </Confirmation>

                <Tooltip label="Resend Notification">
                  <Button
                    variant="filled"
                    // loading={archiveResponse.isLoading}
                    className="bg-white text-primary mx-1"
                    type="button"
                    onClick={() => {
                      setOpenNotificationModal(true);
                      value.type === CredentialType.Parent
                        ? setShowStatus(true)
                        : setShowStatus(false);

                      value.type === CredentialType.Driver
                        ? setShowSpecificLocation(true)
                        : setShowSpecificLocation(false);
                      setValue("title", value.title);
                      setValue("body", value.body);
                      setValue("type", value.type);
                      setValue("status", value.status);
                      setValue("driverLocation", value.driverLocation);
                      setValue("method", value.method);
                    }}
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                  >
                    <span>
                      <IconRefresh size={22} strokeWidth={1.5} />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            </>
          );
        },
      },
    ],
    filter: [
      [
        {
          field: "type",
          value: CredentialType.All,
          operator: "=",
          name: "All",
        },
        {
          field: "type",
          value: CredentialType.Driver,
          operator: "=",
          name: "Driver",
        },
        {
          field: "type",
          value: CredentialType.Parent,
          operator: "=",
          name: "Parent",
        },
        {
          field: "type",
          value: CredentialType.Passenger,
          operator: "=",
          name: "Passenger",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <Card
        title="Notifications"
        action={
          <>
            <div className="p-3">
              <div className="flex space-x-1">
                {/* <h1>Notifications</h1> */}
                <Tooltip label="Send new notification">
                  <Button
                    classNames={{ label: "flex space-x-1 " }}
                    variant="default"
                    className="bg-primary text-white"
                    type="button"
                    size="xs"
                    onClick={() => {
                      setOpenNotificationModal(true);
                      reset(defaultValue);
                    }}
                  >
                    <IconBrandTelegram />
                  </Button>
                </Tooltip>

                <SendByUploadFile />
              </div>
            </div>
          </>
        }
      >
        <EntityList
          viewMode={viewMode}
          title={"Notifications"}
          header={<></>}
          total={notifications?.data?.count}
          itemsLoading={notifications.isFetching}
          showNewButton={false}
          showExport={false}
          showArchivedCheckBox={false}
          showSelector={false}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          onArchivedChecked={(e) =>
            setCollection({
              ...collection,
              withArchived: e,
            })
          }
          onPaginationChange={(skip: number, top: number) => {
            if (collection.skip !== skip || collection.top !== top) {
              setCollection({ ...collection, skip: skip, top: top });
            }
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: ["title"],
            });
          }}
          onFilterChange={(data: any) => {
            if (collection?.filter || data.length > 0) {
              setCollection({ ...collection, filter: data });
            }
          }}
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
      </Card>

      <Modal
        opened={openNotificationModal}
        onClose={() => {
          setOpenNotificationModal(false);
          setShowStatus(false);
          setShowSpecificLocation(false);
          reset(defaultValue);
        }}
        className="my-modal-content"
        withCloseButton={false}
        title="Notification"
        padding={20}
        size={"xl"}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} className={"mt-2"}>
          <div className="w-full">
            <div className="flex w-full space-x-4  justify-center">
              <Controller
                render={({ field }) => (
                  <Select
                    radius={"xs"}
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={errors?.method && `${errors?.method?.message}`}
                    label="Notification Method"
                    placeholder="Notification Method..."
                    // onSelect={(e) =>
                    //   e.currentTarget.value.toLowerCase() ==
                    //   CredentialType.Parent
                    //     ? setShowStatus(true)
                    //     : setShowStatus(false)
                    // }
                    data={[
                      { label: "Both", value: NotificationMethod.Both },
                      {
                        label: "Notification",
                        value: NotificationMethod.Notification,
                      },
                      { label: "Sms", value: NotificationMethod.Sms },
                    ]}
                    withAsterisk
                    {...field}
                  />
                )}
                name="method"
                control={control}
              />
              <TextInput
                classNames={{ label: "dark:text-white" }}
                className="w-full "
                error={errors.title && `${errors?.title?.message}`}
                withAsterisk
                label="Title"
                placeholder="Title"
                {...register("title")}
              />

              <Controller
                render={({ field }) => (
                  <Select
                    radius={"xs"}
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={errors?.type && `${errors?.type?.message}`}
                    label="To"
                    placeholder="To..."
                    onSelect={(e) => {
                      switch (e.currentTarget.value.toLowerCase()) {
                        case CredentialType.Parent:
                          setShowStatus(true);
                          setShowSpecificLocation(false);
                          break;

                        case CredentialType.Driver:
                          setShowStatus(false);
                          setShowSpecificLocation(true);
                          break;

                        case CredentialType.All:
                        case CredentialType.Passenger:
                          setShowStatus(false);
                          setShowSpecificLocation(false);
                          break;

                        default:
                          setShowStatus(false);
                          setShowSpecificLocation(false);
                          break;
                      }
                      // e.currentTarget.value.toLowerCase() ==
                      // CredentialType.Parent
                      //   ? setShowStatus(true)
                      //   : setShowStatus(false);
                      // e.currentTarget.value.toLowerCase() ==
                      // CredentialType.Driver
                      //   ? setShowSpecificLocation(true)
                      //   : setShowSpecificLocation(false);
                    }}
                    data={[
                      { label: "All", value: CredentialType.All },
                      { label: "Driver", value: CredentialType.Driver },
                      { label: "Parent", value: CredentialType.Parent },
                      { label: "Passenger", value: CredentialType.Passenger },
                    ]}
                    withAsterisk
                    {...field}
                  />
                )}
                name="type"
                control={control}
              />

              {showStatus && (
                <Controller
                  render={({ field }) => (
                    <Select
                      radius={"xs"}
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors?.type && `${errors?.type?.message}`}
                      label="Parent Status"
                      placeholder="Parent Status"
                      data={[
                        { value: "all", label: "All" },
                        { value: ParentStatus.New, label: "New" },
                        { value: ParentStatus.Assigned, label: "Assigned" },
                        {
                          value: ParentStatus.PartiallyAssigned,
                          label: "Partially Assigned",
                        },
                        {
                          value: ParentStatus.WaitingToBeAssigned,
                          label: "Waiting To Be Assigned",
                        },
                        { value: ParentStatus.Declined, label: "Declined" },
                        { value: ParentStatus.Agreed, label: "Agreed" },
                        {
                          value: ParentStatus.ContractSigned,
                          label: "Contract Signed",
                        },
                      ]}
                      withAsterisk
                      {...field}
                    />
                  )}
                  name="status"
                  control={control}
                />
              )}

              {showSpecificLocation && (
                <Controller
                  control={control}
                  name="driverLocation"
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      className="w-full"
                      searchable
                      nothingFound={
                        <div className="fle w-full justify-center items-center">
                          <EmptyIcon />
                        </div>
                      }
                      error={
                        errors.driverLocation &&
                        `${errors?.driverLocation?.message}`
                      }
                      radius={"xs"}
                      classNames={{
                        label: "dark:text-white",
                      }}
                      // defaultValue="Ethiopia"
                      label="Specific Location"
                      placeholder="Select Specific Location"
                      data={specificLocations?.map((location: any) => ({
                        label: location.name.toUpperCase(),
                        value: location.name,
                      }))}
                      maxDropdownHeight={400}
                      {...field}
                    />
                  )}
                />
              )}
            </div>

            <div className="flex w-full space-x-4  justify-center">
              <Textarea
                placeholder="Body..."
                label="Body"
                className="w-full"
                withAsterisk
                error={errors?.body && `${errors?.body?.message}`}
                {...register("body")}
              />
            </div>
          </div>
          <Group position="right" mt="xl">
            <>
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                type="reset"
                onClick={() => reset(defaultValue)}
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current h-6"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>
              <Button
                variant="filled"
                className="bg-primary text-white"
                type="submit"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={notificationResponse.isLoading}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </>
          </Group>
        </form>
      </Modal>
    </div>
  );
}
