import { Button, Menu } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Wallet } from "../../../models/wallet.model";
import Card from "../../../shared/component/Card/card-component";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useLazyGetCorporateWalletsQuery,
  useLazyGetWalletsQuery,
} from "../store/wallet.query";

export default function WalletPage() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [wallets, setWallets] = useState<any>();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [passengerType, setPassengerType] = useState<"passenger" | "corporate">(
    "passenger"
  );

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getWallets, walletResponse] = useLazyGetWalletsQuery();
  const [getCorporateWallets, corporateWalletResponse] =
    useLazyGetCorporateWalletsQuery();

  useEffect(() => {
    if (passengerType === "passenger") {
      getWallets(
        {
          ...collection,
          includes: ["passenger"],
          searchFrom: ["passenger.name", "passenger.phoneNumber"],
        },
        true
      ).then((response) => setWallets(response));
      config.visibleColumn[4] = {
        key: "corporateWalletBalance",
        name: "Corporate Wallet Ballance",
        render: (wallet) => {
          return (
            <span className="text-primary">
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(Number(wallet.balance))}
            </span>
          );
        },
      };
    } else {
      getCorporateWallets(
        {
          ...collection,
          includes: ["corporate"],
          searchFrom: ["corporate.name", "corporate.phoneNumber"],
        },
        true
      ).then((response) => setWallets(response));
    }
  }, [collection, getWallets, getCorporateWallets, passengerType]);

  useEffect(() => {
    setWallets(walletResponse);
  }, [walletResponse]);

  useEffect(() => {
    setWallets(corporateWalletResponse);
  }, [corporateWalletResponse]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/wallets/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  const actions = (
    <div className="h-full flex items-center">
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button size="xs" variant="default" className="text-white bg-primary">
            <div className="capitalize flex items-center justify-center">
              <span>{passengerType}</span>
              <span>
                <IconChevronDown size={14} />
              </span>
            </div>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Choose Wallet list</Menu.Label>
          <Menu.Item
            onClick={() => {
              setPassengerType("passenger");
            }}
          >
            Passenger
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setPassengerType("corporate");
            }}
          >
            Corporate
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {/* <Button
        onClick={() => {
          if (passengerType === "corporate") {
            setPassengerType("passenger");
          } else {
            setPassengerType("corporate");
          }
        }}
        size="xs"
        variant="default"
        className="bg-primary text-white"
      >{`${
        passengerType === "corporate" ? "Passengers Wallet" : "Corporate wallet"
      }`}</Button> */}
    </div>
  );

  const data: Wallet[] | undefined = wallets?.data?.data;
  const config: EntityConfig<Wallet> = {
    primaryColumn: {
      key:
        passengerType === "passenger"
          ? ["passenger", "name"]
          : ["corporate", "name"],
      name: "Name",
    },
    rootUrl: "/wallets",
    detailUrl: `/wallets/detail/${passengerType}`,
    identity:
      passengerType === "passenger" ? ["passenger", "id"] : ["corporate", "id"],
    name: "balance",
    visibleColumn: [
      {
        key:
          passengerType === "passenger"
            ? ["passenger", "name"]
            : ["corporate", "name"],
        name: "Name",
        hideSort: true,
      },
      {
        key:
          passengerType === "passenger"
            ? ["passenger", "phoneNumber"]
            : ["corporate", "phoneNumber"],
        name: "Phone number",
        hideSort: true,
      },
      {
        key: "balance",
        name:
          passengerType === "passenger"
            ? "Personal Wallet Balance"
            : "Wallet Balance",
        hideSort: true,
        render: (wallet) => {
          return (
            <span className="text-primary">
              {wallet?.balance?.toFixed(2) ?? 0} ETB
            </span>
          );
        },
      },
      {
        key: "corporateWalletBalance",
        name: "Corporate Wallet Ballance",
        hideSort: true,
        hide: passengerType === "corporate" ? true : false,
        render: (wallet) => {
          return (
            <span className="text-primary">
              {wallet.corporateWalletBalance.toFixed(2)} ETB
            </span>
          );
        },
      },
      { key: "updatedAt", name: "updated At", isDate: true },
    ],
  };
  return (
    <Card title={"Wallets"} action={actions}>
      <EntityList
        showNewButton={false}
        viewMode={viewMode}
        title={"Wallets"}
        showArchivedCheckBox={false}
        total={wallets?.data?.count}
        itemsLoading={
          walletResponse?.isLoading ||
          walletResponse?.isFetching ||
          corporateWalletResponse.isLoading ||
          corporateWalletResponse?.isFetching
        }
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          if (data) {
            setCollection({
              ...collection,
              search: data,
            });
          } else {
            setCollection({
              ...collection,
              search: undefined,
              searchFrom: undefined,
            });
          }
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </Card>
  );
}
