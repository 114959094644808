export const CHATBOT_ENDPOINT = {
  list: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/get-conversations`,
  chatBot: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/get-conversation`,
  create: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/create-conversation`,
  update: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/update-conversation`,
  delete: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/delete-conversation`,
  downloadFile: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/download-chatbot-training-data`,
  train: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/train-chatbot`,
  uploadFile: `${process.env.REACT_APP_KABBA_AI_API}/chat-bot/upload-chatbot-training-data`,
};
