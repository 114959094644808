import { LoadingOverlay } from "@mantine/core";
import { LoadScript } from "@react-google-maps/api";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./shared/component/error-boundary/error-boundary";
import { store } from "./store/app.store";

const loadLibraries: (
  | "places"
  | "drawing"
  | "geometry"
  // | "localContext"
  | "visualization"
)[] = ["places"];
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ""}
          libraries={loadLibraries}
          loadingElement={<LoadingOverlay visible={true} />}
        >
          <App />
        </LoadScript>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(clg))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
