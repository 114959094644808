import {
  ActionIcon,
  Button,
  Center,
  Group,
  LoadingOverlay,
  Select,
  SelectItem,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconBook,
  IconBus,
  IconCheck,
  IconCircleMinus,
  IconLineDashed,
  IconPencil,
  IconPlus,
} from "@tabler/icons";
import { debounce } from "lodash-es";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { DriverFor, Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { Route } from "../../../models/route.model";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetRoutesQuery } from "../../route/store/route.query";
import {
  useCancelRouteAssignmentMutation,
  useChangeAssigmentAvailablityMutation,
  useCompleteRouteAssignmentMutation,
  useDeleteRouteAssignmentMutation,
  useLazyGetRouteAssignmentsQuery,
} from "../store/route-assignment.query";

type AssignmentProps = {
  route?: Route;
  loading?: boolean;
};

export default function RouteAssignmentPage(props: AssignmentProps) {
  const { route, loading } = props;
  const location = useLocation();

  const navigate = useNavigate();

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    filter: [
      [{ field: "vehicle.deletedAt", value: "", operator: "isNull" }],
      [{ field: "vehicleId", value: "", operator: "notNull" }],
      [{ field: "enabled", value: true, operator: "=" }],
    ],
  });

  const zeroPad = (num: number) => String(num).padStart(num > 9 ? 0 : 2, "0");
  function DateTimeOptions(type: "minute" | "hour") {
    let options: string[] = [];
    if (type === "minute") {
      for (let minute = 0; minute < 60; minute++) {
        options.push(zeroPad(minute));
      }
    }
    if (type === "hour") {
      for (let hour = 0; hour < 24; hour++) {
        options.push(zeroPad(hour));
      }
    }
    return options;
  }

  const [data, setData] = useState<RouteAssignment[]>([]);
  const [date, setDate] = useState<Date | null>();
  const [hour, setHour] = useState(DateTimeOptions("hour")[0]);
  const [minute, setMinute] = useState(DateTimeOptions("minute")[0]);

  const [_driver, setDriver] = useState("");
  const [_route, setRoute] = useState("");
  const [_status, setStatus] = useState("");
  const [routeSearch, setRouteSearch] = useState<string>("");
  const [driverSearch, setDriverSearch] = useState<string>("");

  const [getRouteAssignments, routeAssignments] =
    useLazyGetRouteAssignmentsQuery();
  const [getDrivers, drivers] = useLazyGetProvidersQuery();
  const [getRoutes, routes] = useLazyGetRoutesQuery();
  const [deleteRouteAssignments, deleteResponse] =
    useDeleteRouteAssignmentMutation();

  const [changeAssigmentAvailablity, changeAvailablityResponse] =
    useChangeAssigmentAvailablityMutation();
  const [completeRouteAssignment, completeResponse] =
    useCompleteRouteAssignmentMutation();

  const [cancelId, setCancelId] = useState("");
  const [cancelRouteAssignments, cancelResponse] =
    useCancelRouteAssignmentMutation();
  function formatDate(date: Date, format: string = "dddd, MMM DD , YYYY") {
    return moment(date).format(format);
  }

  useEffect(() => {
    getDrivers({
      ...collection,
      filter: [
        [{ field: "vehicle.deletedAt", value: "", operator: "isNull" }],
        [{ field: "vehicleId", value: "", operator: "notNull" }],
        [{ field: "enabled", value: true, operator: "=" }],
        [{ field: "driverFor", value: DriverFor.KabbaKids, operator: "!=" }],
      ],
      search: driverSearch,
      searchFrom: ["name", "phoneNumber"],
    });
  }, [driverSearch]);

  useEffect(() => {
    getRoutes({
      ...collection,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
      search: routeSearch,
      searchFrom: ["name"],
    });
  }, [routeSearch]);

  useEffect(() => {
    if (routeAssignments?.data?.data) {
      setData(routeAssignments.data.data);
    }
  }, [routeAssignments.data]);

  //Route assignments life line
  useEffect(() => {
    const _collection: CollectionQuery = {
      ...collection,
      includes: ["driver", "route", "driver.vehicle"],
      orderBy: [
        { field: "status", direction: "asc" },
        { field: "createdAt", direction: "desc" },
      ],
      //Not empty! to be populated by the next lines
      filter: [[]],
    };
    if (date) {
      _collection?.filter?.push([
        {
          field: "assignmentDate",
          operator: "=",
          value: formatDate(date, "YYYY-MM-DD"),
        },
      ]);
    }
    if (_driver) {
      _collection?.filter?.push([
        {
          field: "driverId",
          operator: "=",
          value: _driver,
        },
      ]);
    }
    if (_status) {
      _collection?.filter?.push([
        {
          field: "status",
          operator: "=",
          value: _status,
        },
      ]);
    }
    if (_route) {
      _collection?.filter?.push([
        {
          field: "routeId",
          operator: "=",
          value: _route,
        },
      ]);
    }
    getRouteAssignments(_collection);
  }, [route?.id, date, _driver, _route, _status, collection]);

  useEffect(() => {
    if (location?.state?.status) {
      setStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  const rows =
    data.length > 0 ? (
      data.map((assignment: RouteAssignment) => {
        return (
          <tr key={assignment.id} className="group">
            {/* <td>{formatDate(assignment.assignmentDate)}</td>
        <td>
          {dayjs(
            assignment?.assignmentDate +
              " " +
              assignment?.pickupTime?.replace("+00", "+03")
          ).format("LT")}
        </td> */}
            <td>
              {assignment?.route?.name ? assignment.route?.name : " ---- "}
            </td>
            <td>
              {assignment.driver?.name && assignment?.driver?.phoneNumber
                ? assignment.driver.name +
                  " (" +
                  assignment.driver?.phoneNumber +
                  ")"
                : " ----- "}
            </td>
            <td>
              {assignment?.driver?.vehicle?.plateNumber &&
              assignment?.driver?.vehicle?.model
                ? assignment.driver?.vehicle.plateNumber +
                  " (" +
                  assignment.driver?.vehicle.model +
                  ")"
                : " ----- "}
            </td>
            <td>
              {Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(
                assignment?.fee ?? 0
              ) + " ETB"}
            </td>
            <td>
              <Text fs={"md"}>
                {assignment?.shift
                  ? assignment?.shift?.charAt(0).toUpperCase() +
                    assignment.shift.slice(1)
                  : ""}
              </Text>
            </td>
            <td>
              {assignment?.morningPickupTime
                ? assignment?.morningPickupTime
                : "---"}
            </td>
            <td>
              {assignment?.afternoonPickupTime
                ? assignment?.afternoonPickupTime
                : "---"}
            </td>
            {/* <td>
              <HoverCard width={180} shadow="md">
                <HoverCard.Target>
                  <Text size="sm" className={"cursor-pointer"}>
                    {"View"}
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  className={
                    "text-justify break-all wrap max-h-60 overflow-auto"
                  }
                >
                  <List>
                    {assignment?.assignmentWeekDays &&
                      assignment?.assignmentWeekDays?.length > 0 &&
                      assignment.assignmentWeekDays?.map((day, idx) => (
                        <List.Item key={idx}>{day}</List.Item>
                      ))}
                  </List>
                </HoverCard.Dropdown>
              </HoverCard>
            </td> */}
            <td>
              {assignment.isVisible ? (
                <IconCheck strokeWidth={1.3} size={18} />
              ) : (
                <IconLineDashed strokeWidth={1.3} size={18} />
              )}
            </td>
            <td>
              {assignment.assignmentStartDate
                ? dateFormat(assignment.assignmentStartDate)
                : "---"}
            </td>
            <td>
              {assignment.createdAt ? dateFormat(assignment.createdAt) : "---"}
            </td>
            <td>
              <Group>
                {/* {assignment.status === "Assigned" && ( */}
                <div className="flex space-x-1">
                  {assignment?.status != "Completed" &&
                    assignment?.status != "Cancelled" &&
                    assignment?.status != "Started" && (
                      <Link to={`detail/${assignment.id}`}>
                        <Tooltip label="Edit Assignment">
                          <ActionIcon
                            variant="filled"
                            className="text-white bg-primary"
                            size={"sm"}
                          >
                            <span>
                              <IconPencil size={16} strokeWidth={1.5} />
                            </span>
                          </ActionIcon>
                        </Tooltip>
                      </Link>
                    )}
                  <Confirmation
                    type={assignment.isVisible ? "danger" : "notify"}
                    header={`${
                      assignment.isVisible ? "Hide" : "Show"
                    } Visiblity Confirmation`}
                    message={`Are you sure you want to ${
                      assignment.isVisible ? "hide" : "show"
                    } it?`}
                    onYes={(val: string) => {
                      changeAssigmentAvailablity(assignment.id ?? "");
                    }}
                    yesText={"Confirm"}
                  >
                    <Tooltip
                      label={`${
                        assignment.isVisible
                          ? "Hide Visiblity"
                          : "Show Visiblity"
                      }`}
                    >
                      <ActionIcon
                        variant="filled"
                        className={`${
                          assignment.isVisible
                            ? "bg-danger text-white"
                            : "bg-primary text-white"
                        }`}
                        size={"sm"}
                        loading={
                          changeAvailablityResponse?.isLoading &&
                          changeAvailablityResponse?.originalArgs ===
                            assignment?.id
                        }
                      >
                        <span>
                          <IconCircleMinus size={16} strokeWidth={1.5} />
                        </span>
                      </ActionIcon>
                    </Tooltip>
                  </Confirmation>
                  <Confirmation
                    type={"danger"}
                    header={"Delete Confirmation"}
                    message={
                      "Are you sure you want to delete the assignment permanently?"
                    }
                    onYes={(val: string) => {
                      deleteRouteAssignments(assignment.id ?? "");
                    }}
                    yesText={"Confirm"}
                  >
                    <Tooltip label="Delete Assignment">
                      <ActionIcon
                        variant="filled"
                        className="text-white bg-danger"
                        size={"sm"}
                        loading={
                          deleteResponse?.isLoading &&
                          deleteResponse?.originalArgs === assignment?.id
                        }
                      >
                        <span>
                          <IconCircleMinus size={16} strokeWidth={1.5} />
                        </span>
                      </ActionIcon>
                    </Tooltip>
                  </Confirmation>

                  {/* {(assignment?.status == "Assigned" ||
                assignment?.status == "Started") && (
                <Confirmation
                  type={"notify"}
                  header={
                    assignment?.status == "Assigned"
                      ? "Start Assignment"
                      : "Complete Assignment"
                  }
                  message={`Are you sure you want to ${
                    assignment?.status == "Assigned" ? "Start" : "Complete"
                  } the assignment?`}
                  onYes={(val: string) => {
                    const assignmentDate = new Date(
                      assignment?.assignmentDate + " " + assignment?.pickupTime
                    );
                    const currentDate = new Date();

                    const difference = dayjs(assignmentDate).diff(
                      currentDate,
                      "minute"
                    );

                    const isOnSameDate = dayjs().isSame(
                      assignment?.assignmentDate,
                      "day"
                    );

                    if (assignment?.status == "Started") {
                      completeRouteAssignment(assignment?.id);
                    } else if (assignment?.status == "Assigned") {
                      startRouteAssignment(assignment?.id);
                    }
                  }}
                  yesText={`${
                    assignment?.status == "Assigned" ? "Start" : "Complete"
                  }`}
                >
                  <Tooltip
                    label={`${
                      assignment?.status == "Assigned" ? "Start" : "Complete"
                    } Assignment`}
                  >
                    <ActionIcon
                      variant="filled"
                      className="text-white bg-green-500"
                      size={"sm"}
                      loading={
                        cancelId === assignment.id && cancelResponse.isLoading
                      }
                    >
                      <span>
                        {assignment?.status == "Assigned" ? (
                          <IconPlayerPlay size={16} strokeWidth={1.5} />
                        ) : (
                          <IconCheck size={16} strokeWidth={1.5} />
                        )}
                      </span>
                    </ActionIcon>
                  </Tooltip>
                </Confirmation>
              )}

              {assignment?.status == "Assigned" && (
                <Confirmation
                  type={"danger"}
                  header={"Cancel Confirmation"}
                  message={"Are you sure you want to cancel the assignment?"}
                  onYes={(val: string) => {
                    setCancelId(assignment.id ?? "");
                    cancelRouteAssignments({
                      id: assignment.id ?? "",
                      cancelledReason: { reason: val },
                    }).finally(() => setCancelId(""));
                  }}
                  yesText={"Confirm"}
                  result={"single"}
                  customInput={true}
                  resultRequired={true}
                  selectorLabel={"Please select a reason"}
                  labelDescription={"Please select a reason"}
                  options={Constants.ArchiveReason.map((reason) => {
                    return { label: reason, value: reason };
                  })}
                >
                  <Tooltip label="Cancel Assignment">
                    <ActionIcon
                      variant="filled"
                      className="text-white bg-danger"
                      size={"sm"}
                      loading={
                        cancelId === assignment.id && cancelResponse.isLoading
                      }
                    >
                      <span>
                        <IconCircleX size={16} strokeWidth={1.5} />
                      </span>
                    </ActionIcon>
                  </Tooltip>
                </Confirmation>
              )} */}
                </div>
                {/* )} */}
              </Group>
            </td>
            <td
              onClick={() => navigate(`bookings/${assignment?.id}`)}
              className="cursor-pointer"
            >
              <Tooltip label="Bookings">
                <span className="flex justify-end invisible w-full text-gray-400 group-hover:visible">
                  <IconBook />
                </span>
              </Tooltip>
            </td>
            <td
              onClick={() => navigate(`trips/${assignment?.id}`)}
              className="cursor-pointer"
            >
              <Tooltip label="Trips">
                <span className="flex justify-end invisible w-full text-gray-400 group-hover:visible">
                  <IconBus />
                </span>
              </Tooltip>
            </td>
          </tr>
        );
      })
    ) : (
      <EmptyIcon />
    );

  function parseDrivers() {
    const list: SelectItem[] = [];
    drivers?.data?.data.map((driver: Provider) => {
      if (driver.id) {
        list.push({
          label: `${driver.name}(${driver?.phoneNumber})`,
          value: driver.id,
        });
      }
    });
    return list;
  }
  function parseRoutes() {
    const list: SelectItem[] = [];
    routes?.data?.data.map((route: Route) => {
      if (route.id) {
        list.push({ label: route.name, value: route.id });
      }
    });
    return list;
  }

  return (
    <div className={`h-full flex m-4 relative shadow-md p-2`}>
      <div className={`border flex-col space-y-2 dark:border-gray-500 w-full`}>
        <Card title={"Route Assignments"}>
          <div className="h-full">
            <Stack>
              <Stack>
                <Group position={"apart"}>
                  <Select
                    className={"z-50"}
                    placeholder="Route"
                    onKeyUp={debounce(
                      (event: any) => setRouteSearch(event.target.value),
                      1000
                    )}
                    searchable
                    clearable
                    value={_route}
                    onChange={(value) => {
                      setRoute(value ?? "");
                    }}
                    data={parseRoutes()}
                  />
                  <Select
                    className={"z-50"}
                    placeholder="Driver"
                    searchable
                    clearable
                    onKeyUp={debounce(
                      (event: any) => setDriverSearch(event.target.value),
                      1000
                    )}
                    value={_driver}
                    onChange={(value) => {
                      setDriver(value ?? "");
                    }}
                    data={parseDrivers()}
                  />
                  {/* <Select
                    className={"z-50"}
                    placeholder="Status"
                    searchable
                    clearable
                    value={_status}
                    onChange={(value) => {
                      setStatus(value ?? "");
                    }}
                    data={Object.keys(AssignmentStatus).map((status) => {
                      return { label: status, value: status };
                    })}
                  /> */}
                  <Group position={"apart"}>
                    <Link to={"new"}>
                      <Button
                        variant="filled"
                        className="text-white bg-primary"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                      >
                        <span>
                          <IconPlus size={16} strokeWidth={1.5} />
                        </span>
                        <span>New</span>
                      </Button>
                    </Link>
                  </Group>
                </Group>
              </Stack>
              <Table>
                <thead>
                  <tr>
                    {/* <th>Date</th>
                    <th>Time</th> */}
                    <th>Route</th>
                    <th>Driver</th>
                    <th>Vehicle</th>
                    <th>Fee</th>
                    <th>Shift</th>
                    <th>Morning Pickup Time</th>
                    <th>Afternoon Pickup Time</th>
                    {/* <th>Trip Days</th> */}
                    <th>Visible</th>
                    <th>Trip Start Date</th>
                    <th>Assigned On</th>
                    <th>Action</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={"relative"}>
                  {routeAssignments.isFetching ? (
                    <tr>
                      <td
                        colSpan={9}
                        className={"relative h-40 justify-center z-20 tr"}
                      >
                        <LoadingOverlay visible={!loading} />
                      </td>
                    </tr>
                  ) : (
                    data?.length > 0 && rows
                  )}
                </tbody>
              </Table>
              {routeAssignments?.data?.count &&
                routeAssignments?.data?.count > 0 && (
                  <Group position={"right"} className={"w-full"}>
                    <Pagination
                      total={routeAssignments?.data?.count}
                      sizeChanger={true}
                      defaultPageSize={10}
                      pageSize={[10, 15, 20]}
                      onPaginationChange={(skip: number, top: number) => {
                        if (
                          collection.skip !== skip ||
                          collection.top !== top
                        ) {
                          setCollection({
                            ...collection,
                            skip: skip,
                            top: top,
                          });
                        }
                      }}
                    />
                  </Group>
                )}
              {data?.length === 0 && !routeAssignments.isFetching && (
                <Center>{<EmptyIcon />}</Center>
              )}
            </Stack>
          </div>
        </Card>
      </div>
    </div>
  );
}
