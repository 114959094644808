/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Vehicle } from "../../../models/vehicle.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { VEHICLE_ENDPOINT } from "../vehicle.endpoints";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let vehicleDetail: string;

const vehicleQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getVehicles: builder.query<Collection<Vehicle>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${VEHICLE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    createVehicle: builder.mutation<Vehicle, Vehicle>({
      query: (data: Vehicle) => ({
        url: `${VEHICLE_ENDPOINT.create}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    activateVehicle: builder.mutation<Vehicle, string>({
      query: (id: string) => ({
        url: `${VEHICLE_ENDPOINT.activate_vehicle}/${id}`,
        method: "POST",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data = draft.data.map((vehicle) => {
                  if (vehicle?.id === param) {
                    return { ...vehicle, enabled: data.enabled };
                  }
                  return vehicle;
                });
              }
            )
          );
          dispatch(
            vehicleQuery.util.updateQueryData("getVehicle", param, (draft) => {
              draft.enabled = data.enabled;
            })
          );
          if (data.enabled) {
            notification("success", "Successfully activated");
          } else {
            notification("success", "Successfully deactivated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    deleteVehicle: builder.mutation<boolean, any>({
      query: (data: { id: string; ownerId: string }) => ({
        url: `${VEHICLE_ENDPOINT.delete}`,
        method: "DELETE",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle) => vehicle.id !== param.id
                );
                draft.count -= 1;
              }
            )
          );
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getArchivedVehicles",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (vehicle) => vehicle.id !== param.id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getVehicle: builder.query<Vehicle, string>({
      query: (id: string) => ({
        url: `${VEHICLE_ENDPOINT.vehicle}/${id}`,
        method: "GET",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          vehicleDetail = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getArchivedVehicle: builder.query<Vehicle, string>({
      query: (id: string) => ({
        url: `${VEHICLE_ENDPOINT.archived_vehicle}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          vehicleDetail = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    updateVehicle: builder.mutation<Vehicle, Vehicle>({
      query: (data: Vehicle) => ({
        url: `${VEHICLE_ENDPOINT.update}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((owner) => {
                  if (owner.id === data.id) return data;
                  else {
                    return owner;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    archiveVehicle: builder.mutation<any, any>({
      query: (data: { id: string; ownerId: string; reason: string }) => ({
        url: `${VEHICLE_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((vehicle) => {
                  if (vehicle.id === arg.id) {
                    return { ...vehicle, deletedAt: data?.deletedAt };
                  } else {
                    return vehicle;
                  }
                });
              }
            )
          );
          dispatch(
            vehicleQuery.util.updateQueryData("getVehicle", arg.id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getArchivedVehicle",
              arg.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getArchivedVehicles: builder.query<Collection<Vehicle>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${VEHICLE_ENDPOINT.archived_owners_vehicles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    restoreVehicle: builder.mutation<any, any>({
      query: (data: { id: string; ownerId: string }) => ({
        url: `${VEHICLE_ENDPOINT.restore_vehicle}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicles",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((vehicle) => {
                  if (vehicle.id === arg.id) {
                    return { ...vehicle, deletedAt: data?.deletedAt };
                  } else {
                    return vehicle;
                  }
                });
              }
            )
          );
          dispatch(
            vehicleQuery.util.updateQueryData("getVehicle", arg.id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getArchivedVehicle",
              arg.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    updateVehicleDocument: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${VEHICLE_ENDPOINT.upload_document}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getVehicle",
              vehicleDetail,
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
          dispatch(
            vehicleQuery.util.updateQueryData(
              "getArchivedVehicle",
              vehicleDetail,
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );

          notification("success", "Successfully uploaded");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetVehiclesQuery,
  useLazyGetVehicleQuery,
  useCreateVehicleMutation,
  useDeleteVehicleMutation,
  useUpdateVehicleMutation,
  useArchiveVehicleMutation,
  useLazyGetArchivedVehiclesQuery,
  useRestoreVehicleMutation,
  useActivateVehicleMutation,
  useUpdateVehicleDocumentMutation,
  useLazyGetArchivedVehicleQuery,
} = vehicleQuery;
