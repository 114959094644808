import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, Modal, MultiSelect, Select } from "@mantine/core";
import { YearPickerInput } from "@mantine/dates";
import { IconDeviceFloppy } from "@tabler/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { CollectionQuery } from "../../../models/collection.model";
import { DriverFor, Provider } from "../../../models/provider.model";
import { Route } from "../../../models/route.model";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { monthsList } from "../../../shared/constants/Constants";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetRoutesQuery } from "../store/route-assignment.query";

interface Props {
  opened: boolean;
  close: () => void;
  submit?: (data: any) => void;
  isLoading?: boolean;
  data?: {
    paymentMonth: string;
    paymentYear: string;
    driverIds: string[];
    routeIds: string[];
  };
}
export default function PayOutModalComponent(props: Props) {
  const { opened, close, submit, isLoading, data } = props;

  const schema = yup
    .object({
      paymentMonth: yup.string().required("Please select a month"),
    })
    .required();

  const defaultValue = {
    paymentYear: new Date(),
  };

  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm<any>({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data: any) => {
    const extractedYear =
      data.paymentYear instanceof Date
        ? data.paymentYear.getFullYear()
        : data.paymentYear;
    // console.log({ ...data, paymentYear: extractedYear });
    submit?.({ ...data, paymentYear: extractedYear });
  };
  const [routeCollection, setRouteCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [getRoutes, routes] = useLazyGetRoutesQuery();
  const [getDrivers, drivers] = useLazyGetProvidersQuery();

  useEffect(() => {
    getRoutes({
      ...routeCollection,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
      includes: ["stations"],
    });
  }, [routeCollection]);

  useEffect(() => {
    getDrivers({
      ...driverCollection,
      includes: ["preferences"],
      filter: [
        [{ field: "vehicleId", value: "", operator: "notNull" }],
        [{ field: "enabled", value: true, operator: "=" }],
        [{ field: "driverFor", value: DriverFor.KabbaKids, operator: "!=" }],
      ],
    });
  }, [driverCollection]);

  function parseDrivers() {
    const data: { value: string; label: string }[] = [];
    if (drivers?.data?.data) {
      drivers.data.data.forEach((driver: Provider) => {
        let label = driver.name;
        if (driver.vehicle) {
          label += ` (${driver.vehicle.model}, ${driver.vehicle.plateNumber})`;
        }
        data.push({
          value: driver.id ?? "",
          label: label,
        });
      });
    }

    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  function parseRoutes() {
    const data: { value: string; label: string }[] = [];
    if (routes?.data?.data) {
      const withStationsRoutes = routes.data.data.filter((eachRoute) => {
        return eachRoute.stations?.length
          ? eachRoute.stations.length > 0
          : false;
      });

      withStationsRoutes.forEach((route: Route) => {
        let label = route.name;
        if (route?.city) {
          label += ` (${route.city.name})`;
        }
        data.push({
          value: route.id ?? "",
          label: label,
        });
      });
    }

    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  return (
    <>
      <Modal
        size={500}
        opened={opened}
        centered
        onClose={close}
        title="Pay Out"
      >
        <div className="flex justify-center h-full">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full gap-y-4 dark:text-white"
          >
            <div className="flex-col space-y-4">
              <div className="flex justify-between w-full space-x-4">
                <Controller
                  name="paymentYear"
                  control={control}
                  render={({ field }) => (
                    <YearPickerInput
                      className="w-full"
                      label="paymentYear"
                      withAsterisk
                      error={
                        errors.paymentYear && `${errors?.paymentYear?.message}`
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="paymentMonth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="w-full"
                      label="Month"
                      placeholder="Select a month"
                      dropdownPosition="bottom"
                      nothingFound={
                        <div className="relative flex items-center justify-center w-full">
                          <EmptyIcon />
                        </div>
                      }
                      withAsterisk
                      searchable
                      error={
                        errors.paymentMonth &&
                        `${errors?.paymentMonth?.message}`
                      }
                      data={monthsList}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="">
                {" "}
                <Controller
                  name="routeIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      dropdownPosition="bottom"
                      className="w-full"
                      searchable
                      error={errors.routeIds && `${errors?.routeIds?.message}`}
                      label="Route"
                      placeholder="Select a route"
                      data={parseRoutes() || []}
                      maxDropdownHeight={400}
                      onKeyUp={debounce((event: any) => {
                        event.target.value
                          ? setRouteCollection({
                              ...routeCollection,
                              skip: 0,
                              search: event.target.value,
                              searchFrom: ["name"],
                            })
                          : setRouteCollection({
                              ...routeCollection,
                              skip: 0,
                              search: "",
                              searchFrom: [],
                            });
                      }, 1000)}
                    />
                  )}
                />
              </div>
              <div className="">
                <Controller
                  name="driverIds"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      dropdownPosition="bottom"
                      className="w-full"
                      searchable
                      // itemComponent={SelectItem(cities.isFetching)}
                      error={
                        errors.driverIds && `${errors?.driverIds?.message}`
                      }
                      label="Driver"
                      placeholder="Select a driver"
                      data={parseDrivers() || []}
                      maxDropdownHeight={400}
                      {...field}
                      onKeyUp={debounce((event: any) => {
                        event.target.value
                          ? setDriverCollection({
                              ...driverCollection,
                              skip: 0,
                              search: event.target.value,
                              searchFrom: ["name", "phoneNumber"],
                            })
                          : setDriverCollection({
                              ...driverCollection,
                              skip: 0,
                              search: "",
                              searchFrom: [],
                            });
                      }, 1000)}
                    />
                  )}
                />
              </div>

              <div className="flex items-center justify-end w-full">
                <Group position="right" mt="xl">
                  <Button
                    variant="filled"
                    className="text-white bg-primary"
                    type="submit"
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                    loading={isLoading}
                  >
                    <span>
                      <IconDeviceFloppy size={22} strokeWidth={1.5} />
                    </span>
                    <span>Submit</span>
                  </Button>
                </Group>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
