import { Button, Modal, Popover, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChalkboard, IconDownload, IconPlus } from "@tabler/icons";
import * as FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ChatBot } from "../../../models/chatBot.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";

import NewChatBotComponent from "../component/new-chatBot-component";
import UploadTrainingFile from "../component/upload-training-file";
import {
  useDownloadChatBotTrainingDataMutation,
  useLazyGetChatBotsQuery,
  useTrainChatBotMutation,
} from "../store/chat-bot.query";

export default function ChatBotPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    offset: 0,
    limit: 10,
  });
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);
  const [getChatBots, chatBots] = useLazyGetChatBotsQuery();
  const [downloadChatBotTraining, downloadresponse] =
    useDownloadChatBotTrainingDataMutation();
  const [trainChatBot, trainChatBotResponse] = useTrainChatBotMutation();
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    getChatBots(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/chat-bot/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  // console.log("chatBots", chatBots);
  const data: ChatBot[] | undefined = chatBots?.data?.results;

  const config: EntityConfig<ChatBot> = {
    primaryColumn: {
      key: "question",
      name: "Question",
      render: (chatBot) => {
        return (
          <Text size="sm" className={chatBot?.deleted_at ? "text-danger" : ""}>
            {chatBot.question}
          </Text>
        );
      },
    },
    detailUrl: "detail",
    rootUrl: "/chat-bot",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "question",
        name: "Question",
        hideSort: true,
        render: (chatBot) => {
          return (
            <Text
              size="sm"
              className={chatBot?.deleted_at ? "text-danger" : ""}
            >
              {chatBot.question}
            </Text>
          );
        },
      },
      {
        key: "description",
        name: "description",
        hideSort: true,
        render: (eachChatBot: ChatBot) => {
          return (
            <>
              <Popover width={500} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <Text className="cursor-pointer flex items-center text-xs">
                    {eachChatBot?.answer && eachChatBot?.answer?.length > 25
                      ? eachChatBot?.answer?.substring(0, 22) + "..."
                      : eachChatBot?.answer}
                  </Text>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="xs">{eachChatBot?.answer}</Text>
                </Popover.Dropdown>
              </Popover>
            </>
          );
        },
      },

      { key: "created_at", name: "Registered at", isDate: true,hideSort: true, },
    ],
  };

  return (
    <div className="h-full p-4">
      <Card
        title="Chatbot Conversations"
        action={
          <>
            <div className="p-3">
              <div className="flex space-x-1">
                <Tooltip label="New">
                  <Button
                    classNames={{ label: "flex space-x-2" }}
                    className="bg-primary text-gray-200"
                    size="xs"
                    variant="default"
                    onClick={open}
                  >
                    <IconPlus />
                  </Button>
                </Tooltip>

                <Confirmation
                  type={"notify"}
                  message={
                    "Are you sure you want to download chatbot training file?"
                  }
                  onYes={() =>
                    downloadChatBotTraining().then((response: any) => {
                      if (response?.data) {
                        const jsonString = JSON.stringify(response?.data);
                        // console.log("response", response);
                        const data = new Blob([jsonString], {
                          type: "application/json",
                        });
                        FileSaver.saveAs(data, "training_file" + ".json");
                      }
                    })
                  }
                  header={`Downlad Chatbot Training File Confirmation `}
                >
                  <Tooltip label="Download chatbot training file">
                    <Button
                      classNames={{ label: "flex space-x-1 " }}
                      variant="default"
                      className="bg-primary  text-gray-200"
                      type="button"
                      size="xs"
                      loading={downloadresponse?.isLoading}
                    >
                      <IconDownload />
                    </Button>
                  </Tooltip>
                </Confirmation>

                <UploadTrainingFile />

                <Confirmation
                  type={"notify"}
                  message={"Are you sure you want to train chatbot?"}
                  onYes={() =>
                    trainChatBot().then((response: any) => {
                      if (response?.data) {
                        //
                      }
                    })
                  }
                  header={`Train Chatbot Confirmation `}
                >
                  <Tooltip label="Train chatbot">
                    <Button
                      classNames={{ label: "flex space-x-1 " }}
                      variant="default"
                      className="bg-primary text-gray-200"
                      type="button"
                      size="xs"
                      loading={trainChatBotResponse?.isLoading}
                    >
                      <IconChalkboard />
                    </Button>
                  </Tooltip>
                </Confirmation>
              </div>
            </div>
          </>
        }
      >
        <EntityList
          showExport={false}
          viewMode={viewMode}
          header={<></>}
          showArchivedCheckBox={false}
          title={"Chatbot Conversations"}
          total={chatBots?.data?.count}
          itemsLoading={chatBots.isLoading || chatBots?.isFetching}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          showSelector={false}
          onArchivedChecked={(e) =>
            setCollection({
              ...collection,
              withArchived: e,
            })
          }
          onPaginationChange={(offset: number, limit: number) => {
            setCollection({ ...collection, offset: offset, limit: limit });
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: ["question"],
            });
          }}
          onFilterChange={(data: any) => {
            if (collection?.filter || data.length > 0) {
              setCollection({ ...collection, filter: data });
            }
          }}
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
      </Card>
      <Modal
        opened={opened}
        onClose={close}
        // opened={openNotificationModal}
        // onClose={() => {
        //   setOpenNotificationModal(false);

        //   // reset(defaultValue);
        // }}
        className="my-modal-content"
        withCloseButton={false}
        // title="New "
        padding={20}
        size={"xl"}
      >
        <NewChatBotComponent editMode="new" close={close} />
      </Modal>
    </div>
  );
}
