/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import GroupAssignment from "../../../models/group-assignment.model";
import { Bank, Provider } from "../../../models/provider.model";
import { Vehicle } from "../../../models/vehicle.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { PROVIDER_ENDPOINT } from "../provider.endpoint";

let providerCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let bankAccountCollection: CollectionQuery;
let archivedBankAccountCollection: CollectionQuery;
let kidAssignmentCollection: CollectionQuery;
let providerId: string;

const providerQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getProviders: builder.query<Collection<Provider>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      // providesTags: ["provider"],
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            providerCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getChapaBanks: builder.query<any, any>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.chappa_bank_list}`,
        method: "GET",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getProviderKidAssignment: builder.query<
      Collection<GroupAssignment>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.getProviderKidAssignment}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          kidAssignmentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createProvider: builder.mutation<Provider, Provider>({
      query: (data: Provider) => ({
        url: `${PROVIDER_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permissions: "manage-drivers",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              providerQuery.util.updateQueryData(
                "getProviders",
                providerCollection,
                (draft) => {
                  draft.data.unshift(data);
                  draft.count += 1;
                }
              )
            );
            notification("success", "Successfully created");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteProvider: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permissions: "manage-drivers",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProviders",
              providerCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (provider) => provider.id !== id
                );
                draft.count -= 1;
              }
            )
          );
          dispatch(
            providerQuery.util.updateQueryData(
              "getArchivedProviders",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (provider) => provider.id !== id
                );
                draft.count -= 1;
              }
            )
          );
          notification("success", "Successfully deleted");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getProvider: builder.query<Provider, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.provider}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          providerId = id;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedProvider: builder.query<Provider, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.archived_provider}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateProvider: builder.mutation<Provider, Provider>({
      query: (data: Provider) => ({
        url: `${PROVIDER_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permissions: "manage-drivers",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(
              providerQuery.util.updateQueryData(
                "getProviders",
                providerCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveProvider: builder.mutation<Provider, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${PROVIDER_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permissions: "manage-drivers",
      }),
      // invalidatesTags: ["provider"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProviders",
              providerCollection,
              (draft) => {
                draft.data = draft?.data?.map((provider) => {
                  if (provider.id === arg.id) {
                    return data?.data;
                  } else {
                    return provider;
                  }
                });
              }
            )
          );
          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt;
              }
            )
          );
          dispatch(
            providerQuery.util.updateQueryData(
              "getArchivedProvider",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedProviders: builder.query<Collection<Provider>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.archived_providers}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    restoreProvider: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.restore_provider}/${id}`,
        method: "POST",
        permissions: "manage-drivers",
      }),
      // invalidatesTags: ["provider"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            providerQuery.util.updateQueryData(
              "getProviders",
              providerCollection,
              (draft) => {
                draft.data = draft?.data?.map((provider) => {
                  if (provider.id === id) {
                    return data;
                  } else {
                    return provider;
                  }
                });
              }
            )
          );
          dispatch(
            providerQuery.util.updateQueryData("getProvider", id, (draft) => {
              // draft.deletedAt = data?.deletedAt;
              if (data) {
                Object.assign(draft, data);
              }
            })
          );
          dispatch(
            providerQuery.util.updateQueryData(
              "getArchivedProvider",
              id,
              (draft) => {
                if (data) {
                  Object.assign(draft, data);
                }
                // draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    activateProvider: builder.mutation<Provider, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.activate}/${id}`,
        method: "POST",
        permissions: "manage-drivers",
      }),
      // invalidatesTags: ["provider"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProviders",
              providerCollection,
              (draft) => {
                draft.data = draft.data.map((provider: Provider) => {
                  if (provider.id === data.id) {
                    const returnData = { ...provider, enabled: data.enabled };
                    return returnData;
                  }
                  return provider;
                });
              }
            )
          );
          dispatch(
            providerQuery.util.updateQueryData("getProvider", id, (draft) => {
              draft.enabled = data.enabled;
            })
          );

          if (data.enabled) {
            notification("success", "Successfully activated");
          } else {
            notification("success", "Successfully deactivated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getBankAccounts: builder.query<Collection<Bank>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.bank_account_list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          bankAccountCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedBankAccounts: builder.query<Collection<Bank>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.archived_bank_account_list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedBankAccountCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createBankAccount: builder.mutation<Bank, Bank>({
      query: (data: Bank) => ({
        url: `${PROVIDER_ENDPOINT.create_bank_account}`,
        method: "POST",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                draft?.bankAccounts?.unshift(data);
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createSubAccount: builder.mutation<
      Bank,
      { driverId?: string; subAccountId: string }
    >({
      query: (data: { driverId?: string; subAccountId: string }) => ({
        url: `${PROVIDER_ENDPOINT.create_sub_account}`,
        method: "POST",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                draft?.bankAccounts?.unshift(data);
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteBankAccount: builder.mutation<boolean, any>({
      query: (data: { id: string; driverId: string }) => ({
        url: `${PROVIDER_ENDPOINT.remove_bank_account}`,
        method: "DELETE",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(
              providerQuery.util.updateQueryData(
                "getProvider",
                arg.driverId,
                (draft) => {
                  draft.bankAccounts = draft?.bankAccounts?.filter(
                    (account) => account.id !== arg.id
                  );
                }
              )
            );
            notification("success", "Successfully deleted");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateBankAccount: builder.mutation<Bank, Bank>({
      query: (data: Bank) => ({
        url: `${PROVIDER_ENDPOINT.update_bank_account}`,
        method: "PUT",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                draft.bankAccounts = draft?.bankAccounts?.map((account) => {
                  if (account.id === data.id) return data;
                  else {
                    return account;
                  }
                });
              }
            )
          );
          notification("success", "Successfully updated");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveBankAccount: builder.mutation<any, any>({
      query: (data: { id: string; driverId: string }) => ({
        url: `${PROVIDER_ENDPOINT.archive_bank_account}`,
        method: "DELETE",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notification("success", "Successfully archived");
          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                draft.bankAccounts = draft.bankAccounts?.filter(
                  (account) => account.id !== arg.id
                );
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getBankAccount: builder.query<Bank, string>({
      query: (id: string) => ({
        url: `${PROVIDER_ENDPOINT.bank}/${id}`,
        method: "GET",
      }),
    }),
    restoreBankAccount: builder.mutation<any, any>({
      query: (data: { id: string; driverId: string }) => ({
        url: `${PROVIDER_ENDPOINT.restore_bank_Account}`,
        method: "POST",
        data: data,
        permissions: "manage-bank-account",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                draft.bankAccounts?.unshift(data);
              }
            )
          );
          notification("success", "Successfully restored");
          dispatch(
            providerQuery.util.updateQueryData(
              "getArchivedBankAccounts",
              archivedBankAccountCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (account) => account.id !== arg.id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateProviderDocument: builder.mutation<Provider, any>({
      query: (data: any) => ({
        url: `${PROVIDER_ENDPOINT.update_document}`,
        method: "POST",
        data: data,
        permissions: "update-provider-document",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            providerQuery.util.updateQueryData(
              "getProvider",
              providerId,
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
          notification("success", "Successfully uploaded");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getUnassignedVehicles: builder.query<Collection<Vehicle>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PROVIDER_ENDPOINT.unassignedVehicles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          //
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetProviderQuery,
  useLazyGetProvidersQuery,
  useLazyGetProviderKidAssignmentQuery,
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
  useArchiveProviderMutation,
  useLazyGetArchivedProvidersQuery,
  useRestoreProviderMutation,
  useActivateProviderMutation,
  useLazyGetBankAccountsQuery,
  useLazyGetArchivedBankAccountsQuery,
  useCreateBankAccountMutation,

  useCreateSubAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useArchiveBankAccountMutation,
  useLazyGetBankAccountQuery,
  useRestoreBankAccountMutation,
  useUpdateProviderDocumentMutation,
  useLazyGetArchivedProviderQuery,
  useLazyGetUnassignedVehiclesQuery,

  useLazyGetChapaBanksQuery,
} = providerQuery;
