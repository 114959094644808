import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import {
  DriverFor,
  DriverType,
  Provider,
} from "../../../models/provider.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetProvidersQuery } from "../store/provider.query";
export default function ProviderPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getProviders, categories] = useLazyGetProvidersQuery();

  useEffect(() => {
    getProviders(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/provider/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Provider[] | undefined = categories.data?.data;
  const config: EntityConfig<Provider> = {
    primaryColumn: {
      key: "name",
      name: "Name",
      render: (provider) => {
        return (
          <Text size="sm" className={provider?.deletedAt ? "text-danger" : ""}>
            {provider.name}
          </Text>
        );
      },
    },

    rootUrl: "/registration/provider",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "driverFor",
        name: "Driver For",
        hideSort: true,
        render: (provider) => {
          return (
            <Text size="sm">
              {provider.driverFor === DriverFor.Both
                ? "Both"
                : provider.driverFor === DriverFor.KabbaKids
                ? "Kabba Kids"
                : provider.driverFor === DriverFor.KabbaMain
                ? "Kabba Public"
                : ""}
            </Text>
          );
        },
      },

      { key: "driverType", name: "Driver Type", hideSort: true },
      {
        key: "name",
        name: "Name",
        render: (provider) => {
          return (
            <Text
              size="sm"
              className={provider?.deletedAt ? "text-danger" : ""}
            >
              {provider.name}
            </Text>
          );
        },
      },
      { key: "phoneNumber", name: "Phone Number", hideSort: true },
      { key: "gender", name: "Gender" },
      {
        key: ["vehicle", "plateNumber"],
        name: "Vehicle",
        hideSort: true,
        render: (provider) => {
          if (provider?.vehicleId && !provider.vehicle) {
            return <span className="text-red-500">Vehicle is Archived</span>;
          } else if (provider?.vehicleId && provider.vehicle) {
            return (
              <span>{`${provider?.vehicle?.model} - ${provider?.vehicle?.plateNumber}`}</span>
            );
          }
        },
      },
      {
        key: "emergencyContact",
        name: "Emergency Contact",
        hideSort: true,
        render: (provider) => {
          return (
            <div className="flex space-x-1">
              <span>{provider?.emergencyContact?.name}</span>
              {"-"}
              <span>{provider?.emergencyContact?.phoneNumber}</span>
            </div>
          );
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "enabled", name: "Status", isBoolean: true, hideSort: true },
    ],
    filter: [
      [
        { field: "gender", value: "male", operator: "=", name: "Male" },
        { field: "gender", value: "female", operator: "=", name: "Female" },
      ],
      [
        { field: "enabled", value: true, operator: "=", name: "Active" },
        { field: "enabled", value: false, operator: "=", name: "Inactive" },
      ],
      [
        {
          field: "driverFor",
          value: DriverFor.Both,
          operator: "=",
          name: "Both",
        },
        {
          field: "driverFor",
          value: DriverFor.KabbaKids,
          operator: "=",
          name: "Kabba Kids",
        },
        {
          field: "driverFor",
          value: DriverFor.KabbaMain,
          operator: "=",
          name: "Kabba Public",
        },
      ],
      [
        {
          field: "driverType",
          value: DriverType.Both,
          operator: "=",
          name: "Both",
        },
        {
          field: "driverType",
          value: DriverType.OneTime,
          operator: "=",
          name: "One Time",
        },
        {
          field: "driverType",
          value: DriverType.Monthly,
          operator: "=",
          name: "Monthly",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Providers"}
        total={categories?.data?.count}
        itemsLoading={categories.isLoading || categories?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
