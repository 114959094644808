/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChatBot } from "../../../models/chatBot.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";

import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";

import { CHATBOT_ENDPOINT } from "../chat-bot.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const chatBotQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getChatBots: builder.query<Collection<ChatBot>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CHATBOT_ENDPOINT.list}`,
        method: "GET",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          if (error.status === 403) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        }
      },
    }),
    createChatBot: builder.mutation<ChatBot, ChatBot>({
      query: (data: ChatBot) => ({
        url: `${CHATBOT_ENDPOINT.create}`,
        method: "POST",
        data: data,
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            chatBotQuery.util.updateQueryData(
              "getChatBots",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteChatBot: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CHATBOT_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            chatBotQuery.util.updateQueryData(
              "getChatBots",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((chatBot) => chatBot.id !== id);
                draft.count -= 1;
              }
            )
          );
          // dispatch(
          //   chatBotQuery.util.updateQueryData(
          //     "getArchivedChatBots",
          //     archivedCollection,
          //     (draft) => {
          //       draft.data = draft.data.filter((chatBot) => chatBot.id !== id);
          //       draft.count -= 1;
          //     }
          //   )
          // );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getChatBot: builder.query<ChatBot, string>({
      query: (id: string) => ({
        url: `${CHATBOT_ENDPOINT.chatBot}/${id}`,
        method: "GET",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
      }),
    }),
    downloadChatBotTrainingData: builder.mutation<ChatBot, void>({
      query: () => ({
        url: `${CHATBOT_ENDPOINT.downloadFile}`,
        method: "GET",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // notification("success", "File downloaded successfully");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    uploadChatBotTrainingData: builder.mutation<ChatBot, any>({
      query: (data: any) => ({
        url: `${CHATBOT_ENDPOINT.uploadFile}`,
        method: "POST",
        data: data,
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "File uploaded successfully");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    trainChatBot: builder.mutation<ChatBot, void>({
      query: () => ({
        url: `${CHATBOT_ENDPOINT.train}`,
        method: "POST",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Training completed successfully");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    // getArchivedChatBot: builder.query<ChatBot, string>({
    //   query: (id: string) => ({
    //     url: `${CHATBOT_ENDPOINT.archived_chatBot}/${id}`,
    //     method: "GET",
    //   }),
    // }),
    updateChatBot: builder.mutation<ChatBot, ChatBot>({
      query: (data: ChatBot) => ({
        url: `${CHATBOT_ENDPOINT.update}/${data?.id}`,
        method: "PATCH",
        data: data,
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-chatBots",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            chatBotQuery.util.updateQueryData(
              "getChatBots",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((chatBot) => {
                  if (chatBot.id === data.id) {
                    return data;
                  } else {
                    return chatBot;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    // archiveChatBot: builder.mutation<ChatBot, any>({
    //   query: (data: { id: string; reason: string }) => ({
    //     url: `${CHATBOT_ENDPOINT.archive}`,
    //     method: "DELETE",
    //     data: data,
    //     permission: "manage-chatBots",
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       dispatch(
    //         chatBotQuery.util.updateQueryData(
    //           "getChatBots",
    //           currentCollection,
    //           (draft) => {
    //             draft.data = draft?.data?.map((chatBot) => {
    //               if (chatBot.id === arg.id) {
    //                 return data;
    //               } else {
    //                 return chatBot;
    //               }
    //             });
    //           }
    //         )
    //       );
    //       dispatch(
    //         chatBotQuery.util.updateQueryData(
    //           "getChatBot",
    //           arg?.id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       dispatch(
    //         chatBotQuery.util.updateQueryData(
    //           "getArchivedChatBot",
    //           arg?.id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       notification("success", "Successfully archived");
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "Error try again"
    //       );
    //     }
    //   },
    // }),
    // getArchivedChatBots: builder.query<Collection<ChatBot>, CollectionQuery>({
    //   query: (data: CollectionQuery) => ({
    //     url: `${CHATBOT_ENDPOINT.archived_chatBots}`,
    //     method: "GET",
    //     params: collectionQueryBuilder(data),
    //   }),
    //   async onQueryStarted(param, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       archivedCollection = param;
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "error try again"
    //       );
    //     }
    //   },
    // }),
    // restoreChatBot: builder.mutation<any, string>({
    //   query: (id: string) => ({
    //     url: `${CHATBOT_ENDPOINT.restore_chatBot}/${id}`,
    //     method: "POST",
    //     permission: "manage-chatBots",
    //   }),
    //   async onQueryStarted(id, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       dispatch(
    //         chatBotQuery.util.updateQueryData(
    //           "getChatBots",
    //           currentCollection,
    //           (draft) => {
    //             draft.data = draft?.data?.map((chatBot) => {
    //               if (chatBot.id === id) {
    //                 return data;
    //               } else {
    //                 return chatBot;
    //               }
    //             });
    //           }
    //         )
    //       );
    //       dispatch(
    //         chatBotQuery.util.updateQueryData("getChatBot", id, (draft) => {
    //           draft.deletedAt = data?.deletedAt;
    //         })
    //       );
    //       dispatch(
    //         chatBotQuery.util.updateQueryData(
    //           "getArchivedChatBot",
    //           id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       notification("success", "Successfully restored");
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "Error try again"
    //       );
    //     }
    //   },
    // }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetChatBotQuery,
  useLazyGetChatBotsQuery,
  useCreateChatBotMutation,
  useDeleteChatBotMutation,
  useUpdateChatBotMutation,
  useDownloadChatBotTrainingDataMutation,
  useUploadChatBotTrainingDataMutation,
  useTrainChatBotMutation,
} = chatBotQuery;
