import { IconCurrentLocation } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { useCurrentUser } from "../../../shared/hooks/current-user.hook";
import { createSocket } from "../../../shared/utility/socket";

import CustomMarkerComponent from "./custom-marker.component";

export default function LiveTrackingComponent({
  setSelectedDriver,
  location,
  setLocation,
  open,
  trackedDriversData,
}: any) {
  const [trackedDrivers, setTrackedDrivers] = useState<any>();

  useEffect(() => {
    if (trackedDriversData && trackedDriversData?.length > 0) {
      setTrackedDrivers(trackedDriversData);
    }
  }, [trackedDriversData]);

  const [userInfo, parentTransportId] = useCurrentUser();
  function RecenterMap({ center }: { center: L.LatLngExpression }) {
    // const dispatch = useDispatch()
    const map = useMap();
    function recenter() {
      map.setView(center, map.getZoom(), { animate: true, duration: 1 });
    }
    useEffect(() => {
      recenter();
    }, [map, center]);
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "15px",
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              recenter();
            }}
          >
            <IconCurrentLocation />
          </button>
        </div>
      </>
    );
  }

  useEffect(() => {
    const newSocket = createSocket(userInfo?.id as string);
    newSocket.on("locationUpdate", (data: any) => {
      setTrackedDrivers((prev: any) => {
        // if(prev?.length > 0) {

        // }
        let newArr = prev?.map((assignment: any) => {
          if (assignment?.driver?.id == data?.id) {
            return {
              ...assignment,
              driver: {
                ...assignment.driver,
                lat: data?.lat,
                lng: data?.lng,
              },
            };
          } else {
            return assignment;
          }
        });
        // console.log("newArr", newArr);
        return newArr;
      });
    });
  }, []);

  return (
    <div className="flex w-full basis-[70%] h-[100vh]">
      <MapContainer
        center={location}
        zoom={13}
        // key={location}
        scrollWheelZoom={false}
        style={{ height: "100vh", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Array.isArray(trackedDrivers) &&
          trackedDrivers?.map((assignment: any, i: number) => {
            return (
              <CustomMarkerComponent
                key={(assignment?.driver?.id as string) + i}
                position={[
                  (assignment?.driver?.lat as number) ?? 0,
                  (assignment?.driver?.lng as number) ?? 0,
                ]}
                handleClick={(e: any) => {
                  // e.preventDefault();
                  setSelectedDriver(assignment);
                  open();
                }}
              >
                <></>
              </CustomMarkerComponent>
            );
            // } else {
            //     return <></>
            // }
          })}

        {/* {trackDriverResponse?.data?.data?.map((route: any) => (
             <CustomMarkerComponent key={route?.vehicle?.driver?.id as string} position={[route?.vehicle?.driver?.lat as number, route?.vehicle?.driver?.lng as number]} handleClick={() => {
              setSelectedDriver(route?.vehicle?.driver);
              open();
            }}
            >
              <></>
            </CustomMarkerComponent>
          ))} */}
        <RecenterMap center={location} />
      </MapContainer>
    </div>
  );
}
