import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Switch,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconMinus,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { City } from "../../../models/city.model";
import { Route } from "../../../models/route.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import SelectItem from "../../../shared/component/select-item-wrapper/SelectItem";
import {
  afternoonTimes,
  Constants,
  morningTimes,
} from "../../../shared/constants/Constants";
import { pickupTimeType } from "../../../shared/utility/enum/pickupTypeTimes.enum";
import {
  useLazyGetCitiesQuery,
  useLazyGetCityBusStopsQuery,
} from "../../city/store/city.query";
import {
  useActivateOrBlockRouteMutation,
  useAddRouteStationsMutation,
  useArchiveRouteMutation,
  useCreateRouteMutation,
  useDeleteRouteMutation,
  useLazyGetArchivedRouteQuery,
  useLazyGetRouteQuery,
  useRestoreRouteMutation,
  useUpdateRouteMutation,
  useUpdateRouteTimeMutation,
} from "../store/route.query";
import PriceComponent from "../sub-component/price-component";
import StationComponent from "../sub-component/station-component";

export interface Props {
  editMode: string;
}

export default function NewRouteComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const defaultValue: Route = {
    id: "",
    name: "",
    description: "",
    cityId: "",
    totalDistance: 0,
    isActive: true,
    isVisible: true,
    difficultyLevel: "",
    trafficJamLevel: "",
    deletedBy: "",
    city: undefined,
    shift: pickupTimeType.Both,
    morningPickupTime: [""],
    afternoonPickupTime: [""],
  };

  let schema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Name should have at least 5 letters")
      .required("Name is required"),
    description: Yup.string().required("Description is required"),
    totalDistance: Yup.number()
      .min(0, "Total distance must be 0 or greater")
      .default(0),
    difficultyLevel: Yup.string().required("Road difficulty level is required"),
    trafficJamLevel: Yup.string().required("Traffic Jam level is required"),
    cityId: Yup.string().required("City is required"),
    shift: Yup.string().required("Shift is required"),
    morningPickupTime: Yup.array()
      .of(Yup.string().nullable())
      .nullable()
      .when("shift", {
        is: (value: string) =>
          value === pickupTimeType.Both || value === pickupTimeType.Morning,
        then: Yup.array()
          .of(Yup.string().required("Time is required"))
          .min(1, "At least one morning pickup time is required"),
      }),
    afternoonPickupTime: Yup.array()
      .of(Yup.string().nullable())
      .nullable()
      .when("shift", {
        is: (value: string) =>
          value === pickupTimeType.Both || value === pickupTimeType.Afternoon,
        then: Yup.array()
          .of(Yup.string().required("Time is required"))
          .min(1, "At least one afternoon pickup time is required"),
      }),
  });
  const [timeValues, setTimeValues] = useState<{ [key: string]: string[] }>({
    morningPickupTime: [],
    afternoonPickupTime: [],
  });
  const [currentRoute, setCurrentRoute] = useState<Route>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [citySelection, setCitySelection] = useState<string>("");
  const [, setRouteId] = useState("");
  const [majorChange, setMajorChange] = useState(false);
  const [tab, setTab] = useState("main");
  const [getCities, cities] = useLazyGetCitiesQuery();
  const [getCityBusStops, cityBusStops] = useLazyGetCityBusStopsQuery();

  const [getRoute, route] = useLazyGetRouteQuery();
  const [getArchivedRoute, archivedRoute] = useLazyGetArchivedRouteQuery();
  const [createRoute, newRoute] = useCreateRouteMutation();
  const [updateRoute, updateResponse] = useUpdateRouteMutation();
  const [archiveRoute, archiveResponse] = useArchiveRouteMutation();
  const [activateRoute, activateResponse] = useActivateOrBlockRouteMutation();
  const [addStations] = useAddRouteStationsMutation();
  const [restoreRoute, restoreCall] = useRestoreRouteMutation();
  const [deleteRoute, deleteCall] = useDeleteRouteMutation();
  const [updateRouteTime, updateTimeResponse] = useUpdateRouteTimeMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Route>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const morningPickupArray = useFieldArray({
    control,
    //@ts-ignore
    name: "morningPickupTime",
  });

  const afternoonPickupArray = useFieldArray({
    control,
    //@ts-ignore
    name: "afternoonPickupTime",
  });

  const levels = [
    { label: "Easy", value: "easy" },
    { label: "Moderate", value: "moderate" },
    { label: "Difficult", value: "hard" },
    { label: "Extreme", value: "extreme" },
  ];
  const pickupTimeTypes = [
    { label: "Both", value: pickupTimeType.Both },
    { label: "Morning", value: pickupTimeType.Morning },
    { label: "Afternoon", value: pickupTimeType.Afternoon },
  ];

  const selectedTimeType = watch("shift");
  const isVisible = watch("isVisible");
  const formatTime = (time: string) => {
    const date = new Date(`1970-01-01T${time}:00Z`);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };
  useEffect(() => {
    if (citySelection) {
      getCityBusStops({
        query: {
          skip: 0,
          top: 100,
          filter: [[{ field: "isActive", value: true, operator: "=" }]],
        },
        CityId: citySelection,
      })
        .then(() => {
          if (editMode === "detail") {
            reset(route.currentData);
          }
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [getCityBusStops, citySelection]);

  useEffect(() => {
    setCitySelection("");
    setIsLoading(true);
    getCities({
      skip: 0,
      top: 100,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
    })
      .then((response: any) => {
        if (response?.data) {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
    if (editMode === "detail") {
      if (params?.type === "archived") {
        getArchivedRoute(`${params?.id}`).then((response: any) => {
          setCurrentRoute(response?.data);
          const newMorningTimes =
            response?.data?.morningPickupTime?.length > 0
              ? response?.data.morningPickupTime
              : [""];
          const newAfternoonTimes =
            response?.data?.afternoonPickupTime?.length > 0
              ? response?.data.afternoonPickupTime
              : [""];

          reset({
            ...response.data,
            afternoonPickupTime: newAfternoonTimes,
            morningPickupTime: newMorningTimes,
          });
          setCitySelection(response.data.cityId);
          setRouteId(response.data.id);
          setIsLoading(false);
        });
      } else {
        getRoute(`${params?.id}`).then((response: any) => {
          setCurrentRoute(response?.data);
          const newMorningTimes =
            response?.data?.morningPickupTime?.length > 0
              ? response?.data.morningPickupTime
              : [""];
          const newAfternoonTimes =
            response?.data?.afternoonPickupTime?.length > 0
              ? response?.data.afternoonPickupTime
              : [""];

          reset({
            ...response.data,
            afternoonPickupTime: newAfternoonTimes,
            morningPickupTime: newMorningTimes,
          });
          setCitySelection(response?.data?.cityId);
          setRouteId(response.data?.id);
          setIsLoading(false);
        });
      }
    } else {
      reset(defaultValue);
    }
  }, [params.id, editMode]);
  // console.log("currentRoutes: ", currentRoute);
  useEffect(() => {
    const s = citySelection !== route?.currentData?.cityId;
    setMajorChange(s);
  }, [citySelection]);

  // In your component
  useEffect(() => {
    if (morningPickupArray.fields.length === 0) {
      morningPickupArray.append("");
    }
  }, [morningPickupArray.fields.length]);

  useEffect(() => {
    if (afternoonPickupArray.fields.length === 0) {
      afternoonPickupArray.append("");
    }
  }, [afternoonPickupArray.fields.length]);

  const handleTimeChange =
    (index: number, type: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedTime = event.target.value;
      if (type === "morningPickupTime") {
        updateRouteTime({
          routeId: `${params.id}`,
          shift: `${selectedTimeType}`,
          morningPickupTime: updatedTime,
          oldMorningPickupTime: currentRoute?.morningPickupTime?.[index] ?? "",
          index: index,
        })
          .then((response: any) => {
            if (response?.data) {
              setCurrentRoute(response?.data);
            }
          })
          .catch((error) => {
            console.error("Error sending to backend", error);
          });
      }
      if (type === "afternoonPickupTime") {
        updateRouteTime({
          routeId: `${params.id}`,
          shift: `${selectedTimeType}`,
          afternoonPickupTime: updatedTime,
          oldAfternoonPickupTime:
            currentRoute?.afternoonPickupTime?.[index] ?? "",
          index: index,
        })
          .then((response: any) => {
            if (response?.data) {
              setCurrentRoute(response?.data);
            }
          })
          .catch((error) => {
            // console.error("Error sending to backend", error);
          });
      }
    };
  const handleTimeRemoval = (index: number, type: string) => {
    if (type === "morningPickupTime") {
      updateRouteTime({
        routeId: `${params.id}`,
        shift: `${selectedTimeType}`,
        oldMorningPickupTime: currentRoute?.morningPickupTime?.[index] ?? "",
        index: index,
      })
        .then((response: any) => {
          if (response?.data) {
            setCurrentRoute(response?.data);
          }
        })
        .catch((error) => {
          console.error("Error sending to backend", error);
        });
    }
    if (type === "afternoonPickupTime") {
      updateRouteTime({
        routeId: `${params.id}`,
        shift: `${selectedTimeType}`,
        oldAfternoonPickupTime:
          currentRoute?.afternoonPickupTime?.[index] ?? "",
        index: index,
      })
        .then((response: any) => {
          if (response?.data) {
            setCurrentRoute(response?.data);
          }
        })
        .catch((error) => {
          // console.error("Error sending to backend", error);
        });
    }
  };
  function onSubmit(data: Route) {
    //  const morningPickupTime = data?.morningPickupTime
    //    ?.filter((time: string) => time !== "")
    //    .map((time: string) => formatTime(time));
    //  const afternoonPickupTime = data?.afternoonPickupTime
    //    ?.filter((time: string) => time !== "")
    //    .map((time: string) => formatTime(time));
    const morningPickupTime = data?.morningPickupTime?.filter(
      (time: string) => time !== ""
    );
    const afternoonPickupTime = data?.afternoonPickupTime?.filter(
      (time: string) => time !== ""
    );
    if (selectedTimeType === pickupTimeType.Morning) {
      data.morningPickupTime = morningPickupTime;
      data.afternoonPickupTime = [];
    } else if (selectedTimeType === pickupTimeType.Afternoon) {
      data.morningPickupTime = [];
      data.afternoonPickupTime = afternoonPickupTime;
    } else {
      data.morningPickupTime = morningPickupTime;
      data.afternoonPickupTime = afternoonPickupTime;
    }
    // console.log("data", data);
    if (editMode === "new") {
      createRoute(data).then((response: any) => {
        if (!response?.error) {
          reset(defaultValue);
        }
      });
    } else {
      const _route: Route = {
        id: params.id,
        ...data,
      };
      updateRoute(_route).then((result) => {
        if (majorChange) {
          addStations({
            routeId: route?.currentData?.id,
            stations: [],
          }).then(() => {
            getRoute(`${params?.id}`).then((response: any) => {
              setCitySelection(response.data.cityId);
              setRouteId(response.data.id);
            });
          });
        }
      });
    }
  }

  const onError = (error: any) => {
    console.log("Error", error);
  };
  useEffect(() => {
    if (editMode === "detail") {
      const debouncedHandleTimeChange = debounce(
        (index: number, value: string, type: string) => {
          // const formattedTime = formatTime(value);
          const simulatedEvent = {
            target: { value: value },
          } as React.ChangeEvent<HTMLInputElement>;

          handleTimeChange(index, type)(simulatedEvent);
        },
        4000
      );

      Object.keys(timeValues).forEach((type) => {
        timeValues[type].forEach((value, index) => {
          debouncedHandleTimeChange(index, value, type);
        });
      });

      return () => {
        debouncedHandleTimeChange.cancel();
      };
    }
  }, [timeValues, editMode]);
  return (
    <div className="relative flex justify-center w-full h-full space-x-2 dark:bg-gray-700 dark:text-white">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Route" : route?.data?.name ?? ""}
        item={route}
        path={`/settings-and-configurables/route/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" && (isLoading || updateResponse.isLoading)
          }
        />

        <Tabs
          defaultValue={"main"}
          value={tab}
          onTabChange={(val) => setTab(val ?? "main")}
        >
          <Tabs.List>
            <Tabs.Tab value="main">Route Information</Tabs.Tab>

            {editMode === "detail" && (
              <Tabs.Tab value="stations">Route Stations</Tabs.Tab>
            )}
            {editMode === "detail" && (
              <Tabs.Tab value="prices">Route Prices</Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full mb-2 space-x-4">
                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.name && `${errors?.name?.message}`}
                      withAsterisk
                      label="Name"
                      placeholder="Name"
                      {...register("name")}
                    />
                    <Switch
                      className="w-full mt-8"
                      {...register("isVisible")}
                      checked={isVisible}
                      label="Is the route visible?"
                    />
                  </div>
                </div>

                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full mb-2 space-x-4">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <Textarea
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          error={
                            errors.description &&
                            `${errors?.description?.message}`
                          }
                          withAsterisk
                          label="Description"
                          placeholder="Description"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full mb-2 space-x-4">
                    <Controller
                      name="cityId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="w-full"
                          searchable
                          withAsterisk
                          itemComponent={SelectItem(cities.isFetching)}
                          error={errors.cityId && `${errors?.cityId?.message}`}
                          label="City"
                          placeholder={"Select city"}
                          data={
                            cities.data
                              ? cities.data.data.map((item: City) => ({
                                  label: `${item.name}`,
                                  value: item.id ?? "",
                                }))
                              : []
                          }
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="shift"
                      render={({ field }) => (
                        <Select
                          withAsterisk
                          className="w-full"
                          error={errors.shift && `${errors?.shift?.message}`}
                          label="Shift"
                          placeholder="Select shift"
                          data={pickupTimeTypes}
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full mb-2 space-x-4">
                    <Controller
                      control={control}
                      name="difficultyLevel"
                      render={({ field }) => (
                        <Select
                          withAsterisk
                          itemComponent={SelectItem(cityBusStops.isFetching)}
                          className="w-full"
                          error={
                            errors.difficultyLevel &&
                            `${errors?.difficultyLevel?.message}`
                          }
                          label="Road Difficulty Level"
                          placeholder="Road Difficulty Level"
                          data={levels}
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="trafficJamLevel"
                      render={({ field }) => (
                        <Select
                          withAsterisk
                          className="w-full"
                          label="Traffic Jam Level"
                          placeholder="Traffic Jam Level"
                          error={
                            errors.trafficJamLevel &&
                            `${errors?.trafficJamLevel?.message}`
                          }
                          data={levels}
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="flex justify-between w-full mb-2 space-x-4">
                    <LoadingOverlay
                      visible={updateTimeResponse?.isLoading}
                      loaderProps={{ children: "Updating..." }}
                      zIndex={1000}
                    />
                    {(selectedTimeType === pickupTimeType.Both ||
                      selectedTimeType === pickupTimeType.Morning) && (
                      <div className="flex-col space-y-3 ">
                        {/* {morningPickupArray?.fields?.length === 0 &&
                          morningPickupArray?.append("")} */}
                        {morningPickupArray.fields.map((field, index) => (
                          <div
                            key={field.id}
                            className="flex items-center mb-4 space-x-2"
                          >
                            <Controller
                              control={control}
                              name={`morningPickupTime.${index}`}
                              render={({ field }) => (
                                <Select
                                  withAsterisk
                                  className="w-full"
                                  label={`Morning Pickup Time ${index + 1}`}
                                  placeholder="Morning pickup time"
                                  error={
                                    errors?.morningPickupTime?.[index]?.message
                                  }
                                  data={morningTimes}
                                  maxDropdownHeight={400}
                                  {...field}
                                  onChange={(value) => {
                                    if (editMode === "detail") {
                                      const updatedTimes = [
                                        ...timeValues.morningPickupTime,
                                      ];
                                      updatedTimes[index] = value ?? ""; // Update the specific index with the selected value
                                      setTimeValues((prevValues) => ({
                                        ...prevValues,
                                        morningPickupTime: updatedTimes,
                                      }));
                                    }

                                    // Ensure the field value in React Hook Form gets updated
                                    field.onChange(value);
                                  }}
                                />
                              )}
                            />

                            <Tooltip label="Remove">
                              <Button
                                size="xs"
                                disabled={
                                  morningPickupArray?.fields?.length === 1
                                }
                                type="button"
                                onClick={() => {
                                  morningPickupArray.remove(index);
                                  if (editMode === "detail") {
                                    handleTimeRemoval(
                                      index,
                                      "morningPickupTime"
                                    );
                                  }
                                }}
                                className="mt-5"
                              >
                                <IconMinus size={"sm"} />
                              </Button>
                            </Tooltip>
                            <Tooltip label="Add Morning Pickup Time">
                              <Button
                                size="xs"
                                type="button"
                                onClick={() => morningPickupArray.append("")}
                                className="mt-5"
                              >
                                <IconPlus size={"sm"} />
                              </Button>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    )}

                    {(selectedTimeType === pickupTimeType.Both ||
                      selectedTimeType === pickupTimeType.Afternoon) && (
                      <div className="flex-col space-y-3 ">
                        {" "}
                        {afternoonPickupArray.fields.map((field, index) => (
                          <>
                            <div
                              key={field.id}
                              className="flex items-center mb-4 space-x-2"
                            >
                              <Controller
                                control={control}
                                name={`afternoonPickupTime.${index}`}
                                render={({ field }) => (
                                  <Select
                                    withAsterisk
                                    className="w-full"
                                    label={`Afternoon Pickup Time ${index + 1}`}
                                    placeholder="Afternoon pickup time"
                                    error={
                                      errors?.afternoonPickupTime?.[index]
                                        ?.message
                                    }
                                    data={afternoonTimes}
                                    maxDropdownHeight={400}
                                    {...field}
                                    onChange={(value) => {
                                      if (editMode === "detail") {
                                        const updatedTimes = [
                                          ...timeValues.afternoonPickupTime,
                                        ];
                                        updatedTimes[index] = value ?? ""; // Update the specific index with the selected value
                                        setTimeValues((prevValues) => ({
                                          ...prevValues,
                                          afternoonPickupTime: updatedTimes,
                                        }));
                                      }

                                      // Ensure the field value in React Hook Form gets updated
                                      field.onChange(value);
                                    }}
                                  />
                                )}
                              />
                              {/* <Select
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors?.afternoonPickupTime?.[index]?.message
                                }
                                withAsterisk
                                label={`Afternoon Pickup Time ${index + 1}`}
                                placeholder="Afternoon pickup time"
                                data={afternoonTimes}
                                {...register(`afternoonPickupTime.${index}`, {
                                  required: "Time is required",
                                  ...(editMode === "detail" && {
                                    onChange: (e) => {
                                      const updatedTimes = [
                                        ...timeValues.afternoonPickupTime,
                                      ];
                                      updatedTimes[index] = e.target.value;
                                      setTimeValues((prevValues) => ({
                                        ...prevValues,
                                        afternoonPickupTime: updatedTimes,
                                      }));
                                    },
                                  }),
                                })}
                              /> */}
                              <Tooltip label="Remove">
                                <Button
                                  size="xs"
                                  type="button"
                                  disabled={
                                    afternoonPickupArray?.fields?.length === 1
                                  }
                                  onClick={() => {
                                    afternoonPickupArray.remove(index);
                                    handleTimeRemoval(
                                      index,
                                      "afternoonPickupTime"
                                    );
                                  }}
                                  className="mt-5"
                                >
                                  <IconMinus size={"sm"} />
                                </Button>
                              </Tooltip>
                              <Tooltip label=" Add Afternoon Pickup Time">
                                <Button
                                  size="xs"
                                  type="button"
                                  onClick={() =>
                                    afternoonPickupArray.append("")
                                  }
                                  className="mt-5"
                                >
                                  <IconPlus size={"sm"} />
                                </Button>
                              </Tooltip>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-end w-full">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                          onClick={() => {
                            reset(defaultValue);
                            setCitySelection("");
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 fill-current"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="text-white bg-primary"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={newRoute.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {(params?.type === "archived" &&
                          archivedRoute?.data?.deletedAt) ||
                        (params?.type === "active" &&
                          route?.data?.deletedAt) ? (
                          <>
                            <Confirmation
                              type={"danger"}
                              message={
                                "Are you sure you want to delete it permanently?"
                              }
                              onYes={() =>
                                deleteRoute(`${params?.id}`).then(
                                  (response: any) => {
                                    if (response?.data) {
                                      navigate(-1);
                                    }
                                  }
                                )
                              }
                              header={`Permanent Delete confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={deleteCall.isLoading}
                                className={`bg-danger text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconTrash size={20} strokeWidth={1.5} />
                                <span>Delete</span>{" "}
                              </Button>
                            </Confirmation>
                            <Confirmation
                              type={"notify"}
                              message={`Are you sure you want to restore it`}
                              onYes={() => {
                                restoreRoute(`${params?.id}`);
                              }}
                              header={`Restore confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={restoreCall.isLoading}
                                className={`bg-green-500 text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconArrowBackUp size={20} strokeWidth={1.5} />
                                <span>Restore</span>
                              </Button>
                            </Confirmation>
                          </>
                        ) : (
                          <>
                            <Confirmation
                              type={"notify"}
                              message={
                                "Are you sure you want to archive this route?"
                              }
                              header={"Archive Confirmation"}
                              result={"single"}
                              resultRequired={true}
                              selectorLabel={"Please select a reason"}
                              labelDescription={
                                "Why are you archiving this route?"
                              }
                              options={Constants.ArchiveReason.map((reason) => {
                                return { label: reason, value: reason };
                              })}
                              customInput={true}
                              yesText={"Archive"}
                              onYes={(data: string) => {
                                archiveRoute({
                                  id: `${params.id}`,
                                  reason: data,
                                });
                              }}
                            >
                              <Button
                                variant="filled"
                                loading={archiveResponse.isLoading}
                                className="text-white bg-danger"
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <span>
                                  <IconArchive size={22} strokeWidth={1.5} />
                                </span>
                                <span>Archive</span>
                              </Button>
                            </Confirmation>

                            <Confirmation
                              type={"notify"}
                              message={
                                "Are you sure you want to " +
                                (route.data?.isActive
                                  ? "deactivate"
                                  : "activate") +
                                " it?"
                              }
                              onYes={() =>
                                schema.validate(route.data).then((data) => {
                                  //@ts-ignore
                                  const _route: Route = {
                                    ...data,
                                    isActive: data.isActive,
                                  };
                                  activateRoute(route?.data?.id ?? "");
                                })
                              }
                              header={
                                (route.data?.isActive
                                  ? "Deactivate"
                                  : "Activate") + " Confirmation"
                              }
                            >
                              <Button
                                variant="filled"
                                loading={activateResponse.isLoading}
                                className={`${
                                  route.data?.isActive
                                    ? " bg-danger"
                                    : "bg-green-500"
                                } text-white`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 fill-current"
                                    viewBox="0 0 48 48"
                                  >
                                    <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                  </svg>
                                </span>
                                {route.data?.isActive ? (
                                  <span>Deactivate</span>
                                ) : (
                                  <span>Activate</span>
                                )}
                              </Button>
                            </Confirmation>

                            <Confirmation
                              type={"danger"}
                              size={"lg"}
                              yesText={"Continue"}
                              message={
                                <Stack align={"center"} pt={10}>
                                  <Text fw={400}>
                                    {" "}
                                    Change that can affect current route
                                    stations have been made!
                                  </Text>
                                  <Text fw={400}>
                                    {" "}
                                    If you proceed, the current route stations
                                    assignment will be reset!
                                  </Text>
                                  <Text fw={500}>
                                    {" "}
                                    Do you want to continue?
                                  </Text>
                                </Stack>
                              }
                              optionalConfirmation={true}
                              optionalStatus={majorChange}
                              onYes={() => {
                                handleSubmit(onSubmit)();
                              }}
                              header="Update Confirmation"
                            >
                              <Button
                                variant="filled"
                                className="text-white bg-primary"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                                loading={updateResponse.isLoading}
                              >
                                <span>
                                  <IconDeviceFloppy
                                    size={22}
                                    strokeWidth={1.5}
                                  />
                                </span>
                                <span>Update</span>
                              </Button>
                            </Confirmation>
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          {editMode === "detail" && tab === "stations" && (
            <Tabs.Panel value="stations" pt="xs">
              <StationComponent route={route.currentData} />
            </Tabs.Panel>
          )}

          {editMode === "detail" && tab === "prices" && (
            <Tabs.Panel value="prices" pt="xs">
              <PriceComponent route={route.currentData} />
            </Tabs.Panel>
          )}
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}
