import {
  Button,
  Card,
  Checkbox,
  Input,
  LoadingOverlay,
  Modal,
  Popover,
  Table,
} from "@mantine/core";
import {
  IconArrowsDownUp,
  IconChevronDown,
  IconFilter,
  IconSearch,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import EmptyIcon from "../../empty-icon/empty-icon";
import { CollectionSelectorConfig } from "../model/collection-selector-config";
/* eslint-disable-next-line */
interface CollectionSelectorProps {
  config?: CollectionSelectorConfig;
  items?: any[];
  total?: number | undefined;
  collectionQuery?: any;
  itemsLoading?: boolean;
  title?: string;
  filter?: any;
  order?: any;
  hasSort?: boolean;
  paginationChange?: any;
  buttonLoading?: boolean;
  modalOpened: boolean;
  showFilterButton?: boolean;
  onPaginationChange(skip: number, top: number): void;
  setModalOpened(event: boolean): void;
  search: (event: any) => void;
  onDone?: (event: any) => void;
  selectedRows?: any[];
}

export function ModalCollectionSelector(props: CollectionSelectorProps) {
  const [items, setItems] = useState(props.items);
  const [opened, setOpened] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>(
    props?.selectedRows ? props?.selectedRows : []
  );
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [order, setOrder] = useState("ASC");

  useEffect(() => {
    if (props?.selectedRows) {
      setCheckedItems(props?.selectedRows);
    }
  }, [props.selectedRows]);

  const checkAll = (event: any) => {
    if (event.target.checked) {
      setCheckedItems(items ? items : []);
      setIsCheckAll(true);
    } else {
      setCheckedItems([]);
      setIsCheckAll(false);
    }
  };

  const handleCheckbox = (event: any, element: any) => {
    if (event.target.checked) {
      setCheckedItems((prev) => [...prev, element]);
    } else {
      const checked = checkedItems.filter((e) => e.id !== element.id);
      setCheckedItems(checked);
      setIsCheckAll(false);
    }
  };

  const sorting = (column: any, type: string) => {
    if (order === "ASC" && items) {
      const sorted = [...items].sort((a, b) =>
        a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1
      );
      setItems(sorted);
      setOrder("DEC");
    }
    if (order === "DEC" && items) {
      const sorted = [...items].sort((a, b) =>
        b[column].toLowerCase() > a[column].toLowerCase() ? 1 : -1
      );
      setItems(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    checkedItems?.length === items?.length
      ? setIsCheckAll(true)
      : setIsCheckAll(false);
  }, [checkAll, handleCheckbox]);

  useEffect(() => {
    setItems(props.items);
  }, [props]);

  const onFilterOpened = () => {
    setOpened(!opened);
  };

  let headers, rows, filterOptions;
  let filterParam: any[] = [];

  if (props.config?.visibleColumn) {
    headers = props.config?.visibleColumn.map((column) => {
      return (
        <th key={Array.isArray(column.key) ? column.key[0] : column.key}>
          <div
            className={`flex ${
              column.key !== props.config?.primaryColumn?.key &&
              "hidden md:block"
            }`}
          >
            <div>{column?.name}</div>
            {props.hasSort && (
              <div
                className={`h-4 items-center  hover:cursor-pointer  ${
                  column.key !== props.config?.primaryColumn?.key &&
                  "hidden md:block"
                } `}
              >
                <a onClick={() => sorting(column.key, "ASC")}>
                  <IconArrowsDownUp
                    className="mt-1 ml-1"
                    color={"grey"}
                    width={14}
                    height={14}
                  />
                </a>
              </div>
            )}
          </div>
        </th>
      );
    });

    const checkIsChecked = (element: any) => {
      if (Array.isArray(checkedItems)) {
        const found = checkedItems?.some((el) => el?.id === element.id) ?? [];
        if (found) return true;
        return false;
      }
    };
    rows = items?.map((element, index) => (
      <tr key={index} className="group">
        <td>
          <Checkbox
            color={"blue"}
            onChange={(event) => handleCheckbox(event, element)}
            checked={checkIsChecked(element)}
          />
        </td>
        {props.config?.visibleColumn.map((column) => {
          if (column?.deep && column?.deepKey) {
            return (
              <td>
                <ul
                  className={`${
                    column.key !== props.config?.primaryColumn?.key &&
                    "hidden md:block"
                  }`}
                >
                  {element[column.key]?.map((item: any) => (
                    <li className={"list-disc"} key={item?.toString()}>
                      {item[`${column.deepKey}`]}
                    </li>
                  ))}
                </ul>
              </td>
            );
          }
          return (
            <td>
              <span
                className={`flex ${
                  column.key !== props.config?.primaryColumn?.key &&
                  "hidden md:block"
                }`}
              >
                {!Array.isArray(column.key)
                  ? element[column.key]
                  : childeView(element, column.key)}
              </span>
            </td>
          );
        })}
      </tr>
    ));
    if (props?.config?.filter?.length) {
      filterOptions = props.config.filter.map((option) => {
        return (
          <div className="w-full">
            <div className="py-2 pl-4 font-bold border-b">
              Filtered By {option[0].fieldName}
            </div>
            <div className="px-2" key={option[0].field}>
              {option.map((opt) => {
                return (
                  <Checkbox
                    onChange={(event) => onFilter(event, opt)}
                    label={opt.name}
                    size="xs"
                    className="my-2"
                  />
                );
              })}
            </div>
          </div>
        );
      });
    }
  }

  const childeView = (item: any, keys: string[]) => {
    if (keys.length && item) {
      keys.forEach((key: any) => {
        if (item[key] !== null && item[key] !== undefined) {
          item = item[key];
        } else {
          item = "";
        }
      });
    }

    return item;
  };

  const onDone = async () => {
    if (props.onDone) {
      await props.onDone(checkedItems);
      props.setModalOpened(false);
      setCheckedItems([]);
    }
  };

  const onFilter = (event: any, selectedField: any) => {
    // Adds and removes filter params into the query
    event.currentTarget.checked
      ? filterParam.push(selectedField)
      : (filterParam = filterParam.filter(
          (a) => a.value !== selectedField.value
        ));

    // groups the filter query by their filter key
    let filterQuery: any[] = [];
    const filterMap: { [key: string]: any[] } = {};
    filterParam.forEach((item) => {
      filterMap[item.field] = filterParam.filter(
        (query) => query?.field === item.field
      );
    });
    // constructs the filter query into array form the grouped object
    Object.keys(filterMap).forEach((key) => {
      filterQuery = [...filterQuery, filterMap[key]];
    });
    props.filter(filterQuery);
  };

  return (
    <Modal
      opened={props.modalOpened}
      onClose={() => props.setModalOpened(false)}
      title={props.config?.title}
      size={props.config?.size}
      styles={{
        header: {
          borderBottom: "1px solid rgb(229 231 235)",
        },
        title: {
          color: "rgb(0,0,0)",
          fontWeight: "bold",
        },
      }}
    >
      <Card className="w-full" style={{ height: "fit-content" }}>
        {/* search */}
        <Card.Section className="px-2 py-2">
          <div className="flex w-full">
            <Input
              className={"w-full"}
              size="xs"
              onKeyUp={(event: any) => props?.search(event.target.value)}
              placeholder="search"
              rightSection={<IconSearch className="inline-block" size={16} />}
              rightSectionWidth={40}
              styles={{ rightSection: { pointerEvents: "none" } }}
            />

            {props.config?.filter?.length && props.showFilterButton && (
              <Popover
                classNames={{ dropdown: "ml-2 h-7 bg-white hover:bg-white" }}
                opened={opened}
                onClose={() => setOpened(false)}
              >
                <Popover.Target>
                  <div
                    onClick={onFilterOpened}
                    className="flex items-center border h-7 hover:cursor-pointer"
                  >
                    <IconFilter className="flex mx-1" />
                    <span className="mx-1">Filter</span>
                    <IconChevronDown className="flex mx-1" />
                  </div>
                </Popover.Target>
                <Popover.Dropdown>{filterOptions}</Popover.Dropdown>
              </Popover>
            )}
          </div>
        </Card.Section>
        {/* table */}
        <Card.Section className="px-2 py-2">
          <Table horizontalSpacing="sm">
            <thead>
              <tr className="bg-gray-200">
                <th style={{ width: "1%" }}>
                  <Checkbox
                    checked={isCheckAll}
                    onChange={(event) => checkAll(event)}
                  />
                </th>
                {headers}
              </tr>
            </thead>
            {props.itemsLoading && (
              <div>
                <LoadingOverlay
                  visible={props.itemsLoading}
                  loaderProps={{
                    size: "sm",
                    color: "#1d2861",
                    variant: "oval",
                  }}
                  overlayOpacity={0.3}
                  overlayColor={"#1d2861"}
                />
              </div>
            )}
            <tbody>{rows}</tbody>
          </Table>
        </Card.Section>
        {!props.items?.length && !props.itemsLoading && <EmptyIcon />}

        {/* pagination */}

        {/* {props?.items?.length ? (
          <Card.Section className="flex justify-end mt-4 mb-2 ">
            <Pagination
              total={props?.total ? props?.total : 0}
              pageSize={[5, 10, 20]}
              onPaginationChange={(skip:number,top:number)=>props.onPaginationChange(skip,top)}
            />
          </Card.Section>
        ) : null} */}

        {/* submit value */}
        <Card.Section className="flex justify-end px-2 mt-4">
          <div className="mr-2">
            <Button
              onClick={() => props.setModalOpened(false)}
              variant="outline"
              size="xs"
              type="submit"
              component="button"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              loading={props.buttonLoading}
              onClick={onDone}
              size={"xs"}
              type="submit"
              variant="default"
              className="text-white bg-primary"
              component="button"
            >
              Done
            </Button>
          </div>
        </Card.Section>
      </Card>
    </Modal>
  );
}

export default ModalCollectionSelector;
