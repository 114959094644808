import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Center, Group, PasswordInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useChangePasswordMutation } from "../store/account.query";

export default function ChangePasswordPage() {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      currentPassword: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("Current password is required"),
      password: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("New password is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .test(
          "",
          "Confirm password must be the same with the New password",
          (value) => {
            if (getValues("password") === value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .required("Confirm password is required"),
    })
    .required();

  const defaultValue = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };
  const [changePassword, changePasswordResponse] = useChangePasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });

  const onSubmit = (data: any) => {
    changePassword(data).then((response: any) => {
      if (!response?.error) {
        navigate("/");
      }
    });
  };
  const onError = (error: any) => {};

  return (
    <>
      <div className="bg-sky-100 h-screen w-full flex justify-center items-center">
        <Center className="w-[40%] z-20  h-3/4 border py-4 bg-white">
          <div className="flex-col space-y-4 w-full">
            <div className="w-full flex justify-center">
              <a href="/">
                <img src="/favicon-32x32.ico" alt="img" className=" w-12" />
              </a>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full flex justify-center"
            >
              <div className="w-2/4 gap-y-2">
                <PasswordInput
                  placeholder="Current Password"
                  label="Current Password"
                  error={
                    errors?.currentPassword &&
                    `${errors?.currentPassword?.message}`
                  }
                  {...register("currentPassword")}
                  withAsterisk
                />
                <PasswordInput
                  placeholder="New Password"
                  label="New Password"
                  error={errors?.password && `${errors?.password?.message}`}
                  {...register("password")}
                  withAsterisk
                />
                <PasswordInput
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  description="Password must be the same with the new password"
                  withAsterisk
                  error={
                    errors?.confirmPassword &&
                    `${errors?.confirmPassword?.message}`
                  }
                  {...register("confirmPassword")}
                />

                <div className="w-full flex justify-center items-center">
                  <Group position="center" mt="xl" className="w-full">
                    <Button
                      variant="filled"
                      className="bg-primary w-full text-white"
                      type="submit"
                      classNames={{ label: "flex space-x-1" }}
                      size={"xs"}
                      loading={changePasswordResponse?.isLoading}
                    >
                      <span>Change Password</span>
                    </Button>
                  </Group>
                </div>
              </div>
            </form>
          </div>
        </Center>
      </div>
    </>
  );
}
