import { Avatar, Group, Menu, Text, UnstyledButton } from "@mantine/core";
import {
  IconAccessPoint,
  IconBook,
  IconBrandBooking,
  IconBrandSuperhuman,
  IconBrandYoutubeKids,
  IconBuilding,
  IconBusStop,
  IconCar,
  IconCash,
  IconChevronRight,
  IconCurrencyDollarBrunei,
  IconDashboard,
  IconEdit,
  IconLayoutGridAdd,
  IconMap,
  IconPropeller,
  IconReportAnalytics,
  IconRoute,
  IconSchool,
  IconSettings,
  IconTie,
  IconTruckDelivery,
  IconUser,
  IconUserCheck,
  IconUserCircle,
  IconUsers,
  IconWallet,
} from "@tabler/icons";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  useLazyGetUserRolePermissionsQuery,
  useLazyGetUserRolesQuery,
} from "../../feature/user/store/user.query";
import { Role } from "../../models/role.model";
import { switchRole } from "../../shared/auth/api/auth.api";
import { setRole } from "../../shared/auth/auth-slice/auth-slice";
import AuthContext from "../../shared/auth/context/authContext";
import { downloadUrlParser } from "../../shared/utility/Tools/tools";
import { LinkDropDown } from "./link-dropdown-component";
interface Props {
  collapse: boolean;
}
interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  image: string;
  name: string;
  role: string | undefined;
  icon?: React.ReactNode;
  collapse: boolean;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, role, collapse, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: "block",
        width: "100%",
      })}
      {...others}
    >
      <Group className="flex">
        <Avatar src={image} radius="xl" />

        {!collapse && (
          <div style={{ flex: 1 }}>
            <Text size="sm" className="text-white" weight={500}>
              {name}
            </Text>

            <Text
              color="dimmed"
              size="xs"
              className="flex items-end space-x-1 capitalize"
            >
              {role} {!collapse && (icon || <IconChevronRight size="1rem" />)}
            </Text>
          </div>
        )}
      </Group>
    </UnstyledButton>
  )
);

export default function SideNav(props: Props) {
  const ref = useRef<any>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, logOut } = useContext(AuthContext);
  const [value, setValue] = useState<string | null>(null);
  const [currentRole, setCurrentRole] = useState<Role>();

  const [getUserRoles, userRoles] = useLazyGetUserRolesQuery();
  const [getUserRolePermissions, userRolePermissions] =
    useLazyGetUserRolePermissionsQuery();

  const { collapse } = props;

  const validateJson = () => {
    if (localStorage?.currentRole) {
      try {
        return JSON.parse(localStorage.currentRole)?.id;
      } catch (e) {
        return null;
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setValue(null);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setValue]);

  useEffect(() => {
    if (user?.id) {
      getUserRoles(user?.id, true).then((response) => {
        if (response) {
          if (
            localStorage.currentRole &&
            response?.data?.data?.some((role) => role.id === validateJson())
          ) {
            setCurrentRole(JSON.parse(localStorage?.currentRole));
          } else {
            setCurrentRole(response?.data?.data?.[0]);

            localStorage.setItem(
              "currentRole",
              JSON.stringify(response?.data?.data?.[0])
            );
          }
        }
      });
    }
  }, [user, getUserRoles]);

  useEffect(() => {
    if (currentRole?.id) {
      dispatch(setRole(currentRole));
      getUserRolePermissions({
        roleId: currentRole?.id,
        accountId: user?.id,
      }).then((response) => {
        if (response) {
          localStorage.setItem(
            "userRolePermissions",
            JSON.stringify(response?.data)
          );
        }
      });
    }
  }, [currentRole, getUserRolePermissions, user?.id]);

  return (
    <div className="relative flex-col w-full h-full text-white bg-gray-900 border-0 border-r border-gray-500">
      <div className={`gap-4 overflow-y-auto  h-full pb-2`}>
        <Menu withArrow>
          <Menu.Target>
            <UserButton
              className={` bg-gray-900 mt-2 mb-4 ${
                collapse ? " flex justify-center " : ""
              }`}
              collapse={collapse}
              image={
                user?.profileImage?.path
                  ? downloadUrlParser({
                      path: user?.profileImage?.path,
                      filename: user?.profileImage?.filename,
                      originalname: user?.profileImage?.originalname,
                      mimetype: user?.profileImage?.mimetype,
                    })
                  : ""
              }
              name={user?.name}
              role={currentRole?.name}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Profile</Menu.Label>
            {userRoles?.data?.data?.map((role: Role) => (
              <Menu.Item
                key={role.id}
                icon={<IconUserCircle size={14} />}
                onClick={() => {
                  setCurrentRole(role);
                  switchRole(role?.id);
                  localStorage.setItem("currentRole", JSON.stringify(role));
                }}
              >
                {role?.name}
              </Menu.Item>
            ))}
            <Menu.Item
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 fill-current"
                  viewBox="0 0 24 24"
                  fill="#5B5B5B"
                >
                  <path
                    d="M6 2C4.897 2 4 2.897 4 4L4 20C4 21.103 4.897 22 6 22L18 22C19.103 22 20 21.103 20 20L20 15.25L18.001953 16.748047L18.001953 20L6 20L6 4L18 4L18 7.25L20 8.75L20 4C20 2.897 19.103 2 18 2L6 2 z M 16 8.25L16 11L11 11L11 13L16 13L16 15.75L21 12L16 8.25 z"
                    fill="#5B5B5B"
                  />
                </svg>
              }
            >
              <div onClick={() => logOut()} className="w-full cursor-pointer">
                Sign out
              </div>
            </Menu.Item>
            <Menu.Item icon={<IconEdit size={14} />}>
              <NavLink to={"/accounts/change-password"}>
                Change Password
              </NavLink>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <div className="flex-col space-y-1 " ref={ref}>
          <LinkDropDown
            label="Dashboard"
            icon={IconDashboard}
            link="/"
            collapse={collapse}
            links={[
              {
                label: "Owners",
                link: "/dashboard/owner",
                Icon: IconUser,
                active:
                  location?.pathname?.includes("/dashboard/owner") && true,
              },
              {
                label: "Vehicles",
                link: "/dashboard/vehicles",
                Icon: IconCar,
                active:
                  location?.pathname?.includes("/dashboard/vehicles") && true,
              },
              {
                label: "Corporates",
                link: "/dashboard/corporate",
                Icon: IconBuilding,
                active:
                  location?.pathname?.includes("/dashboard/corporate") && true,
              },

              {
                label: "Passengers",
                link: "/dashboard/passenger",
                Icon: IconUser,
                active:
                  location?.pathname?.includes("/dashboard/passenger") && true,
              },
              {
                label: "Assignments",
                link: "/dashboard/assignment",
                Icon: IconAccessPoint,
                active:
                  location?.pathname?.includes("/dashboard/assignment") && true,
              },
              {
                label: "Bookings",
                link: "/dashboard/booking",
                Icon: IconBrandBooking,
                active:
                  location?.pathname?.includes("/dashboard/booking") && true,
              },
              {
                label: "Kabba Kids",
                link: "/dashboard/kabba-kids",
                Icon: IconBrandYoutubeKids,
                active:
                  location?.pathname?.includes("/dashboard/kabba-kids") && true,
              },
              {
                label: "Kids Earnings",
                link: "/dashboard/kids-earning",
                Icon: IconCurrencyDollarBrunei,
                active:
                  location?.pathname?.includes("/dashboard/kids-earning") &&
                  true,
              },
              {
                label: "Public Earnings",
                link: "/dashboard/earning",
                Icon: IconCurrencyDollarBrunei,
                active:
                  location?.pathname?.includes("/dashboard/earning") && true,
              },
            ]}
          />

          {["super_admin", "admin", "operator"].includes(
            `${currentRole?.key}`
          ) && (
            <LinkDropDown
              label="Operations"
              icon={IconPropeller}
              collapse={collapse}
              links={[
                {
                  label: "Owners",
                  link: "/registration/owner",
                  Icon: IconUser,
                  active:
                    location?.pathname?.includes("/registration/owner") && true,
                },
                {
                  label: "Vehicles",
                  link: "/registration/vehicle",
                  Icon: IconCar,
                  active:
                    location?.pathname?.includes("/registration/vehicle") &&
                    true,
                },
                {
                  label: "Providers",
                  link: "/registration/provider",
                  Icon: IconTruckDelivery,
                  active:
                    location?.pathname?.includes("/registration/provider") &&
                    true,
                },
                {
                  label: "Corporates",
                  link: "/registration/corporate",
                  Icon: IconBuilding,
                  active:
                    location?.pathname?.includes("/registration/corporate") &&
                    true,
                },
                {
                  label: "Passengers",
                  link: "/registration/passenger",
                  Icon: IconUser,
                  active:
                    location?.pathname?.includes("/registration/passenger") &&
                    true,
                },
                {
                  label: "Schools",
                  link: "/registration/school",
                  Icon: IconSchool,
                  active:
                    location?.pathname?.includes("/registration/school") &&
                    true,
                },
                {
                  label: "Parents",
                  link: "/registration/parent",
                  Icon: IconBrandSuperhuman,
                  active:
                    location?.pathname?.includes("/registration/parent") &&
                    true,
                },
                {
                  label: "Groups",
                  link: "/registration/group",
                  Icon: IconUsers,
                  active:
                    location?.pathname?.includes("/registration/group") && true,
                },
                {
                  label: "Jobs",
                  link: "/registration/job",
                  Icon: IconTie,
                  active:
                    location?.pathname?.includes("/registration/job") && true,
                },
              ]}
            />
          )}
          <LinkDropDown
            label="Driver Tracking"
            icon={IconCash}
            link="/drivertracking"
            active={location?.pathname?.includes("/drivertracking") && true}
            collapse={collapse}
          />
          <LinkDropDown
            label="Assignments"
            icon={IconUserCheck}
            link="/assignment"
            active={
              location?.pathname?.includes("/assignment") &&
              location?.pathname?.split("/")[2] !== "payment" &&
              true
            }
            collapse={collapse}
          />
          <LinkDropDown
            label="Payments"
            icon={IconCash}
            link="/assignment/payment"
            active={location?.pathname?.includes("/assignment/payment") && true}
            collapse={collapse}
          />
          <LinkDropDown
            label="Requests"
            icon={IconCar}
            link="/request"
            active={location?.pathname?.includes("/request") && true}
            collapse={collapse}
          />
          <LinkDropDown
            label="Bookings"
            icon={IconBook}
            link="/bookings"
            active={location?.pathname?.split("/")[1] === "bookings" && true}
            collapse={collapse}
          />

          <LinkDropDown
            label="Wallets"
            icon={IconWallet}
            collapse={collapse}
            link="/wallets"
            active={location?.pathname?.includes("/wallets") && true}
          />
          {currentRole?.key !== "finance" && (
            <>
              {/* <LinkDropDown
                label="Services"
                icon={IconServicemark}
                collapse={collapse}
                link="/service"
                active={location?.pathname?.includes("/service")&&true}
              />
              <LinkDropDown
                label="Blogs"
                icon={IconBrandBlogger}
                collapse={collapse}
                link="/blogs"
                active={location?.pathname?.includes("/blogs")&&true}
              /> */}
            </>
          )}
          {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <LinkDropDown
              label="Passengers Preferences"
              icon={IconRoute}
              collapse={collapse}
              link="/passengers-preferences"
              active={location?.pathname?.includes("/suggested-route") && true}
            />
          )}
          {/* {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <LinkDropDown
              label="Trips"
              icon={IconCar}
              collapse={collapse}
              link="/trips"
              active={location?.pathname?.includes("/trips") && true}
            />
          )} */}
          <LinkDropDown
            label="FAQs"
            icon={IconUserCheck}
            collapse={collapse}
            links={[
              {
                label: "FAQ Categories",
                link: "/faq-category",
                Icon: IconCash,
                active: location?.pathname?.includes("/faq-category") && true,
              },
              {
                label: "FAQs",
                link: "/faq",
                Icon: IconUserCheck,
                active: location?.pathname?.split("/").includes("faq") && true,
              },
            ]}
          />
          {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <LinkDropDown
              label="Chatbot Conversations"
              icon={IconReportAnalytics}
              collapse={collapse}
              link="/chat-bot"
              active={location?.pathname?.includes("/chat-bot") && true}
            />
          )}
          {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <LinkDropDown
              label="Demand Forecasts"
              icon={IconReportAnalytics}
              collapse={collapse}
              link="/demand-forcast"
              active={location?.pathname?.includes("/demand-forcast") && true}
            />
          )}
          {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <LinkDropDown
              label="Activity Logs"
              icon={IconReportAnalytics}
              collapse={collapse}
              link="/activity"
              active={location?.pathname?.includes("/activity") && true}
            />
          )}
          {currentRole?.key !== "finance" && (
            <LinkDropDown
              label="Settings"
              icon={IconSettings}
              collapse={collapse}
              links={[
                {
                  label: "City",
                  link: "/settings-and-configurables/city",
                  Icon: IconMap,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/city"
                    ) && true,
                },
                {
                  label: "Bus Stop",
                  link: "/settings-and-configurables/bus-stop",
                  Icon: IconBusStop,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/bus-stop"
                    ) && true,
                },
                {
                  label: "Category",
                  link: "/settings-and-configurables/category",
                  Icon: IconLayoutGridAdd,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/category"
                    ) && true,
                },
                {
                  label: "Route",
                  link: "/settings-and-configurables/route",
                  Icon: IconRoute,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/route"
                    ) && true,
                },
                {
                  label: "Configuration",
                  link: "/settings-and-configurables/configurable",
                  Icon: IconSettings,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/configurable"
                    ) && true,
                },
              ]}
            />
          )}
          {currentRole?.key === "super_admin" && (
            <LinkDropDown
              label="User Management"
              icon={IconUserCircle}
              collapse={collapse}
              links={[
                {
                  label: "User Management",
                  link: "/user-management/user",
                  Icon: IconUserCircle,
                  active:
                    location?.pathname?.includes("/user-management/user") &&
                    true,
                },
                {
                  label: "Role and Permission",
                  link: "/user-management/role",
                  Icon: IconUserCircle,
                  active:
                    location?.pathname?.includes("/user-management/role") &&
                    true,
                },
              ]}
            />
          )}

          <div className="flex items-center justify-center px-2 mt-8 h-14">
            <img
              src="/kabba-logo.png"
              alt="logo"
              className={`${collapse ? "h-4" : "h-10"}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
