import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NewChatBotComponent from "../component/new-chatBot-component";
import ChatBotPage from "../page/chatBot-page";
export const ChatBotRoute: RouteObject = {
  path: "/chat-bot",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <ChatBotPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewChatBotComponent editMode="detail" />,
    },
    { path: ":id", element: <NewChatBotComponent editMode="new" /> },
  ],
};
