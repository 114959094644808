/* eslint-disable @typescript-eslint/no-explicit-any */
import { Booking } from "../../../models/booking.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Owner } from "../../../models/owner.model";
import { Passenger } from "../../../models/passenger.model";
import { Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { User } from "../../../models/user.model";
import { Vehicle } from "../../../models/vehicle.model";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { DASHBOARD_ENDPOINT } from "../dashboard.endpoint";

const dashboardQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getPassengersDashboard: builder.query<
      Collection<Passenger>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.passengers}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getUsersDashboard: builder.query<Collection<User>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.users}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getVehiclesDashboard: builder.query<Collection<Vehicle>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.vehicles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getAllProvidersDashboard: builder.query<
      Collection<Provider>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.drivers}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getOwnersDashboard: builder.query<Collection<Owner>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.owners}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getBookingsDashboard: builder.query<Collection<Booking>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.bookings}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getTrips: builder.query<Collection<RouteAssignment>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.trips}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getTotalDeposit: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.total_deposit}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getTotalDeposits: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.get_total_deposits}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupOwnersByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_owners_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupVehiclesByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_vehicle_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    countVehiclesByCategory: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.count_vehicles_category}`,
        method: "GET",
      }),
    }),
    groupPassengersByCreatedDate: builder.query<any, string>({
      query: (format) => ({
        url: `${DASHBOARD_ENDPOINT.group_passenger_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    groupOwnersByCreatedDate: builder.query<any, string>({
      query: (format: string) => ({
        url: `${DASHBOARD_ENDPOINT.group_owners_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    groupVehiclesByCreatedDate: builder.query<any, string>({
      query: (format) => ({
        url: `${DASHBOARD_ENDPOINT.group_vehicles_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    getTotalCorporateBalance: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.total_corporate_balance}`,
        method: "GET",
      }),
    }),
    getTotalWalletBallance: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.total_wallet_ballance}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getTotalDriverFees: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.total_driver_fees}`,
        method: "GET",
      }),
    }),
    groupPassengersByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_passenger_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupPassengersByGender: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_passenger_by_gender}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupOwnersByGender: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_owners_by_gender}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupBookingsByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_bookings_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupBookingsByCreatedMonth: builder.query<any, any>({
      query: (data: any) => ({
        url: `${DASHBOARD_ENDPOINT.group_booking_by_created_month}/${data.format}`,
        method: "GET",
        params: collectionQueryBuilder(data.collectionQ),
      }),
    }),
    groupCorporatesByCreatedDate: builder.query<any, string>({
      query: (format: string) => ({
        url: `${DASHBOARD_ENDPOINT.group_corporates_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    getBookingValue: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.get_booking_value}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getCompanyEarning: builder.query<any, CollectionQuery>({
      query: (collection: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.get_company_earning}`,
        method: "GET",
        params: collectionQueryBuilder(collection),
      }),
    }),
    getKidsCompanyEarning: builder.query<any, CollectionQuery>({
      query: (collection: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.get_kids_company_earning}`,
        method: "GET",
        params: collectionQueryBuilder(collection),
      }),
    }),
    groupEarningByCreatedDate: builder.query<any, string>({
      query: (format) => ({
        url: `${DASHBOARD_ENDPOINT.group_earning_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    groupKidsEarningByCreatedDate: builder.query<any, string>({
      query: (format) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_earning_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    groupeEarningByCategory: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_earning_by_category}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupKidsEarningByCategory: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_earning_by_category}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupEarningByFormat: builder.query<any, any>({
      query: (data: { format: string; collection: CollectionQuery }) => ({
        url: `${DASHBOARD_ENDPOINT.group_earning_by_format}/${data?.format}`,
        method: "GET",
        params: collectionQueryBuilder(data?.collection),
      }),
    }),
    groupKidsEarningByFormat: builder.query<any, any>({
      query: (data: { format: string; collection: CollectionQuery }) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_earning_by_format}/${data?.format}`,
        method: "GET",
        params: collectionQueryBuilder(data?.collection),
      }),
    }),
    groupParentsByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_parents_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupKidsByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupKidsByTransportationTime: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_by_transportation_time}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupParentsByEnabled: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_parents_by_enabled}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupKidsByGender: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_by_gender}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupAssignmentsByRoutes: builder.query<Collection<any>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_assignment_by_routes}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    mostBookedRoutes: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.get_most_booked_routes}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupAssignmentsByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_assignment_by_status}`,
        method: "GET",
        data: collectionQueryBuilder(data),
      }),
    }),
    groupKidsByCreatedDate: builder.query<any, string>({
      query: (format) => ({
        url: `${DASHBOARD_ENDPOINT.group_kids_by_created_date}/${format}`,
        method: "GET",
      }),
    }),
    groupCorporatesByCreatedMonth: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.group_corporates_by_created_month}`,
        method: "GET",
      }),
    }),
    groupCorporatesByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_corporates_by_status}`,
        method: "GET",
        data: collectionQueryBuilder(data),
      }),
    }),
    exportVehicles: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_vehicles}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
    exportOwners: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_owners}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
    exportCorporate: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_corporates}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
    exportPassenger: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_passengers}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
    exportParents: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_parents}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
    exportKids: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_kids}?${collectionQueryBuilder(
          data
        )}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetUsersDashboardQuery,
  useLazyGetPassengersDashboardQuery,
  useLazyGetBookingsDashboardQuery,
  useLazyGetOwnersDashboardQuery,
  useLazyGetVehiclesDashboardQuery,
  useLazyGetAllProvidersDashboardQuery,
  useLazyGetTripsQuery,
  useLazyGetTotalDepositQuery,
  useLazyCountVehiclesByCategoryQuery,
  useLazyGroupVehiclesByStatusQuery,
  useLazyGetTotalCorporateBalanceQuery,
  useLazyGroupVehiclesByCreatedDateQuery,
  useLazyGroupPassengersByStatusQuery,
  useLazyGroupPassengersByGenderQuery,
  useLazyGroupPassengersByCreatedDateQuery,
  useLazyGroupOwnersByStatusQuery,
  useLazyGroupOwnersByCreatedDateQuery,
  useLazyGroupOwnersByGenderQuery,
  useLazyGroupBookingsByStatusQuery,
  useLazyGetCompanyEarningQuery,
  useLazyGetKidsCompanyEarningQuery,
  useLazyGroupEarningByCreatedDateQuery,
  useLazyGroupKidsEarningByCreatedDateQuery,
  useLazyGroupParentsByStatusQuery,
  useLazyGroupKidsByStatusQuery,
  useLazyGroupKidsByTransportationTimeQuery,
  useLazyGroupParentsByEnabledQuery,
  useLazyGroupKidsByGenderQuery,
  useLazyGroupAssignmentsByRoutesQuery,
  useLazyGroupKidsByCreatedDateQuery,
  useLazyGroupAssignmentsByStatusQuery,
  useLazyGroupCorporatesByCreatedMonthQuery,
  useLazyGroupCorporatesByStatusQuery,
  useLazyExportVehiclesQuery,
  useLazyExportOwnersQuery,
  useLazyExportCorporateQuery,
  useLazyExportPassengerQuery,
  useLazyExportParentsQuery,
  useLazyExportKidsQuery,
  useLazyGetBookingValueQuery,
  useLazyMostBookedRoutesQuery,
  useLazyGroupEarningByFormatQuery,
  useLazyGroupKidsEarningByFormatQuery,
  useLazyGroupeEarningByCategoryQuery,
  useLazyGroupKidsEarningByCategoryQuery,
  useLazyGroupBookingsByCreatedMonthQuery,
  useLazyGetTotalWalletBallanceQuery,
  useLazyGetTotalDepositsQuery,
  useLazyGroupCorporatesByCreatedDateQuery,
} = dashboardQuery;
