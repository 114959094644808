import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActionIcon,
  Alert,
  Button,
  FileInput,
  Group,
  Input,
  List,
  LoadingOverlay,
  Modal,
  Stack,
  Table,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconDeviceFloppy,
  IconPlus,
  IconSearch,
  IconTableImport,
  IconUpload,
  IconUserMinus,
} from "@tabler/icons";

import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { CollectionQuery } from "../../../../models/collection.model";
import { Passenger } from "../../../../models/passenger.model";
import Confirmation from "../../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../../shared/component/pagination/pagination";
import { dateFormat } from "../../../../shared/utility/date-format/date-format";
import {
  useImportEmployeeMutation,
  useLazyGetCorporateEmployeesAdminQuery,
  useRemoveEmployeeMutation,
} from "../../store/corporate.query";
interface Props {
  id: string | undefined;
}

const schema = yup
  .object()
  .shape({
    users: yup
      .mixed()
      .required("Employee file is required")
      .test("fileSize", "Users file should be less than 2MB", (value) => {
        return value && value?.size <= 2000000;
      })
      .test("type", "Only xlsx formats are accepted", (value) => {
        return (
          value &&
          value.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }),
  })
  .required();

export default function CorporateEmployeeComponent(props: Props) {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [opened, setOpened] = useState(false);
  const [getCorporateEmployees, corporateEmployees] =
    useLazyGetCorporateEmployeesAdminQuery();
  const [removeEmployee, removedEmployee] = useRemoveEmployeeMutation();
  const [selection, setSelection] = useState("");

  const [importEmployee, importResponse] = useImportEmployeeMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("users", data.users);
    importEmployee({ id: `${props.id}`, excelData: formData }).then(
      (result: any) => {
        if (!result?.error && result?.data?.failedEmployees?.length === 0) {
          setOpened(false);
        } else {
        }
      }
    );
  };

  const [searchParam, setSearchParam] = useState<string>("");

  useEffect(() => {
    if (props.id) {
      getCorporateEmployees({
        ...collection,
        filter: [[{ field: "corporateId", operator: "=", value: props.id }]],
      });
    }
  }, [collection, props]);

  const onError = (data: any) => {};
  console.log("searchParam", searchParam);

  return (
    <div className={"min-w-max overflow-x-auto"}>
      <Stack align={"flex-start"} className={"mt-3 mb-5"}>
        <div className={"ml-3"}>Corporate Employee List</div>
        <Group position={"apart"} className={"w-full"}>
          <Group position={"apart"}>
            <Input
              onKeyUp={debounce(
                (event: any) =>
                  setCollection({
                    ...collection,
                    search: event.target.value,
                    searchFrom: ["name", "email", "phoneNumber"],
                  }),
                1000
              )}
              size="xs"
              icon={<IconSearch size={16} />}
              placeholder="Search"
            />
          </Group>
          <Group>
            <NavLink
              to={"add"}
              className="relative flex items-center px-2 py-1 text-xs text-white rounded cursor-pointer bg-primary"
            >
              <IconPlus size={16} />
              <div className={"pl-2"}>Add</div>
            </NavLink>
            <div
              onClick={() => {
                setOpened(true);
              }}
              className="relative flex items-center justify-center px-2 py-1 text-xs text-white rounded cursor-pointer bg-primary"
            >
              <IconTableImport size={16} strokeWidth={1.5} />
              <div className={"pl-2"}>Import</div>
            </div>
          </Group>
        </Group>
      </Stack>

      <Modal
        size={"lg"}
        opened={opened}
        onClose={() => setOpened(false)}
        title="Import Employees"
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="w-full">
            <Controller
              control={control}
              name="users"
              render={({ field }) => (
                <FileInput
                  error={errors.users && `${errors.users?.message}`}
                  label="Employees"
                  withAsterisk
                  //@ts-ignore
                  placeholder="upload xlsx"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
          </div>
          <Group position="right" mt="xl">
            <>
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                type="reset"
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 fill-current"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>
              <Button
                variant="filled"
                className="text-white bg-primary"
                type="submit"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={importResponse.isLoading}
              >
                <span>
                  <IconDeviceFloppy size={16} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </>
          </Group>
        </form>

        {importResponse?.isSuccess &&
          importResponse?.data?.failedEmployees.length > 0 && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Failed to import"
              color="red"
              className="mt-4"
            >
              <List type="ordered" size="sm" withPadding>
                {importResponse?.data?.failedEmployees.map(
                  (employee: Passenger, index: number) => (
                    <List.Item>{`${index + 1}. ${employee?.name}  +${
                      employee?.phoneNumber
                    }`}</List.Item>
                  )
                )}
              </List>
            </Alert>
          )}
      </Modal>
      <div className="relative overflow-x-auto">
        {/* <LoadingOverlay visible={corporateEmployees.isFetching && !corporateEmployees?.isLoading} /> */}
        <Table
          fontSize={10}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Gender
              </th>
              <th scope="col" className="px-6 py-3">
                Emergency Contact
              </th>
              <th scope="col" className="px-6 py-3">
                Registered At
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {corporateEmployees.isSuccess &&
              corporateEmployees.data.data.length > 0 &&
              corporateEmployees.data?.data.map(
                (employee: Passenger, idx: number) => (
                  <tr
                    key={idx}
                    className="text-xs bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {employee.name}
                    </th>
                    <td className="px-6 py-4 text-xs">
                      {employee.phoneNumber}
                    </td>
                    <td className="px-6 py-4">
                      {employee.gender === "male" ? "Male" : "Female"}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {employee?.emergencyContact
                        ? employee?.emergencyContact?.name +
                          "(" +
                          employee?.emergencyContact?.phoneNumber +
                          ")"
                        : "----"}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {dateFormat(employee?.createdAt)}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {employee?.enabled ? "Active" : "Inactive"}
                    </td>
                    <td className="px-2 py-4">
                      <div className="flex-col items-center space-y-1">
                        <Confirmation
                          header={"Remove employee confirmation"}
                          type={"danger"}
                          yesText={"Remove"}
                          message={"Do you want to remove it?"}
                          onYes={() => {
                            setSelection(employee.id);
                            removeEmployee({
                              corporateId: props.id ?? "",
                              id: employee.id,
                            }).then(() => {
                              setSelection("");
                            });
                          }}
                        >
                          <ActionIcon
                            variant="filled"
                            loading={
                              removedEmployee.isLoading &&
                              employee.id === selection
                            }
                            className="text-white bg-danger"
                            type="button"
                            size={"xs"}
                          >
                            {!(
                              removedEmployee.isLoading &&
                              employee.id === selection
                            ) && (
                              <span>
                                <IconUserMinus
                                  className={"mx-auto"}
                                  size={14}
                                  strokeWidth={1.5}
                                />
                              </span>
                            )}
                          </ActionIcon>
                        </Confirmation>
                      </div>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
        {(corporateEmployees.isLoading ||
          !corporateEmployees?.data?.data.length ||
          corporateEmployees?.isError) && (
          <div className="relative flex items-center justify-center w-full">
            <LoadingOverlay
              visible={corporateEmployees.isLoading}
              overlayBlur={1}
            />
            <EmptyIcon />
          </div>
        )}
        {corporateEmployees?.isSuccess &&
          corporateEmployees?.data?.data?.length > 0 && (
            <div className="flex items-center justify-end w-full mt-2 mb-2">
              <Pagination
                total={
                  corporateEmployees?.data ? corporateEmployees?.data?.count : 0
                }
                defaultPageSize={10}
                pageSize={[5, 10, 15, 20]}
                onPaginationChange={(skip: number, top: number) => {
                  setCollection({ ...collection, skip: skip, top: top });
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}
