import { Button, LoadingOverlay, Table, TextInput } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { debounce } from "lodash";
import { useState } from "react";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";

export default function RouteTableComponent({
  setSelectedDriver,
  setCollection,
  collection,
  openDriverModal,
  setLocation,
  assignments,
  isLoading,
  refresh,
  assignmentsCount,
}: any) {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const rows =
    assignments &&
    assignments?.map((assignment: any, i: number) => {
      return (
        <tr
          key={i}
          className={`cursor-pointer ${
            selectedRow === i ? "bg-primary text-white" : ""
          }`}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => {
            setLocation([
              assignment?.driver?.lat ?? 0,
              assignment?.driver?.lng ?? 0,
            ]);
            setSelectedDriver(assignment);
            setSelectedRow(i);
            // openDriverModal();
          }}
        >
          <td>{assignment?.route?.name}</td>

          <td>{`${assignment?.driver?.name} (${assignment?.driver?.phoneNumber})`}</td>
        </tr>
      );
    });

  return (
    <div className="flex w-full basis-[30%] flex-col gap-5 items-start">
      <LoadingOverlay visible={isLoading} />

      <div className="flex w-full gap-3">
        <TextInput
          className="w-full"
          placeholder="Search by driver and route"
          onChange={debounce(
            (e: any) => {
              if (e?.target.value) {
                setCollection({
                  ...collection,
                  filter: [
                    [
                      {
                        field: "driver.name",
                        operator: "like",
                        value: e.target.value,
                      },
                      {
                        field: "driver.phoneNumber",
                        operator: "like",
                        value: e.target.value,
                      },
                      {
                        field: "route.name",
                        operator: "like",
                        value: e.target.value,
                      },
                    ],
                    [
                      {
                        field: "trips.isCompleted",
                        operator: "=",
                        value: false,
                      },
                    ],
                  ],
                });
              } else {
                setCollection({
                  ...collection,
                  filter: [
                    [
                      {
                        field: "trips.isCompleted",
                        operator: "=",
                        value: false,
                      },
                    ],
                  ],
                });
              }
            },

            2000
          )}
        />

        <Button
          // className="basis-[5%]"
          onClick={refresh}
          loading={isLoading}
        >
          <IconRefresh />
        </Button>
      </div>

      <Table className="">
        <thead>
          <tr>
            <th>Route </th>
            <th>Driver</th>
          </tr>
        </thead>
        {assignments?.length && assignments?.length > 0 ? (
          <tbody>{rows}</tbody>
        ) : (
          <div className="relative flex items-center justify-center w-full h-56">
            <EmptyIcon />
          </div>
        )}
      </Table>

      {assignments && assignments?.length > 0 && (
        <div className="flex items-center justify-end w-full mt-2 mb-2">
          <Pagination
            total={assignmentsCount as number}
            defaultPageSize={10}
            pageSize={[5, 10, 15, 20]}
            onPaginationChange={(skip: number, top: number) => {
              setCollection({
                ...collection,
                skip: skip,
                top: top,
                filter: [
                  [
                    {
                      field: "trips.isCompleted",
                      operator: "=",
                      value: false,
                    },
                  ],
                ],
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
