import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";

import NewOwnerComponent from "../component/new-owner-component";
import OwnerVehiclesComponent from "../component/owner-vehicles.component";
import OwnerPage from "../page/owner-page";
export const OwnerRoute: RouteObject = {
  path: "/registration/owner",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <OwnerPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewOwnerComponent editMode="detail" />,
    },
    { path: ":id", element: <NewOwnerComponent editMode="new" /> },

    { path: "vehicles/:id", element: <OwnerVehiclesComponent /> },
    { path: "vehicles", element: <OwnerVehiclesComponent /> },
  ],
};
