import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FileInput, Group } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCalendar,
  IconDeviceFloppy,
  IconUpload,
  IconX,
} from "@tabler/icons";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useLazyGetArchivedOwnerQuery,
  useLazyGetOwnerQuery,
  useUpdateOwnerDocumentMutation,
} from "../store/owner.query";

export default function OwnerFileUploadComponent() {
  const params = useParams();
  const ref = useRef<any>(null);
  const [file, setFile] = useState<any>();
  const [modal, setModal] = useState(false);
  const [resetData, setResetData] = useState(false);
  const date = new Date();
  const schema = yup
    .object()
    .shape({
      logo: yup.mixed().when("title", (title, schema) => {
        if (!owner?.data?.logo?.filename)
          return schema
            .required("Logo is required")
            .test(
              "fileSize",
              "Logo file should be less than 2MB",
              (value: any) => {
                return value && value?.size <= 2000000;
              }
            )
            .test(
              "type",
              "Only the following formats are accepted: .jpeg, .jpg",
              (value: any) => {
                return (
                  value &&
                  (value.type === "image/jpeg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpg" ||
                    value.mimetype === "image/jpeg" ||
                    value.mimetype === "image/png" ||
                    value.mimetype === "image/jpg")
                );
              }
            );
        return schema;
      }),
      license: yup.mixed().when("title1", (title1, schema) => {
        if (!owner?.data?.license?.filename)
          return schema
            .required("License is required")
            .test(
              "fileSize",
              "License file should be less than 2MB",
              (value: any) => {
                return value && value?.size <= 2000000;
              }
            )
            .test("type", "Only .pdf format is allowed", (value: any) => {
              return value && value.type === "application/pdf";
            });
        return schema;
      }),
      licenseDueDate: yup
        .date()
        .typeError("License due date must be a valid date"),
    })
    .required();
  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [uploadOwnerDocument, uploadResponse] =
    useUpdateOwnerDocumentMutation();
  const [getOwner, owner] = useLazyGetOwnerQuery();
  const [getArchivedOwner, archivedOwner] = useLazyGetArchivedOwnerQuery();

  const onSubmit = (data: any) => {
    const formData = new FormData();
    data?.logo && formData.append("logo", data.logo);
    data?.license && formData.append("license", data.license);
    formData.append("id", `${params.id}`);
    formData.append("licenseDueDate", dayjs(data.licenseDueDate).format());
    uploadOwnerDocument(formData);
  };
  const onError = (data: any) => {};
  const onViewFile = (file: any) => {
    window
      .open(
        downloadUrlParser({
          path: file?.path,
          filename: file?.filename,
          originalname: file?.originalname,
          mimetype: file?.mimetype,
        }),
        "_blank"
      )
      ?.focus();
    setFile(file);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setModal(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [modal]);
  useEffect(() => {
    if (params?.type === "active") {
      getOwner(`${params.id}`);
    } else if (params?.type === "archived") {
      getArchivedOwner(`${params.id}`);
    }
    setResetData(false);
  }, [params.id, params?.type]);

  useEffect(() => {
    if (owner) {
      setValue(
        "licenseDueDate",
        params?.type === "active"
          ? owner?.data?.licenseDueDate
            ? new Date(owner?.data?.licenseDueDate)
            : null
          : archivedOwner?.data?.licenseDueDate
          ? new Date(archivedOwner?.data?.licenseDueDate)
          : null
      );
    }
  }, [owner?.data, params?.type, archivedOwner]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="w-full">
          <Controller
            control={control}
            name="logo"
            render={({ field }) => (
              <FileInput
                error={errors.logo && `${errors.logo?.message}`}
                label="Logo / Profile Picture"
                withAsterisk
                //@ts-ignore
                placeholder="Logo / Profile Picture"
                icon={<IconUpload size={14} />}
                {...field}
              />
            )}
          />
          {(owner.data?.logo?.["originalname"] ||
            archivedOwner?.data?.logo?.["originalname"]) &&
            !resetData && (
              <span
                onClick={() =>
                  onViewFile(
                    params?.type === "active"
                      ? owner.data?.logo
                      : archivedOwner?.data?.logo
                  )
                }
                className="text-xs cursor-pointer "
              >
                {params?.type === "active"
                  ? owner.data?.logo?.["originalname"]
                  : archivedOwner?.data?.logo?.["originalname"]}
              </span>
            )}
        </div>

        <div className="flex w-full space-x-2">
          <div className="w-full">
            <Controller
              control={control}
              name="license"
              render={({ field }) => (
                <FileInput
                  className="w-full"
                  error={errors.license && `${errors.license?.message}`}
                  label="License"
                  withAsterisk
                  //@ts-ignore
                  placeholder="License"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
            {(owner.data?.license?.["originalname"] ||
              archivedOwner?.data?.license) &&
              !resetData && (
                <span
                  onClick={() =>
                    onViewFile(
                      params?.type === "active"
                        ? owner.data?.license
                        : archivedOwner?.data?.license
                    )
                  }
                  className="text-xs cursor-pointer "
                >
                  {params?.type === "active"
                    ? owner.data?.license?.["originalname"]
                    : archivedOwner?.data?.license?.["originalname"]}
                </span>
              )}
          </div>
          <Controller
            control={control}
            name="licenseDueDate"
            render={({ field }) => (
              <DatePickerInput
                icon={<IconCalendar size={16} />}
                withAsterisk
                className="w-full"
                excludeDate={(edate) => {
                  if (edate.getFullYear() < date.getFullYear()) {
                    return true;
                  } else if (
                    edate.getFullYear() === date.getFullYear() &&
                    edate.getMonth() < date.getMonth()
                  ) {
                    return true;
                  } else {
                    if (
                      edate.getFullYear() <= date.getFullYear() &&
                      edate.getMonth() === date.getMonth() &&
                      edate.getDate() <= date.getDate()
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }}
                error={
                  errors.licenseDueDate && `${errors.licenseDueDate?.message}`
                }
                //@ts-ignore
                placeholder="Pick date"
                label="License due date"
                {...field}
              />
            )}
          />
        </div>
        <Group position="right" mt="xl">
          <>
            <Button
              classNames={{ label: "flex space-x-1 " }}
              variant="default"
              className="hidden dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
              type="reset"
              size="xs"
              onClick={() => {
                reset();
                setResetData(true);
                setValue("licenseDueDate", null);
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 fill-current"
                  viewBox="0 0 32 32"
                >
                  <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                </svg>
              </span>
              <span>Reset</span>
            </Button>
            <Button
              variant="filled"
              className="text-white bg-primary"
              type="submit"
              classNames={{ label: "flex space-x-1" }}
              size={"xs"}
              loading={uploadResponse.isLoading}
            >
              <span>
                <IconDeviceFloppy size={22} strokeWidth={1.5} />
              </span>
              <span>Submit</span>
            </Button>
          </>
        </Group>
      </form>
      {modal && (
        <div
          className={`group absolute top-0 bottom-0 right-0 left-0 z-50 h-full w-full items-center justify-center `}
        >
          <div className="relative w-3/4 h-full">
            <div className="fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-black opacity-40"></div>
            <div className="fixed z-50 flex items-center justify-end w-full top-6 right-6 ">
              <IconX className="text-white" onClick={() => setModal(false)} />
            </div>
            <div className="fixed right-0 flex items-center justify-center w-full h-full px-10  top-8 left-20">
              <iframe
                ref={ref}
                className="w-3/4 h-3/4"
                src={downloadUrlParser({
                  path: file?.path,
                  filename: file?.filename,
                  originalname: file?.originalname,
                  mimetype: file?.mimetype,
                })}
                title={`${file?.originalname}`}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
