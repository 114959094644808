import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FileInput,
  Group,
  Modal,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { IconDeviceFloppy, IconFileUpload, IconUpload } from "@tabler/icons";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSendByUploadFileMutation } from "../store/notification.query";

export default function SendByUploadFile() {
  let uploadSchema = Yup.object().shape({
    users: Yup.mixed()
      .test("fileSize", "Users file file should be less than 4MB", (value) => {
        if (!value || value.length === 0) return true; // attachment is optional

        return value.size <= 4000000;
      })
      .required("Please upload excel file"),
    body: Yup.string().required("Please enter body"),
  });
  const uploadForm = useForm<{ users: any; body: string }>({
    resolver: yupResolver(uploadSchema),
    mode: "all",
  });
  const [previewProfile, setPreviewProfile] = useState<any>(null);
  const [opened, setOpened] = useState(false);

  const [sendByUploadFile, uploadresponse] = useSendByUploadFileMutation();

  const onSubmit = (data: { users: any; body: string }) => {
    const formData = new FormData();
    data?.users && formData.append("users", data.users);
    data?.body && formData.append("body", data.body);
    //@ts-ignore
    sendByUploadFile(formData).then((response: any) => {
      if (response?.data) {
        uploadForm.reset({ body: undefined, users: undefined });
        setOpened(false);
        // window.location.reload();
      }
    });
  };
  const onError = (data: any) => {
    console.log(data);
  };
  return (
    <>
      <Tooltip label="Send notifications by uploading a user's contact">
        <Button
          type="button"
          size="xs"
          className="bg-primary_light text-gray-500 text-xs hover:text-gray-700"
          onClick={() => setOpened(true)}
        >
          <IconFileUpload />
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <div className="flex-col space-y-2 ">
            <div>Send notifications by uploading a user's contact</div>
            {/* Add a download link for the sample Excel file */}
            <div>
              <a
                href="/sample.xlsx" // Update this with the actual file path
                download="sample.xlsx"
                className="text-blue-500 text-sm hover:underline "
              >
                Download sample file
              </a>
            </div>
          </div>
        }
      >
        <form onSubmit={uploadForm.handleSubmit(onSubmit, onError)}>
          <div className="px-4 w-full flex-col space-y-2">
            <Controller
              control={uploadForm.control}
              name="users"
              render={({ field }) => (
                <FileInput
                  clearable
                  withAsterisk
                  className="w-full"
                  error={
                    uploadForm.formState.errors.users &&
                    `${uploadForm.formState.errors.users?.message}`
                  }
                  label="Upload file"
                  // accept="image/png,image/jpeg,image/jpg"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
            <Controller
              name="body"
              control={uploadForm.control}
              render={({ field }) => (
                <Textarea
                  className="w-full"
                  withAsterisk
                  error={
                    uploadForm.formState.errors.body &&
                    `${uploadForm.formState.errors.body?.message}`
                  }
                  label="Body"
                  placeholder="Enter body text"
                  {...field}
                />
              )}
            />
          </div>
          <div className="w-full flex justify-end mt-2 items-center">
            <Group className={"px-4"} position="right" mt="xl">
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                type="reset"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                // onClick={() => {
                //   uploadForm.reset({ body: undefined, users: undefined });
                //   // Reset file input text - form reset couldn't reset it!
                //   const file: HTMLInputElement | null =
                //     document.querySelector(".file");
                //   if (file) file.value = "";
                // }}
                onClick={() => uploadForm.reset({ body: "", users: null })}
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current h-6"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>

              <Button
                variant="filled"
                className="bg-primary text-white"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={uploadresponse.isLoading}
                type="submit"
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </Group>
          </div>
        </form>
      </Modal>
    </>
  );
}
