import { Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { DemandForcast } from "../../../models/demand-forcast.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";

import {
  useLazyGetDemandForcastsQuery,
} from "../store/demand-forcast.query";

export default function DemandForcastPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    offset: 0,
    limit: 10,
  });
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);
  const [getDemandForcasts, demandForcasts] = useLazyGetDemandForcastsQuery();
 
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    getDemandForcasts(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/demand-forcast/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  // console.log("demandForcasts", demandForcasts);
  const data: DemandForcast[] | undefined = demandForcasts?.data?.results;

  const config: EntityConfig<DemandForcast> = {
    primaryColumn: {
      key: "route",
      name: "Route",
      hideSort: true,
      render: (demandForcast) => {
        return (
          <Text size="sm" className={demandForcast?.deleted_at ? "text-danger" : ""}>
            {demandForcast.route}
          </Text>
        );
      },
    },
    detailUrl: "detail",
    rootUrl: "/demand-forcast",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "route",
        name: "Route",
        hideSort: true,
        render: (demandForcast) => {
          return (
            <Text
              size="sm"
              className={demandForcast?.deleted_at ? "text-danger" : ""}
            >
              {demandForcast.route}
            </Text>
          );
        },
      },
      { key: "date", name: "Date" ,    render: (demandForcast) => {
        return (
          <Text
            size="sm"
            className="text-xs"
          >
            {/* {const date = new Date(response?.data?.result?.date);} */}
           
            { //@ts-ignore
            dateFormat(new Date(demandForcast?.date), "ddd, mmmm dS, yyyy")}
          </Text>
        );
      },
       hideSort: true,},
      { key: "time", name: "Time" ,  hideSort: true,},
      { key: "event", name: "Event" ,  hideSort: true,},
      { key: "holiday", name: "Holiday",  hideSort: true, },
      { key: "traffic_condition", name: "Traffic Condition" ,  hideSort: true,},
      { key: "historical_demand", name: "historical Demand" ,  hideSort: true,},

      { key: "created_at", name: "Registered at", isDate: true,hideSort: true, },
    ],
  };

  return (
    <div className="h-full p-4">
    
   <EntityList
          showExport={false}
          viewMode={viewMode}
          // header={<></>}
          showArchivedCheckBox={false}
          title={"Demand Forcasts"}
          total={demandForcasts?.data?.count}
          itemsLoading={demandForcasts.isLoading || demandForcasts?.isFetching}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          showSelector={false}
          onArchivedChecked={(e) =>
            setCollection({
              ...collection,
              withArchived: e,
            })
          }
          onPaginationChange={(offset: number, limit: number) => {
            setCollection({ ...collection, offset: offset, limit: limit });
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: ["route"],
            });
          }}
          onFilterChange={(data: any) => {
            if (collection?.filter || data.length > 0) {
              setCollection({ ...collection, filter: data });
            }
          }}
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
    </div>
  );
}
