/* eslint-disable @typescript-eslint/no-explicit-any */

import { Collection, CollectionQuery } from "../../../models/collection.model";
import { DemandForcast } from "../../../models/demand-forcast.model";

import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";

import { DEMANDFORCAST_ENDPOINT } from "../demand-forcast.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const demandForcastQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDemandForcasts: builder.query<Collection<DemandForcast>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DEMANDFORCAST_ENDPOINT.list}`,
        method: "GET",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          if (error.status === 403) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        }
      },
    }),
    createDemandForcast: builder.mutation<DemandForcast, DemandForcast>({
      query: (data: DemandForcast) => ({
        url: `${DEMANDFORCAST_ENDPOINT.create}`,
        method: "POST",
        data: data,
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-demandForcasts",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            demandForcastQuery.util.updateQueryData(
              "getDemandForcasts",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteDemandForcast: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${DEMANDFORCAST_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-demandForcasts",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            demandForcastQuery.util.updateQueryData(
              "getDemandForcasts",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((demandForcast) => demandForcast.id !== id);
                draft.count -= 1;
              }
            )
          );
          // dispatch(
          //   demandForcastQuery.util.updateQueryData(
          //     "getArchivedDemandForcasts",
          //     archivedCollection,
          //     (draft) => {
          //       draft.data = draft.data.filter((demandForcast) => demandForcast.id !== id);
          //       draft.count -= 1;
          //     }
          //   )
          // );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getDemandForcast: builder.query<DemandForcast, string>({
      query: (id: string) => ({
        url: `${DEMANDFORCAST_ENDPOINT.demandForcast}/${id}`,
        method: "GET",
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
      }),
    }),
  
   
  
    // getArchivedDemandForcast: builder.query<DemandForcast, string>({
    //   query: (id: string) => ({
    //     url: `${CHATBOT_ENDPOINT.archived_demandForcast}/${id}`,
    //     method: "GET",
    //   }),
    // }),
    updateDemandForcast: builder.mutation<DemandForcast, DemandForcast>({
      query: (data: DemandForcast) => ({
        url: `${DEMANDFORCAST_ENDPOINT.update}/${data?.id}`,
        method: "PATCH",
        data: data,
        headers: {
          "kabba-api-key": `${process.env.REACT_APP_KABBA_AI_API_KEY}`,
        },
        permission: "manage-demandForcasts",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            demandForcastQuery.util.updateQueryData(
              "getDemandForcasts",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((demandForcast) => {
                  if (demandForcast.id === data.id) {
                    return data;
                  } else {
                    return demandForcast;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    // archiveDemandForcast: builder.mutation<DemandForcast, any>({
    //   query: (data: { id: string; reason: string }) => ({
    //     url: `${CHATBOT_ENDPOINT.archive}`,
    //     method: "DELETE",
    //     data: data,
    //     permission: "manage-demandForcasts",
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData(
    //           "getDemandForcasts",
    //           currentCollection,
    //           (draft) => {
    //             draft.data = draft?.data?.map((demandForcast) => {
    //               if (demandForcast.id === arg.id) {
    //                 return data;
    //               } else {
    //                 return demandForcast;
    //               }
    //             });
    //           }
    //         )
    //       );
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData(
    //           "getDemandForcast",
    //           arg?.id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData(
    //           "getArchivedDemandForcast",
    //           arg?.id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       notification("success", "Successfully archived");
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "Error try again"
    //       );
    //     }
    //   },
    // }),
    // getArchivedDemandForcasts: builder.query<Collection<DemandForcast>, CollectionQuery>({
    //   query: (data: CollectionQuery) => ({
    //     url: `${CHATBOT_ENDPOINT.archived_demandForcasts}`,
    //     method: "GET",
    //     params: collectionQueryBuilder(data),
    //   }),
    //   async onQueryStarted(param, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       archivedCollection = param;
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "error try again"
    //       );
    //     }
    //   },
    // }),
    // restoreDemandForcast: builder.mutation<any, string>({
    //   query: (id: string) => ({
    //     url: `${CHATBOT_ENDPOINT.restore_demandForcast}/${id}`,
    //     method: "POST",
    //     permission: "manage-demandForcasts",
    //   }),
    //   async onQueryStarted(id, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData(
    //           "getDemandForcasts",
    //           currentCollection,
    //           (draft) => {
    //             draft.data = draft?.data?.map((demandForcast) => {
    //               if (demandForcast.id === id) {
    //                 return data;
    //               } else {
    //                 return demandForcast;
    //               }
    //             });
    //           }
    //         )
    //       );
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData("getDemandForcast", id, (draft) => {
    //           draft.deletedAt = data?.deletedAt;
    //         })
    //       );
    //       dispatch(
    //         demandForcastQuery.util.updateQueryData(
    //           "getArchivedDemandForcast",
    //           id,
    //           (draft) => {
    //             draft.deletedAt = data?.deletedAt;
    //           }
    //         )
    //       );
    //       notification("success", "Successfully restored");
    //     } catch (error: any) {
    //       notification(
    //         "error",
    //         error?.error?.data?.message
    //           ? error?.error?.data?.message
    //           : "Error try again"
    //       );
    //     }
    //   },
    // }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetDemandForcastQuery,
  useLazyGetDemandForcastsQuery,
  useCreateDemandForcastMutation,
  useDeleteDemandForcastMutation,
  useUpdateDemandForcastMutation,
} = demandForcastQuery;
