/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Corporate } from "../../../models/corporate.model";
import { Passenger } from "../../../models/passenger.model";
import { Transaction } from "../../../models/transaction.model";
import { Wallet } from "../../../models/wallet.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CORPORATE_ENDPOINT } from "../corporate.endpoint";

let corporateCollection: CollectionQuery;
let corporateEmployeeCollection: CollectionQuery;
let archivedCorporateCollection: CollectionQuery;
let transactionCollection: CollectionQuery;

const corporateQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporate: builder.query<Corporate, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCorporate: builder.query<Corporate, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.archivedCorporate}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCorporates: builder.query<
      Collection<Corporate>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${CORPORATE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedCorporateCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getCorporates: builder.query<Collection<Corporate>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CORPORATE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            corporateCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    getCorporateEmployeesAdmin: builder.query<
      Collection<Passenger>,
      CollectionQuery
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.passengerList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            corporateEmployeeCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    createCorporate: builder.mutation<Corporate, Corporate>({
      query: (newData: any) => ({
        url: `${CORPORATE_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission: "manage-corporates",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporates",
                corporateCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateCorporate: builder.mutation<Corporate, any>({
      query: (newData: any) => ({
        url: `${CORPORATE_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-corporates",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporates",
                corporateCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((corporate) => {
                      if (corporate.id === data.id) return data;
                      else {
                        return corporate;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateCorporate: builder.mutation<Corporate, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission: "manage-corporates",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporates",
                corporateCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((corporate) => {
                      if (corporate.id === data.id) return data;
                      else {
                        return corporate;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporate",
                param,
                (draft) => {
                  if (data) {
                    draft.enabled = data?.enabled;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to update status");
        }
      },
    }),

    archiveCorporate: builder.mutation<
      Corporate,
      { id: string; reason: string }
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-corporates",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully archived");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporates",
                corporateCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((corporate) => {
                      if (corporate.id === query.id) {
                        return data;
                      } else {
                        return corporate;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporate",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data?.deletedAt;
                  }
                }
              )
            );
            dispatch(
              corporateQuery.util.updateQueryData(
                "getArchivedCorporate",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data?.deletedAt;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to archive");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    deleteCorporate: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-corporates",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getArchivedCorporates",
                archivedCorporateCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (corporate) => corporate.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification("error", error?.error?.data?.message);
        }
      },
    }),

    restoreCorporate: builder.mutation<Corporate, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-corporates",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporates",
                corporateCollection,
                (draft) => {
                  draft.data = draft?.data?.map((corporate) => {
                    if (corporate.id === id) {
                      return data;
                    } else {
                      return corporate;
                    }
                  });
                }
              )
            );
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporate",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              corporateQuery.util.updateQueryData(
                "getArchivedCorporate",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch {
          notification("error", "Unable to restore");
        }
      },
    }),

    removeEmployee: builder.mutation<
      Passenger,
      { corporateId: string; id: string }
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.removeEmployee}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully removed");

            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployeesAdmin",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (employee) => employee.id !== query.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to remove");
        }
      },
    }),

    addEmployee: builder.mutation<
      Passenger,
      { corporateId: string; id: string }
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.addEmployee}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployeesAdmin",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (employee) => employee.id !== data.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to add");
        }
      },
    }),

    getPassengers: builder.query<Collection<Passenger>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CORPORATE_ENDPOINT.passengerList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),

    importEmployee: builder.mutation<any, { id: string; excelData: any }>({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.import_corporate_employee}/${data.id}`,
        method: "post",
        data: data.excelData,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployeesAdmin",
                corporateEmployeeCollection,
                (draft) => {
                  if (data.data.length) {
                    draft.data = draft.data.concat(data?.data);
                    draft.count += data?.data?.length;
                    notification("success", "Successfully imported");
                  } else {
                    notification("error", "Unable to complete the import.");
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to complete import");
        }
      },
    }),
    getCorporateWallet: builder.query<Wallet, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.corporate_wallet}/${id}`,
        method: "GET",
      }),
    }),
    getCorporateTransactions: builder.query<Collection<Transaction>, any>({
      query: (data: { collection: CollectionQuery; id: string }) => ({
        url: `${CORPORATE_ENDPOINT.corporate_transactions}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          transactionCollection = param;
        } catch (error: any) {
          notification("error", error?.error?.data?.message);
        }
      },
    }),
    getCorporateEmployees: builder.query<Collection<Passenger>, any>({
      query: (corporate: { id: string; collection: CollectionQuery }) => ({
        url: `${CORPORATE_ENDPOINT.corporate_employees}/${corporate.id}`,
        method: "GET",
        params: collectionQueryBuilder(corporate.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            corporateEmployeeCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    transferToMultipleEmployees: builder.mutation<any, any>({
      query: (data: { id: string; amount: number; employees: string[] }) => ({
        url: `${CORPORATE_ENDPOINT.transfer_to_multiple_employees}/${data.id}`,
        method: "POST",
        data: { amount: data.amount, employees: data.employees },
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            corporateQuery.util.updateQueryData(
              "getCorporates",
              corporateCollection,
              (draft) => {
                draft.data = draft?.data?.map((corpo) => {
                  if (corpo?.id === data?.corporateId) {
                    return {
                      ...corpo,
                      wallet: {
                        ...corpo.wallet, // Keep any existing wallet properties
                        balance: data?.balance, // Update the balance
                      },
                    };
                  }
                  return corpo;
                });
              }
            )
          );
          dispatch(
            corporateQuery.util.updateQueryData(
              "getCorporateWallet",
              param.id,
              (draft) => {
                draft.balance = data.balance;
              }
            )
          );
          dispatch(
            corporateQuery.util.updateQueryData(
              "getCorporateTransactions",
              transactionCollection,
              (draft) => {
                draft.data.unshift(...data.transactions);
                draft.data = draft.data.slice(0, 6);
                draft.count += data.transactions.length;
              }
            )
          );
          notification("success", "Successfully Transferred");
        } catch (error: any) {
          notification("error", error?.error?.data?.message);
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetPassengersQuery,
  useLazyGetCorporateQuery,
  useLazyGetArchivedCorporateQuery,
  useLazyGetCorporatesQuery,
  useLazyGetCorporateEmployeesAdminQuery,
  useLazyGetArchivedCorporatesQuery,
  useCreateCorporateMutation,
  useAddEmployeeMutation,
  useRemoveEmployeeMutation,
  useArchiveCorporateMutation,
  useUpdateCorporateMutation,
  useActivateCorporateMutation,
  useRestoreCorporateMutation,
  useDeleteCorporateMutation,
  useImportEmployeeMutation,
  useLazyGetCorporateWalletQuery,
  useLazyGetCorporateTransactionsQuery,
  useLazyGetCorporateEmployeesQuery,
  useTransferToMultipleEmployeesMutation,
} = corporateQuery;
