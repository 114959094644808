import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import MyMapComponent from "../page/driver-tracking-page";

export const DriverTrackingRoute: RouteObject = {
  path: "/drivertracking",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <MyMapComponent />
    </AuthGuard>
  ),
};
