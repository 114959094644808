import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconTrash,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Faq } from "../../../models/faq.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { Constants } from "../../../shared/constants/Constants";

import { debounce } from "lodash";
import { CollectionQuery } from "../../../models/collection.model";
import { FaqCategory } from "../../../models/faqCategory.model";
import { useLazyGetFaqCategoriesQuery } from "../../faq-category/store/faq-category.query";
import {
  useArchiveFaqMutation,
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useLazyGetArchivedFaqQuery,
  useLazyGetFaqQuery,
  useRestoreFaqMutation,
  useUpdateFaqMutation,
} from "../store/faq.query";

interface Props {
  editMode: string;
}

export default function NewFaqComponent(props: Props) {
  const { editMode } = props;
  const id = useId();
  const params = useParams();

  let schema: any = yup.object().shape({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
    categoryId: yup.string().required("Category is required"),
  });

  const defaultValue: Faq = {
    id: "",
    question: "",
    answer: "",
    categoryId: "",
  };
  const [faqCategorysCollection, setFaqCategorysCollection] =
    useState<CollectionQuery>({
      skip: 0,
      top: 10,
      orderBy: [{ field: "createdAt", direction: "desc" }],
    });
  const [currentFaq, setCurrentFaq] = useState<any>();
  const [getFaq, faq] = useLazyGetFaqQuery();
  const [createFaq, newFaq] = useCreateFaqMutation();
  const [updateFaq, updateResponse] = useUpdateFaqMutation();
  const [restoreFaq, restoredFaq] = useRestoreFaqMutation();
  const [deleteFaq, deleteResponse] = useDeleteFaqMutation();

  const [archiveFaq, archiveResponse] = useArchiveFaqMutation();

  const [getArchivedFaq, archivedFaq] = useLazyGetArchivedFaqQuery();
  const [getFaqCategorys, allFaqCategorys] = useLazyGetFaqCategoriesQuery();
  const [categoryValue, setCategoryValue] = useState("");
  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Faq>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const navigate = useNavigate();
  function faqCategorysSelector() {
    let activeFaqCategorys =
      (allFaqCategorys?.data?.data &&
        allFaqCategorys.data.data.map((faqCategory: FaqCategory) => ({
          value: faqCategory?.id || "",
          label: `${faqCategory?.name}`,
        }))) ||
      [];

    const faqCategoryToAdd = {
      value: currentFaq?.data?.faqCategory?.id || "",
      label: `${currentFaq?.data?.faqCategory?.name}`,
    };
    if (
      currentFaq?.data?.faqCategory &&
      !activeFaqCategorys?.some(
        (faqCategory) => faqCategory.value === faqCategoryToAdd.value
      )
    ) {
      activeFaqCategorys?.unshift({
        value: currentFaq?.data?.faqCategory?.id || "",
        label: `${currentFaq?.data?.faqCategory?.name}`,
      });
    } else if (
      currentFaq?.data?.faqCategory === undefined &&
      editMode === "detail"
    ) {
      activeFaqCategorys?.unshift({
        value: currentFaq?.data?.faqCategoryId,
        label: "Archived FAQ Category",
      });
    }

    return activeFaqCategorys;
  }
  useEffect(() => {
    getFaqCategorys(faqCategorysCollection);
  }, [params?.type, faqCategorysCollection, getFaqCategorys]);
  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type && params?.type === "archived") {
        getArchivedFaq(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setCurrentFaq(response);
        });
      } else {
        getFaq(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setCurrentFaq(response);
        });
      }
    } else {
      reset(defaultValue);
    }
  }, [params, editMode, getFaq]);

  function onSubmit(data: any) {
    if (editMode === "new") {
      createFaq(data).then((response: any) => {
        if (response?.data) {
          reset(defaultValue);
        }
      });
    } else {
      updateFaq(data).then((response: any) => {
        if (response?.data) {
          reset(response?.data);
        }
      });
    }
  }
  const onError = (error: any) => {};

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? (
            "New FAQ "
          ) : (
            <div className="h-full space-x-2 flex items-center">
              <div>{faq?.data?.question}</div>
            </div>
          )
        }
        item={faq}
        path={`/faq/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            (editMode === "new"
              ? newFaq.isLoading
              : updateResponse.isLoading) || faq.isLoading
          }
        />
        <>
          <div className="flex  justify-center h-full">
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full gap-y-4 dark:text-white"
            >
              <div className="flex-col space-y-4">
                <div className="flex w-full space-x-4  justify-between">
                  <Controller
                    name="categoryId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="w-full"
                        searchable
                        withAsterisk
                        error={
                          errors.categoryId && `${errors?.categoryId?.message}`
                        }
                        label="FAQ Category"
                        placeholder="Select FAQ category"
                        data={faqCategorysSelector()}
                        maxDropdownHeight={400}
                        onKeyUp={debounce((event: any) => {
                          event.target.value
                            ? setFaqCategorysCollection({
                                ...faqCategorysCollection,
                                skip: 0,
                                search: event.target.value,
                                searchFrom: ["name"],
                              })
                            : setFaqCategorysCollection({
                                ...faqCategorysCollection,
                                skip: 0,
                                search: "",
                                searchFrom: [],
                              });
                        }, 1000)}
                      />
                    )}
                  />
                </div>
                <div className="flex w-full space-x-4  justify-between">
                  <TextInput
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={errors.question && `${errors?.question?.message}`}
                    withAsterisk
                    label="Question"
                    placeholder="Enter question"
                    {...register("question")}
                  />
                </div>
                <div className="flex w-full space-x-4  justify-between">
                  <Textarea
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={errors.answer && `${errors?.answer?.message}`}
                    withAsterisk
                    label="Answer"
                    placeholder="Enter answer"
                    {...register("answer")}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end items-center">
                <Group position="right" mt="xl">
                  {editMode === "new" && (
                    <>
                      <Button
                        classNames={{ label: "flex space-x-1 " }}
                        variant="default"
                        className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                        type="reset"
                        size="xs"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-current h-6"
                            viewBox="0 0 32 32"
                          >
                            <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                          </svg>
                        </span>
                        <span>Reset</span>
                      </Button>
                      <Button
                        variant="filled"
                        className="bg-primary text-white"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={newFaq.isLoading}
                      >
                        <span>
                          <IconDeviceFloppy size={22} strokeWidth={1.5} />
                        </span>
                        <span>Submit</span>
                      </Button>
                    </>
                  )}
                  {editMode === "detail" && (
                    <>
                      {(params?.type === "archived" &&
                        archivedFaq?.data?.deletedAt) ||
                      (params?.type === "active" && faq?.data?.deletedAt) ? (
                        <>
                          <Confirmation
                            type={"danger"}
                            message={
                              "Are you sure you want to delete it permanently?"
                            }
                            onYes={() =>
                              deleteFaq(`${params?.id}`).then(
                                (response: any) => {
                                  if (response?.data) {
                                    navigate(-1);
                                  }
                                }
                              )
                            }
                            header={`Permanent Delete confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={deleteResponse.isLoading}
                              className={`bg-danger text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconTrash size={20} strokeWidth={1.5} />
                              <span>Delete</span>{" "}
                            </Button>
                          </Confirmation>
                          <Confirmation
                            type={"notify"}
                            message={`Are you sure you want to restore it`}
                            onYes={() => restoreFaq(`${params?.id}`)}
                            header={`Restore confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={restoredFaq.isLoading}
                              className={`bg-green-500 text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconArrowBackUp size={20} strokeWidth={1.5} />
                              <span>Restore</span>
                            </Button>
                          </Confirmation>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Confirmation
                            type={"notify"}
                            message={"Are you sure you want to archive it?"}
                            header={`Archive Confirmation`}
                            result={"single"}
                            resultRequired={true}
                            selectorLabel={"Please select a reason"}
                            labelDescription={"Why are you archiving it?"}
                            options={Constants.ArchiveReason.map((reason) => {
                              return { label: reason, value: reason };
                            })}
                            customInput={true}
                            yesText={"Archive"}
                            onYes={(reason: string) => {
                              archiveFaq({
                                id: `${params.id}`,
                                reason: reason,
                              });
                            }}
                          >
                            <Button
                              variant="filled"
                              loading={archiveResponse.isLoading}
                              className="bg-danger text-white"
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <span>
                                <IconArchive size={22} strokeWidth={1.5} />
                              </span>
                              <span>Archive</span>
                            </Button>
                          </Confirmation>
                          <Button
                            variant="filled"
                            className="bg-primary text-white"
                            type="submit"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                            loading={updateResponse.isLoading}
                          >
                            <span>
                              <IconDeviceFloppy size={22} strokeWidth={1.5} />
                            </span>
                            <span>Update</span>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Group>
              </div>
            </form>
          </div>
        </>
      </ActivityLogWrapperComponent>
    </div>
  );
}
